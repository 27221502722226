import Button from "../../../../../components/UI/Button";
import VariantItem from "./VariantItem";
import {useFieldArray} from "react-hook-form";

export const VariantComponent = ({watch, clearErrors, setError, control, setValue, handleSubmit, errors, register, getValues})=>{
  const { fields: varientFields, append: appendVariant, remove:removeVariant} = useFieldArray({
    control,
    name: 'variants',
  });

  return (<>

    <div className="item_variation_wrap">
      <div className="d_flex_end add_varation_btn pb_20">
        <Button
          size={'btn_md'}
          text={<span className="mr_5 d_flex_center">
            <i className="lni lni-plus mr_5"></i> Add New Varient</span>}
          onClickHandler={()=> appendVariant({name: '', values: [{name: ""}]})}
          type={"button"}
        />
      </div>
      <div className="row">
        {getValues("variants") && getValues("variants").map((field, index) => (
          <div className="col_4"  key={`category_${index}`}>
            <VariantItem errors={errors}
                         key={`category_${index}`}
                         watch={watch}
                         setValue={setValue}
                         control={control}
                         register={register}
                         getValues={getValues}
                         clearErrors={clearErrors}
                         removeRow={removeVariant}
                         addNewVariant={() => appendVariant({name: "", values: [{name: ""}]})}
                         index={index}/>
          </div>
        ))}
      </div>
    </div>
  </>)
}

export default VariantComponent