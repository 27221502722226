import {getIncompleteCheckoutDetails} from "../../api/incompleteCheckout";
import {useLoaderData} from "react-router";
import React, {useEffect, useState} from "react";
import Card from "../../components/UI/Card";
import { noImagePlaceholder, unstructureJson} from "../../util/helper";


export async function loadIncompleteDetails({ params }) {
    const query = params.id ? await getIncompleteCheckoutDetails({id:params.id, type:params.type}) : undefined
    return {query}
}
const IncompleteDetails = () => {
    const {query} = useLoaderData()
    const [customer, setCustomer] = useState(null)
    const [products, setProducts] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(()=>{
        if(query){
            setCustomer(query.data.customer)
            setProducts(query.data.products)
            setTotal(query.data.total)
        }
    }, [query])


    return(
        <>
            {products && (
                <>

                    <div className="row">
                        <Card cardClass={'width_full'} title={'Summary'}>
                            <div className="order_summery_table">
                                <table className="table border_none">
                                    <tbody>
                                    {customer && (
                                        <>
                                            <tr>
                                                <td className="text_left width_100p fw_600">Name</td>
                                                <td>{customer.fname} {customer.lname}</td>
                                            </tr>
                                            <tr>
                                                <td className="text_left fw_600">Email</td>
                                                <td>{customer.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="text_left fw_600">Phone</td>
                                                <td>{customer.phone}</td>
                                            </tr>
                                        </>
                                    )}
                                    <tr>
                                        <td className="text_left fw_600">Total</td>
                                        <td className={'fw_600'}>${total ? total.toFixed(2) : '0.0'}</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                            {!customer && (
                            <div className="order_summery_table">
                                <table className="table border_none">
                                <tbody>
                                    <tr>
                                        <td className="text_left width_100p fw_600">User not logged in.</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                                )}
                        </Card>
                    </div>

                    <div className="row">
                        <Card cardClass={'width_full'} title={'Products'}>
                            <div className="view_order_details">
                                <div className="img_wrap">
                                    {products && products.map((cart, i) => (
                                        <div className="inner p_relative" key={'product_' + i}>
                                            <div className="inner_wrap">
                                                <img src={cart.thumbnail} width={100} alt="" onError={noImagePlaceholder}/>
                                                <div className="text">
                                                    <h2 className={"mt_5"}>{cart.sku}</h2>
                                                    <p>Unit Price:${cart.price.toFixed(2)}</p>
                                                    <p>Qty: {cart.quantity}</p>
                                                    <p>Total price: ${cart.total.toFixed(2)}</p>
                                                    {cart.product_variant_values && <p>
                                                        {unstructureJson(cart.product_variant_values)}
                                                    </p>}
                                                </div>
                                            </div>
                                        </div>))
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                </>
            )}

        </>
    );
}

export default IncompleteDetails;