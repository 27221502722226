import Button from "../../../../components/UI/Button";

const HomeSectionCardActions = ({ clickHandler}) => {
  return (
      <Button
          text="+ Add a New Category"
          variant={"btn_black"}
          onClickHandler={clickHandler}
      />
  );
}

export default HomeSectionCardActions