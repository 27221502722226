import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {getStaticMenuDetails} from "../../../../api/staticMenus";
import Loading from "../../../../components/UI/Loading";
import Card from "../../../../components/UI/Card";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentEditMenu} from "../../../../store/slices/staticMenuSlice";
import StaticMenuForm from "../../../../views/products/settings/static-menus/StaticMenuForm";

import {
  selectUnselectProduct, setSelectProductsForStaticMenus, setSelectToggle
} from "../../../../store/slices/productSlice";
import SelectableProductListComponent from "../../../../views/products/SelectableProductListComponent";
import SelectedProducts from "../../../../views/products/settings/static-menus/SelectedProducts";
import VideoURLInput from "./VideoURLInput";

const AddEditStaticMenu = () =>{
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const isEditMode = !!params.id;
  const staticMenuStore = useSelector(state => state.staticMenu);
  const [selectedProductsForStaticMenu, setSelectedProductsForStaticMenu] = useState([]);
  const staticMenu = staticMenuStore.currentEditMenu
  const form = useForm({
    defaultValues: {
      name: '',
      banner: '',
      meta_title: '',
      meta_description: '',
      status: "1",
      type: "create",
    },
  });

  const {getValues, setValue, watch, reset, control, register, setError, clearErrors, handleSubmit, formState: { errors }} = form;

  const {mutate, isPending} = useMutation({
    mutationFn: () => getStaticMenuDetails(params.id),
    onSuccess: (data) => {
      dispatch(setCurrentEditMenu(data))
      dispatch(setSelectToggle({type: 'select'}))
    }
  });

  useEffect(() => {
    if (params.id) {
      mutate();
    } else {
      setSelectedProductsForStaticMenu([])
      dispatch(setCurrentEditMenu(null))
      dispatch(setSelectToggle({type: 'unselect'}))
    }
  }, []);

  useEffect(() => {
    reset();
  }, [location]);

  const selectUnselectMenuProduct = (product, index) => {
    const isExisting = selectedProductsForStaticMenu.some(item => item.id === product.id);
    if (isExisting) {
      const updatedProducts = selectedProductsForStaticMenu.filter(item => item.id !== product.id);
      setSelectedProductsForStaticMenu(updatedProducts);
    } else {
      setSelectedProductsForStaticMenu(prevProducts => [...prevProducts, product]);
    }
  }

  const removeSelectedProduct = (index, id) => {
    const updatedProducts = [...selectedProductsForStaticMenu];
    updatedProducts.splice(index, 1);
    setSelectedProductsForStaticMenu(updatedProducts);
    dispatch(selectUnselectProduct(id));
  };

  useEffect(() => {
    if (isEditMode && staticMenuStore.currentEditMenu?.products) {
      setSelectedProductsForStaticMenu(staticMenuStore.currentEditMenu?.products);
      const productIds = staticMenuStore.currentEditMenu.products.map(product => product.id);
      dispatch(setSelectProductsForStaticMenus(productIds));
    }
  }, [isEditMode, dispatch, staticMenuStore.currentEditMenu?.products]);


  return(
      <>
        <Card title={`${isEditMode ? 'Edit' : 'Add'} Static Menu`}>
          {isPending ?
              <Loading/> :
              <>
                <StaticMenuForm
                    watch={watch}
                    setError={setError}
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    clearErrors={clearErrors}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    form={form}
                    selectedProductsForStaticMenu={selectedProductsForStaticMenu}
                    register={register}
                />
          </>
          }
        </Card>

        {staticMenu && staticMenu.slug === 'todays-home-shopping-video' ? <>
            <VideoURLInput staticMenu={staticMenu} />
            </> :
        <div className="row">
          <div className="col_12">
              <SelectableProductListComponent selectUnselectProduct={(product)=>selectUnselectMenuProduct(product)}/>
          </div>
          <div className="col_12">
              <SelectedProducts
                  title={`tabs_products`}
                  selectedProductsForStaticMenu={selectedProductsForStaticMenu}
                  name={'selected_products'}
                  control={control}
                  removeSelectedProduct={removeSelectedProduct}
              />
          </div>
        </div>
        }
      </>
  );
}

export default AddEditStaticMenu;