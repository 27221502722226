import Input from "../../../../../components/UI/form/Input";
import Card from "../../../../../components/UI/Card";
import {useFieldArray} from "react-hook-form";
import Button from "../../../../../components/UI/Button";
import VariantCardActions from "./VariantCardActions";
import {useEffect, useState} from "react";
import {getErrorObj, getValidationMessage} from "../../../../../util/validationMessage";

export const VariantItem = ({control,index, errors = [], register, watch, setValue, getValues, addNewVariant, removeRow, clearErrors})=>{
  const { fields: valuesFields, append: appendValue, remove:removeValue} = useFieldArray({
    control,
    name: `variants[${index}].values`,
  });

  const [cardName, setCardName] = useState('')

  useEffect(()=>{
    setCardName(capitalize(getValues(`variants[${index}].name`)))
  }, [watch(`variants[${index}].name`)])

  function capitalize(str) {
    return str.toUpperCase()
  }

  const handleAddNewValueClick = () => {
    appendValue({name: ""})
    clearErrors(`variants[${index}].values`)
  }

  return (
    <>
      <div className="card item_variation_content" key={index}>
        <div className="remove_variant">
          <Button size={'btn_sm'} onClickHandler={()=> removeRow(index)} text={<span className="false"><i className="lni lni-close"></i></span>} />
        </div>
        <div className="card_body ">
          <div className="form_row ">
            <label className="">{ cardName ? cardName : `Variant ${index + 1}` } </label>
          </div>

          <Input
            name={`variants[${index}].name`}
            errors={errors}
            register={register}/>
          <div className="form_row ">
            <label className="">Option value </label>
          </div>
          {getValues(`variants[${index}].values`) && getValues(`variants[${index}].values`).map((field, i) => (
            <div className="form_input d_flex_center variant_option_val" key={i}>
              <span className="pr_10"><img src="/assets/media/images/thumbnails.png" className="width_15p" alt="" /></span>
              <Input
                classes={'width_full mb_0'}
                inline={true}
                name={`variants[${index}].values[${i}].name`}
                errors={errors}
                register={register}/>

              {getValues(`variants[${index}].values`).length > 1 &&
              <span className="del_variant cursor_pointer" onClick={()=> removeValue(i)}><img src="/assets/media/images/trash.png" alt="" /></span>
              }
            </div>
          ))}


          <div className="add_option">
            <button type="button" className="btn btn_black btn_sm" onClick={handleAddNewValueClick}>
              <span className="false"><i className="lni lni-plus"></i></span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default VariantItem