import {noImagePlaceholder} from "../../../../util/helper";
import EmptyImage from "../../../../components/UI/EmptyImage";
import Button from "../../../../components/UI/Button";

const SingleProductItem = ({product, setSelectedSingleItem}) => {
  return (
    <div className={"col_2"}>
      <div className="item_outer_wrap">
        {product.media && product.media.length > 0 ?
          <img src={product.media[0].url} alt="" className={'width_full'} onError={noImagePlaceholder} />
          :
          <EmptyImage />
        }
        <div className="text">
          <p className="style_no mb_0 pt_10 order_add_product_sku">{ product.sku }</p>
          <p className="mb_0 pb_10 d_flex_btwn">
            <span>${ product.price.toFixed(2) }</span>
            {product.original_price &&
            <span className={'text_red'}>$<del>{product.original_price.toFixed(2)}</del></span>
            }
          </p>
        </div>
        <Button text={"Select"} onClickHandler={()=>setSelectedSingleItem(product)} classes={'width_full'} />
      </div>

    </div>
  );
}

export default SingleProductItem