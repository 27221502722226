import Button from "../../components/UI/Button";
import {useEffect, useState} from "react";

export const VendorFilterComponent = ({searchHandler, params})=>{
  const [filterParams, setFilterParams] = useState({...params});

  const handleInputChange = (event) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      search: event.target.value,
      page: 1,
    }));

  }
  const changePerPage = (event) => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      per_page: event.target.value,
      page: 1
    }));
    searchHandler({...filterParams, per_page:event.target.value, page: 1})
  }
  const sendSearchRequest = ()=>{
    searchHandler(filterParams)
  }


  const resetSearch = ()=>{
    searchHandler({
      per_page: 50,
      page: 1,
      search: ''
    })
    setFilterParams({
      per_page: 50,
      page: 1,
      search: ''
    })
  }

  const enterPressHandler = (e) => {
    if(e.key === 'Enter'){
      searchHandler(filterParams)
    }
  }

  return (
    <>
      <div className="order_filter_content">
        <div className="d_flex_start mb_20">
          <select className="form_global width_260p mr_5 " value={params.per_page} onChange={(event)=>{changePerPage(event)}}>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="80">80</option>
            <option value="100">100</option>
          </select>
          <div className="form_row mb_0 width_full">
            <div className="form_input">
              <div className="search_input">
                <input type="text" className="form_global"
                       value={filterParams? filterParams.search : ''}
                       onKeyPress={enterPressHandler}
                       placeholder={'Search by name, email, phone, city or business name'}
                       onChange={(event)=>handleInputChange(event)}
                />
              </div>

              <Button text={"Search"}
                      classes={'mr_5 ml_5'}
                      onClickHandler={sendSearchRequest} />

              <Button text={"Reset"}
                      onClickHandler={resetSearch} />
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default VendorFilterComponent