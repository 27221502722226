import {useState} from "react";
import SubmitActionButtonGroup from "./includes/SubmitActionButtonGroup";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import {importExcelProducts} from "../../../api/productImport";
import {useForm} from "react-hook-form";

const ProductImportForm = ({watch, setError, control, handleSubmit, errors, register, setValue, getValues}) => {
    const {reset} = useForm();

    const queryClient = useQueryClient();
    const [toastId, setToastId] = useState(null);

    const {isPending, mutate} = useMutation({
        mutationFn: importExcelProducts,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, { render: `Product imported successfully`, type: "success", isLoading: false, autoClose: true });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'couriers',
            });
            reset();
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }

            toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
        }
    });

  const onSubmit = (data) => {
      if (!data?.file) {
          toast.error('Please select a file.', { autoClose: true });
          return;
      }
      const formData = new FormData();
      formData.append('file', data.file[0]);
      mutate(formData)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb_20">
              <div className="form_row">
                  <div className="form_input">
                      <input className={'form_global'} type="file" {...register('file')} />
                      {errors.file && <div className="text_red">{errors.file.message}</div>}
                  </div>
              </div>
          </div>
        <SubmitActionButtonGroup isloading={isPending} />
      </form>
    </>
  );
}

export default ProductImportForm