import React, {useEffect} from "react";
import SubHeadingComponent from "../../../components/UI/SubHeadingComponent";
import {noImagePlaceholder} from "../../../util/helper";
import EmptyImage from "../../../components/UI/EmptyImage";
import {useFieldArray} from "react-hook-form";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const HomeSectionSelectedProducts = ({name, control, setValue, product, selectedProducts, removeSelectedProduct }) => {
  const {editSection} = useSelector(state => state.homePageSection)

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: name
  });


  useEffect(()=>{
    if(product){
      const existsIndex = fields.findIndex(p => p.sku === product.sku);
      if (existsIndex !== -1) {
        remove(existsIndex)
      } else {
        append({id:product.id, product_id: product.id, sku:product.sku, thumbnail:product.thumbnail})
      }
    }
  }, [product])

  const removeProduct = (index, id) => {
    remove(index)
    // dispatch(selectUnselectProduct(id))
    removeSelectedProduct(index, id)
  }

  useEffect(()=>{
    if(editSection && editSection.products.length){
      setTimeout(()=>{
        editSection.products.map(product=>{
          append({id:product.id, product_id: product.id, sku:product.sku, thumbnail:product.thumbnail})
        })
      },500)

      // const productIds = editSection.products.map(product => product.id);
      // dispatch(setSelectProductsForStaticMenus(productIds));
    }
  }, [editSection])

  return(
    <>
      <h1 className="common_sub_title custom_border_bottom common_sub_title d_flex_btwn custom_border_bottom pb_10">
        <span>Selected Products</span>
      </h1>
      <div className="item_img_upload_wrap selected_product_wrap  width_full">
        <div className="image_show_wrap">
          {selectedProducts && selectedProducts.map((product,index)=>(
            <div className="inner" key={`prod_${product.id}`} >
              <div className="inner_wrap">
                <div className="remove_list">
                  <button type="button" className="btn btn_black btn_xs" onClick={()=>removeProduct(index, product.id)}>
                    <span className="false"><i className="lni lni-close"></i></span>
                  </button>
                </div>

                {product.thumbnail ?
                  <img className={'width_full'} src={product.thumbnail} alt="" onError={noImagePlaceholder} />
                  :
                  <EmptyImage />
                }

                <div className="text">
                  <p className="style_no mb_0 p_5">{ product.sku }</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default HomeSectionSelectedProducts