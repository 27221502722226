export const InventoryCombinationHeading = ()=>{
  return (
    <>
      <thead>
      <tr>
        <th>Combination</th>
        <th> SKU </th>
        <th>Price</th>
        <th> Available Quantity</th>
        <th className={'text_right'}>Availability</th>
      </tr>
      </thead>
    </>
  )
}

export default InventoryCombinationHeading