import Button from "../../components/UI/Button";
import {useMutation} from "@tanstack/react-query";
import { updateSectionsProducts} from "../../api/pageSections";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectToggle} from "../../store/slices/productSlice";

const SelectedProductAddSectionsButton = ({section, products, setProducts, setModalStatus})=> {
  const {selectedProducts, allProducts} = useSelector(state => state.product);
  const [toastId, setToastId] = useState(null);
  const dispatch = useDispatch()

  const {isPending, mutate} = useMutation({
    mutationFn:  (data) => updateSectionsProducts( section ? section.id : '' ,data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      setProducts(res)
      setModalStatus(false)
      dispatch(setSelectToggle({type: 'unselect'}))
      toast.update(toastId, { render: `Section product updates`, type: "success", isLoading: false, autoClose: true });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }

  });

  const addToList = () => {
    if(selectedProducts.length){
      mutate({ids: selectedProducts})
    }
  }
  return (
    <div className="d_flex_btwn pt_10 pb_5">
      <div className="d_flex_inline"></div>
      <div className="d_flex_inline">
        <Button text={"Add to Section's product"}
                classes={'mr_5'}
                isPending={isPending}
                onClickHandler={addToList}
                variant={"btn_black"} />
      </div>
    </div>
  );
}

export default SelectedProductAddSectionsButton;