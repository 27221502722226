import Button from "../../components/UI/Button";

const CouponUpdateCardButton = ({setModalStatus})=>{
    return(
        <Button
            text="Edit"
            variant={"btn_black"}
            onClickHandler={()=>setModalStatus(true)}
        />
    )
}

export default CouponUpdateCardButton