import {createSlice} from "@reduxjs/toolkit";

export const vendorUserSlice = createSlice({
  name: 'vendorUser',
  initialState:{
    editVendorUser: null,
  },
  reducers:{
    setEditVendorUser:(state, action)=>{
      state.editVendorUser = action.payload
    }
  }
});

export const {
  setEditVendorUser
} = vendorUserSlice.actions

export default vendorUserSlice.reducer;