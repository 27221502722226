import Input from "../../components/UI/form/Input";
import React, {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {useNavigate, useParams} from "react-router-dom";
import {storeCustomer, updateCustomer} from "../../api/customers";
import Button from "../../components/UI/Button";
import TextArea from "../../components/UI/form/TextArea";

const CustomerForm = ({watch, setError, handleSubmit, errors, register}) => {

    const [toastId, setToastId] = useState(null);
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const params = useParams()

    const {isPending, mutate} = useMutation({
        mutationFn: params.id ? (data) => updateCustomer(params.id, data) : storeCustomer,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, { render: `${params.id ? 'Customer Updated' : 'Please check email address.'}`, type: "success", isLoading: false, autoClose: true });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'customers',
            });

            navigate('/customers');
        },
        onError: (error) => {
            let errorMsg = error.message.message;
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }
            toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
        }
    });

    const onSubmit = (data) => {
        mutate(data)
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>

          <div className="row">
              <div className="col_4 pr_20">
                  <Input label="First Name"
                         name="fname"
                         inline={true}
                         errors={errors}
                         validationRules={{required: true, type: 'string'}}
                         register={register}/>
              </div>
              <div className="col_4 pr_20">
                  <Input label="Last Name"
                         name="lname"
                         inline={true}
                         errors={errors}
                         validationRules={{required: false, type: 'string'}}
                         register={register}/>
              </div>

              <div className="col_4">
                  <Input label="Email"
                         name="email"
                         inline={true}
                         type={'email'}
                         disable={params.id ? true : false}
                         errors={errors}
                         validationRules={{required: true, type: 'email'}}
                         register={register}/>
              </div>

              <div className="col_4 pr_20">
                  <Input label="Username"
                         name="username"
                         disable={params.id ? true : false}
                         inline={true}
                         errors={errors}
                         validationRules={{required: true}}
                         register={register}/>
              </div>
              <div className="col_4 pr_20">
                  <Input label="Phone"
                         name="phone"
                         inline={true}
                         errors={errors}
                         register={register}/>
              </div>
              <div className="col_4">
                  <Input label="Street"
                         name="street"
                         inline={true}
                         errors={errors}
                         register={register}/>
              </div>
              <div className="col_4 pr_20">
                  <Input label="City"
                         name="city"
                         inline={true}
                         errors={errors}
                         register={register}/>
              </div>
              <div className="col_4 pr_20">
                  <Input label="State"
                         name="state"
                         inline={true}
                         errors={errors}
                         register={register}/>
              </div>
              <div className="col_4">
                  <Input label="Zip code"
                         name="zipcode"
                         inline={true}
                         errors={errors}
                         register={register}/>
              </div>

              <div className="col_6 pr_20">
                  <Input label="Password"
                         name="password"
                         inline={true}
                         type={'password'}
                         errors={errors}
                         validationRules={{required: params.id === undefined, type: 'string'}}
                         register={register}/>
              </div>

              <div className="col_6 ">
                  <Input label="Confirm Password"
                         name="password_confirmation"
                         inline={true}
                         type={'password'}
                         errors={errors}
                         validationRules={{required: params.id === undefined, type: 'string'}}
                         register={register}/>
              </div>

              <div className="col_12">
                  <TextArea label="Note"
                            name="note"
                            inline={true}
                            placeholder={"Max. 500 letters allowed. The following special characters are not allowed: <, >, {, }, ^, [, ], =, @, ;"}
                            errors={errors}
                            validationRules={{type: 'string'}}
                            register={register}/>
              </div>

          </div>

          <div className="d_flex_end">
              <Button
                isPending={isPending}
                text="SAVE"
                type="submit"
              />
          </div>

      </form>
    )
}

export default CustomerForm;