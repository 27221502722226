import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import Card from "../../../../components/UI/Card";
import {getStaticMenus, sortStaticMenu} from "../../../../api/staticMenus";
import StaticMenuListActions from "../../../../views/products/settings/static-menus/StaticMenuListActions";
import {ReactSortable} from "react-sortablejs";
import StaticMenuAccordianItem from "../../../../views/products/settings/static-menus/includes/StaticMenuAccordianItem";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import StaticMenuCardActions from "../../../../views/products/settings/static-menus/StaticMenuCardActions";

const StaticMenuList = () => {
  const [staticMenus, setStaticMenus] = useState([]);

  const { data } = useQuery({
    queryKey: ['staticMenus'],
    queryFn:()=> getStaticMenus(),
    initialData: () => []
  });

  useEffect(() => {
    if (data !== undefined) {
      setStaticMenus(data);
    }
  }, [data])

  const [toastId, setToastId] = useState(null);
  const { mutate,  reset} = useMutation({
    mutationFn: async  (ids) => sortStaticMenu( {ids:[...ids]}),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, {
        render: `Static Menu Sorted!`,
        type: "success",
        isLoading: false,
        autoClose: true
      });
      setTimeout(()=>{
        reset()
      }, 1000)
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      toast.update(toastId, {
        render: errorMsg,
        type: "error",
        isLoading: false,
        autoClose: true
      });
    }
  })

  const staticMenuSort = (list, type = '')=>{
    if(type === 'child'){
      let ids = list.map(obj => obj.id);
      mutate(ids)
    } else {
      const checkEquality = JSON.stringify(list) === JSON.stringify(staticMenus);
      if (!checkEquality) {
        let ids = list.map(obj => obj.id);
        mutate(ids)
      }
      setStaticMenus(list)
    }
  }

  return (
    <>
      <Card title={'Static Menu'} CardActions={<StaticMenuCardActions />}>
        <div className="accordion_wrap categories_row">
          <ReactSortable animation={200}
                         list={staticMenus}
                         setList={staticMenuSort} >
            {staticMenus.length && staticMenus.map(staticMenu => (
              <StaticMenuAccordianItem
                  key={staticMenu.id}
                  staticMenu={staticMenu}
                  staticMenuSort={(list)=>staticMenuSort(list, 'child')}
              />
            ))}
          </ReactSortable>
        </div>
      </Card>
    </>
  )
}

export default StaticMenuList;