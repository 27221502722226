import Button from "../../../../components/UI/Button";
import {NavLink} from "react-router-dom";

const HomeSectionCardActions = () => {
  return (
      <NavLink to="/products/static-menus/add">
          <Button
              text="+ Add a New menu"
              variant={"btn_black"}
          />
      </NavLink>
  );
}

export default HomeSectionCardActions