import {bestSaleItems, dashboardData, latestOrders} from "../api/dashboard";
import {useLoaderData} from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';



export async function loader(){
  const [dashboard, bestItems, recentOrderItems] = await Promise.all([
    dashboardData(),
    bestSaleItems(),
    latestOrders(),
  ]);

  return {dashboard, bestItems, recentOrderItems};
}
const HomePage = () => {
  const {dashboard, bestItems, recentOrderItems} = useLoaderData();

  setTimeout(()=>{
    window.$(".round").each(function() {
      var round = window.$(this);
      var roundRadius = round.find('circle').attr('r');
      var roundPercent = round.data('percent');
      var roundCircum = 2 * roundRadius * Math.PI;
      var roundDraw = roundPercent * roundCircum / 100;
      round.css('stroke-dasharray', roundDraw  + ' 999');
    });
  },500)

  ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'  ,
      },
      title: {
        display: true,
        text: 'Summary Statistic',
      },
    },
  };
  const labels = ['Registered Customer', 'Registered Vendors', 'Published Product', 'Total Sale', 'Order', 'Visitors'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Today',
        data: [dashboard.today_new_customer, dashboard.today_new_vendor, dashboard.today_new_product_published, dashboard.today_order_amount, dashboard.today_order_count, dashboard.today_visitors],
        borderColor: 'rgb(255, 193, 7)',
        backgroundColor: 'rgb(255, 193, 7)',
      },
      {
        label: 'Yesterday',
        data: [dashboard.yesterday_customer, dashboard.yesterday_vendor, dashboard.yesterday_product_published, dashboard.yesterday_order_amount, dashboard.yesterday_order_count, dashboard.yesterday_visitors],
        borderColor: 'rgb(226, 78, 77)',
        backgroundColor: 'rgb(226, 78, 77)',
      },
      {
        label: '7 days ago ',
        data: [dashboard.seven_days_customer, dashboard.seven_days_vendor, dashboard.seven_days_product_published, dashboard.seven_days_order_amount, dashboard.seven_days_order_count, dashboard.seven_visitors],
        borderColor: 'rgb(44, 166, 122)',
        backgroundColor: 'rgb(44, 166, 122)',
      },
      {
        label: '30 days ago ',
        data: [dashboard.thirty_days_customer, dashboard.thirty_days_vendor, dashboard.thirty_days_product_published, dashboard.thirty_days_order_amount, dashboard.thirty_days_order_count, dashboard.thirty_visitors],
        borderColor: 'rgb(6, 24, 56)',
        backgroundColor: 'rgb(6, 24, 56)',
      },
    ],
  };

  const formatNumber = (number) =>{
    let total = number
    total = total.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return total;
  }


  return (
      <>
        <div className="row">
          <div className="col">
            <div className="card mt_0  mini-stats">
              <div className="card_heading d_flex_btwn">
                <span className="mr_5"><i className="font_16p fa fa-user"></i> Vendors </span>
                <span className="mr_5 dashboard_span_subheading cursor_pointer" title={'All vendor summary.'}>
                    <i className="fas fa-info-circle"></i>
                  </span>
              </div>
              <div className="card_body">
                <div className="mini-stats-content">
                  <div className="inner">
                    <h2>{dashboard.total_vendors_active}</h2>
                    <p>Active</p>
                  </div>
                  <div className="inner">
                    <h2>{dashboard.total_vendors_inactive}</h2>
                    <p>Inactive/Block</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mt_0 mini-stats">
              <div className="card_heading d_flex_btwn">
                <span className="mr_5"><i className="font_16p fas fa-users"></i> Customers</span>
                <span className="mr_5 dashboard_span_subheading cursor_pointer" title={'All customers summary.'}>
                    <i className="fas fa-info-circle"></i>
                  </span>
              </div>
              <div className="card_body">
                <div className="mini-stats-content">
                  <div className="inner">
                    <h2>{dashboard.total_customers}</h2>
                    <p>Active</p>
                  </div>
                  <div className="inner">
                    <h2>0</h2>
                    <p>Inactive</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mt_0 mini-stats">
              <div className="card_heading d_flex_btwn">
                <span className="mr_5"><i className="font_16p fas fa-dollar-sign"></i> Order</span>
                <span className="mr_5 dashboard_span_subheading cursor_pointer"
                      title={'Total sale summary (exclude cancel and delete order).'}> <i
                    className="fas fa-info-circle"></i></span>
              </div>
              <div className="card_body">
                <div className="mini-stats-content">
                  <div className="inner">
                    <h2>${formatNumber(dashboard.total_sale)}</h2>
                    <p>Sale</p>
                  </div>
                  <div className="inner">
                    <h2>{dashboard.total_order_count}</h2>
                    <p>Count</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card mt_0 mini-stats">
              <div className="card_heading d_flex_btwn">
                <span className="mr_5"><i className="font_16p fas fa-box-open"></i> Product </span>
                <span className="mr_5 dashboard_span_subheading cursor_pointer" title={'Store stock summary'}>
                    <i className="fas fa-info-circle"></i>
                  </span>
              </div>
              <div className="card_body">
                <div className="mini-stats-content">
                  <div className="inner">
                    <h2>{dashboard.total_active_products_active}</h2>
                    <p>Active</p>
                  </div>
                  <div className="inner">
                    <h2>{dashboard.total_active_products_inactive}</h2>
                    <p>InActive</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="row">*/}
        {/*  <div className="col_3">*/}
        {/*    <div className="card mini-stats">*/}
        {/*      <div className="card_heading">*/}
        {/*        <span className="mr_5">*/}
        {/*          <i className="fas fa-calendar-week"></i>*/}
        {/*        </span>*/}
        {/*        Today's Summary*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.today_new_customer}</h2>*/}
        {/*            <p>Registered Customer</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.today_new_vendor}</h2>*/}
        {/*            <p>Registered Vendors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.today_new_product_published}</h2>*/}
        {/*            <p>Product Published</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>${dashboard.today_order_amount ? dashboard.today_order_amount.toFixed(2) : '0.00'}</h2>*/}
        {/*            <p>Total Sale</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.today_order_count }</h2>*/}
        {/*            <p>Order Count</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.today_visitors}</h2>*/}
        {/*            <p>Visitors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col_3">*/}
        {/*    <div className="card mini-stats">*/}
        {/*      <div className="card_heading">*/}
        {/*        <span className="mr_5">*/}
        {/*          <i className="fas fa-calendar-week"></i>*/}
        {/*        </span>*/}
        {/*        Yesterday Summary*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.yesterday_customer}</h2>*/}
        {/*            <p>Registered Customer</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.yesterday_vendor}</h2>*/}
        {/*            <p>Registered Vendors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.yesterday_product_published}</h2>*/}
        {/*            <p>Product Published</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>${dashboard.yesterday_order_amount ? dashboard.yesterday_order_amount.toFixed(2) : '0.00'}</h2>*/}
        {/*            <p>Total Sale</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.yesterday_order_count}</h2>*/}
        {/*            <p>Order Count</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.yesterday_visitors}</h2>*/}
        {/*            <p>Visitors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col_3">*/}
        {/*    <div className="card mini-stats">*/}
        {/*      <div className="card_heading">*/}
        {/*        <span className="mr_5">*/}
        {/*          <i className="fas fa-calendar-week"></i>*/}
        {/*        </span>*/}
        {/*        Statistic From 7 Days Ago*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.seven_days_customer}</h2>*/}
        {/*            <p>Registered Customer</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.seven_days_vendor}</h2>*/}
        {/*            <p>Registered Vendors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.seven_days_product_published}</h2>*/}
        {/*            <p>Product Published</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>${dashboard.seven_days_order_amount ? dashboard.seven_days_order_amount.toFixed(2) : '0.00'}</h2>*/}
        {/*            <p>Total Sale</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.seven_days_order_count }</h2>*/}
        {/*            <p>Order Count</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.seven_visitors}</h2>*/}
        {/*            <p>Visitors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="col_3">*/}
        {/*    <div className="card mini-stats">*/}
        {/*      <div className="card_heading">*/}
        {/*        <span className="mr_5">*/}
        {/*          <i className="fas fa-calendar-week"></i>*/}
        {/*        </span>*/}
        {/*        Statistic From 30 Days Ago*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.thirty_days_customer}</h2>*/}
        {/*            <p>Registered Customer</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.thirty_days_vendor}</h2>*/}
        {/*            <p>Registered Vendors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.thirty_days_product_published}</h2>*/}
        {/*            <p>Product Published</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>${dashboard.thirty_days_order_amount ? dashboard.thirty_days_order_amount.toFixed(2) : '0.00'}</h2>*/}
        {/*            <p>Total Sale</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="card_body">*/}
        {/*        <div className="mini-stats-content">*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.thirty_days_order_count }</h2>*/}
        {/*            <p>Order Count</p>*/}
        {/*          </div>*/}
        {/*          <div className="inner">*/}
        {/*            <h2>{dashboard.thirty_visitors}</h2>*/}
        {/*            <p>Visitors</p>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="row">
          <div className="col_12">
            <div className="card dashboard_selling_stats">
              <div className="card_heading"> Selling</div>
              <div className="card_body">
                <div className="row">
                  <div className="col_8 dashboard_chart">
                    <Line
                        options={options}
                        data={data}
                    />
                  </div>
                  <div className="col_4 pl_0">
                    <div className="dashboard_selling_stats_content">
                      <div className="inner width_50_percent_imp">
                        <div className="inner_wrap">
                          <h2>Today Order Amt</h2>
                          <h1>${dashboard.today_order_amount?.toFixed(2)}
                            {dashboard.today_order_amount > dashboard.yesterday_order_amount ? (
                                <span className="up">${(dashboard.today_order_amount - dashboard.yesterday_order_amount)?.toFixed(2)} <i className="fa fa-arrow-up"></i></span>
                            ) : (
                                <span className="down">${(dashboard.today_order_amount - dashboard.yesterday_order_amount)?.toFixed(2)} <i className="fa fa-arrow-down"></i></span>
                            )}
                          </h1>
                          <p>Yesterday Order Amt: <span>${dashboard.yesterday_order_amount?.toFixed(2)}</span></p>
                        </div>
                      </div>
                      <div className="inner width_50_percent_imp">
                        <div className="inner_wrap border_none">
                          <h2>Today Homepage Visits</h2>
                          <h1>{dashboard.today_visitors}
                            {dashboard.today_visitors > dashboard.yesterday_visitors ? (
                                <span className="up">{dashboard.today_visitors - dashboard.yesterday_visitors}% <i className="fa fa-arrow-up"></i></span>
                            ) : (
                                <span className="down">{dashboard.today_visitors - dashboard.yesterday_visitors}% <i className="fa fa-arrow-down"></i></span>
                            )}
                          </h1>
                          <p>Yesterday Visits: <span> {dashboard.yesterday_visitors} </span></p>
                        </div>
                      </div>

                      <div className="inner width_50_percent_imp">
                        <div className="inner_wrap">
                          <h2>Total Orders Count</h2>
                          <h1>
                            {dashboard.total_order_count}
                          </h1>
                        </div>
                      </div>
                      <div className="inner width_50_percent_imp">
                        <div className="inner_wrap border_none">
                          <h2>Total Sales</h2>
                          <h1>${formatNumber(dashboard.total_sale)} {/*<span className="up">+6.45% <i className="fa fa-arrow-up"></i></span>*/}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="row mt_10">
          <div className="col_9">
            <div className="card best_items">
              <div className="card_heading d_flex_btwn">
                <span>Best Items</span>
                <span className="mr_5 dashboard_span_subheading cursor_pointer"
                      title={'Consider all vendor\'s total best sale products within 15days. If no sale product available then random item could be show'}>
                    <i className="fas fa-info-circle"></i>
                  </span>
              </div>
              <div className="card_body pb_5">
                {bestItems && <>
                  <div className="best_item_content">
                    {bestItems.map((item, index) => (
                        <div className="inner" key={index}>
                          <div className="inner_wrap">
                            <div className="img">
                              <span className="num">{index + 1}</span>
                              <img src={item.image_path} alt="" className="width_full" />
                            </div>
                            <div className="text">
                              <h2>{item.sku}</h2>
                              <h2>{item.total_sales_count ?? 0} <span>sales</span></h2>
                              <p className="border_top">Stocks: {item.available_quantity}</p>
                              <p>Amount : ${item.price}</p>
                            </div>
                          </div>
                        </div>
                    ))}
                  </div>
                </>
                }
              </div>
            </div>
          </div>
          <div className="col_3">
            <div className="card recent_orders">
              <div className="card_heading">
                <span>Recent Order</span>
                <span className="mr_5 dashboard_span_subheading cursor_pointer"
                      title={'Consider all vendor\'s only latest 7 new orders within 15days.'}>
                    <i className="fas fa-info-circle"></i>
                  </span>
              </div>
              <div className="card_body ">
                <div className="recent_orders_content">
                  {recentOrderItems && <>
                    <div className="table_responsive">
                      <table className="table">
                        <tbody>
                        {recentOrderItems.map((order, recentItemIndex) => (
                            <tr key={`recent_order_${recentItemIndex}`}>
                              <td>
                                <div className="name">{order?.customer?.fname} {order?.customer?.lname} <br/>
                                  <span>{order?.customer?.email}</span></div>
                              </td>
                              <td>
                                <button type="button"
                                        className="btn btn_sm has_border_radius_15 btn_black">pending {order.payment_status}</button>
                              </td>
                              <td className="text_right">${order.total} <span className={'width_full'}
                                                                              style={{'fontSize': '10px'}}>{order.created_at}</span>
                              </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>

      </>
  );
};

export default HomePage;