import {getValidationMessage} from "../../../util/validationMessage";
import {useState} from "react";

const Checkbox = ({ label = '',
                    options = [],
                    name,
                    checked = false,
                    onChangeHandler = () => {},
                    register = null,
                    validationRules = {},
                    errors =null,
                    classes = ''}) => {

  const handleChange = (e) => {
    onChangeHandler(e)
  }

  return (
    <div className={`form_row ${(errors && errors[name]) ? 'has_error' : ''} ${ classes }`}>
      <div className="form_checkbox">
        <input type="checkbox"
               id={name}
               onClick={(e)=>handleChange(e)}
               {...register?.(name, validationRules)} />
        <label className={'text_nowrap'} htmlFor={name}>{label}</label>
      </div>

      {errors?.[name] && <small>{getValidationMessage(errors[name], label)}</small>}
    </div>
  );
};

export default Checkbox;