import {useState} from "react";

const TabComponent = ({items, tabChangeEvent = null, classes=''})=> {
  const [activeTab, setActiveTab] = useState(items[0]['id'] || '');

  const selectTab = (id)=>{
    setActiveTab(id)
    if(tabChangeEvent)
      tabChangeEvent(id)
  }

  return (
    <>
      <nav className="tabs">
        <ul>
          {items.map((item) => (
            <li key={item.id}
                href={`#tab_content_${item.id}`}
                onClick={() => selectTab(item.id)}
                className={`${activeTab === item.id && 'active'}`}>{item.title}</li>
          ))}
        </ul>
      </nav>

      <div className={`tab_content_wrapper  ${classes}`}>
        {items.map(item => (
          <div id={`tab_content_${item.id}`}
               key={`tab_content_${item.id}`}
               className={`tab_content ${item.id === activeTab ? 'show' : ''}`}>
            <div className="fadein">
              {item.component}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default TabComponent;