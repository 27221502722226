import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import { getProductReviewDetails} from "../../../../api/products";
import {Link, useParams} from "react-router-dom";
import Card from "../../../../components/UI/Card";
import SubHeadingComponent from "../../../../components/UI/SubHeadingComponent";
import Button from "../../../../components/UI/Button";

const ReviewDetails = () => {
  const params = useParams()
  const [review, setReview] = useState(null);
  const {mutate, isPending} = useMutation({
    mutationFn: (data)=> getProductReviewDetails(data),
    onSuccess: (response, payload) => {
      setReview(response)
    },
  })

  useEffect(()=>{
    if(!review && params){
      mutate(params.id)
    }
  }, [review])

  return(
    <>
      {review && <>
        <div className="width_full d_flex_btwn">
          <h1 className="common_title">Review Details</h1>
          <Link to={`/products/settings/reviews`}>
            <Button
              text="Back to List"
              classes={'has_border_radius'}
              variant={"btn_black"}
            />
          </Link>
        </div>

        <div className="row">
          <div className="col_4">
            <Card cardBodyCls={'pt_10 pb_0'} cardClass={'checkout_summery height_full'}>
              <SubHeadingComponent heading={`Product Information`} classes={'mt_0 mb_5'} />
              <table className="table border_none mb_0">
                <tbody>
                <tr>
                  <td>Name</td>
                  <td className={'width_260p'}>{review.product?.name}</td>
                </tr>
                <tr>
                  <td>SKU</td>
                  <td>
                    <Link to={`/products/edit/${review.product?.id}`}> {review.product?.sku} </Link>
                  </td>
                </tr>
                <tr>
                  <td>Rating</td>
                  <td>
                    {review.product? review.product?.rating.toFixed(1) : '0.00'}*
                  </td>
                </tr>
                </tbody>
              </table>
            </Card>
          </div>

          <div className="col_4">
            <Card cardBodyCls={'pt_10 pb_0'} cardClass={'checkout_summery height_full'}>
              <SubHeadingComponent heading={`Customer Information`} classes={'mt_0 mb_5'} />
              <table className="table border_none mb_0">
                <tbody>
                <tr>
                  <td>Name</td>
                  <td>{review.customer?.name}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{review.customer?.email}</td>
                </tr>
                <tr>
                  <td>Customer Rating</td>
                  <td>{review.rating? review.rating.toFixed(1) : '0.00'}*</td>
                </tr>
                </tbody>
              </table>
            </Card>
          </div>

          <div className="col_4">
            <Card cardBodyCls={'pt_10 pb_0'} cardClass={'checkout_summery height_full'}>
              <SubHeadingComponent heading={`Review Image`} classes={'mt_0 mb_5'} />
              <table className="table border_none mb_0">
                <tbody>
                {review.files.length ? review.files.map((item)=>(
                  <tr key={`item_${item.id}`}>
                    <td>
                      <a target={'_blank'} href={item.location}>
                      <img height={100} src={item.thumbnail} alt=""/>
                      </a>
                    </td>
                  </tr>
                )) : <tr>
                  <td>No Images</td>
                </tr>}
                </tbody>
              </table>
            </Card>
          </div>

        </div>
        <div className="row mt_30">
          <div className="col_12">
            <Card cardBodyCls={'pt_10 pb_0'} cardClass={'checkout_summery height_full'}>
              <SubHeadingComponent heading={review.title} classes={'mt_0 mb_5'} />
              <div className={'mt_10 mb_10'}>
                {review.description}
              </div>
            </Card>
          </div>
        </div>
      </>}
    </>
  );
}

export default ReviewDetails