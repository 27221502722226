import {useEffect, useState} from "react";
import {ReactSortable} from "react-sortablejs";
import Button from "../../../../../components/UI/Button";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteCategory} from "../../../../../api/categories";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";

export const CategoryAccordianItem = ({category, editHandler, categorySort}) =>{
  const [subCategory, setSubCategory] = useState([])
  const [toastId, setToastId] = useState(null);
  const queryClient = useQueryClient()

  const {mutate} = useMutation({
    mutationFn: deleteCategory,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Category Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'categories',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })
  const deleteHandler = (category) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: category.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    })
  }

  useEffect(()=>{
    setSubCategory(category.sub_categories ? category.sub_categories : [])
  }, [category])

  const editCategory = (cat)=>{
    editHandler(cat)
  }
  const shortCategory = (list)=>{
    const checkEquality = JSON.stringify(list) === JSON.stringify(subCategory);
    if(!checkEquality){
      categorySort(list, 'child')
    }

    setSubCategory(list)
  }
  return(
    <div className="accordion">
      <div className="accordion_heading p_relative">
        <div className={`${category.sub_categories && category.sub_categories.length ? 'accordion_heading_inner left' : 'last_category'} `} data-toggle="collapse" data-target={`#parent${category.id}`}>{category.name}</div>
        <div className="category_actions p_absolute">
          <span className={'cursor_pointer'} onClick={()=>editCategory(category)}><img src="/assets/media/images/edit.png" alt="" /></span>
          <span className="pl_15 cursor_pointer" onClick={()=> deleteHandler(category)}><img src="/assets/media/images/trash.png" alt="" /></span>
          <span className="move pl_15"><i className="lni lni-move"></i></span>
        </div>
      </div>
      {category.sub_categories && category.sub_categories.length ?
        <div className="accordion_body pr_0" id={`parent${category.id}`}>
          <div className="accordion_wrap">
            <ReactSortable animation={200}
                           list={subCategory}
                           setList={shortCategory} >
              {subCategory.map(sub => (
                <CategoryAccordianItem key={sub.id} categorySort={(list)=>categorySort(list)} category={sub} editHandler={(sub)=>editCategory(sub)}  />
              ))}
            </ReactSortable>
          </div>
        </div>
        : ''}
    </div>
  )
}

export default CategoryAccordianItem