import {noImagePlaceholder} from "../../../../util/helper";
import EmptyImage from "../../../../components/UI/EmptyImage";

const HomeSectionSelectedProducts = ({tab, control, selectedProductsForStaticMenu, removeSelectedProduct}) => {

    const removeProduct = (index, product) => {
        removeSelectedProduct(index, product.id)
    }

    return (
        <>
            <h1 className="common_sub_title custom_border_bottom common_sub_title d_flex_btwn custom_border_bottom pb_10">
                <span>Selected Products</span>
            </h1>
            <div className="item_img_upload_wrap selected_product_wrap  width_full">
                <div className="image_show_wrap">
                    {selectedProductsForStaticMenu && selectedProductsForStaticMenu.map((product, index) => (
                        <div className="inner" key={`prod_${product.id}`}>
                            <div className="inner_wrap">
                                <div className="remove_list">
                                    <button type="button" className="btn btn_black btn_xs"
                                            onClick={() => removeProduct(index, product.id)}>
                                        <span className="false"><i className="lni lni-close"></i></span>
                                    </button>
                                </div>

                                {product.thumbnail ?
                                    <img className={'width_full'} src={product.thumbnail} alt=""
                                         onError={noImagePlaceholder}/>
                                    :
                                    <EmptyImage/>
                                }
                                <div className="text">
                                    <p className="style_no mb_0 p_5">{product.sku}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default HomeSectionSelectedProducts