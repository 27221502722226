import Button from "../../../components/UI/Button";

const CarriersCardActions = ({isPending, clickHandler, newCarrier}) => {
  return (
    <>
      <Button
        text={`Sync Carriers`}
        classes={"mr_10"}

        disabled={isPending ? true : false}
        onClickHandler={clickHandler}
      />
      <Button
        text={`+ New Carrier`}
        variant={"btn_black"}
        onClickHandler={newCarrier}
      />
    </>
  );
}

export default CarriersCardActions