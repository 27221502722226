import {useForm} from "react-hook-form";
import Card from "../../components/UI/Card";
import {getProductDetails} from "../../api/products";
import { useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ProductFormNew from "../../views/products/product/ProductFormNew";
import ProductFormHeadingButtonGroup from "../../views/products/product/form_includes/ProductFormHeadingButtonGroup";
import Loading from "../../components/UI/Loading";
import {useMutation} from "@tanstack/react-query";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentEditProduct, setSendRequest} from "../../store/slices/productSlice";



const AddEditProducts = () => {
  const product = useSelector(state => state.product.currentEditProduct)
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const paramValue = location.pathname.split('/').pop();
  const isEditMode = !!paramValue;


  const form = useForm({
    defaultValues: {
      status: "1",
      type: "create",
      parent_category_id: "",
      second_category_id: "",
      third_category_id: "",
      sell_out_of_stock: false,
      available_quantity: 999,
      tracking_inventory: false,
      is_physical: false,
      variants:[]
    },
  });

  const {getValues, setValue, clearErrors,
    reset, control, register,
    setError,   watch,
    handleSubmit, formState: { errors }} = form;


  const {mutate, isPending} = useMutation({
    mutationFn: () => getProductDetails(params.id),
    onSuccess: (data) => {
      dispatch(setCurrentEditProduct(data))
    }
  })
  useEffect(() => {
    if (params.id) {
      mutate();
    }
  }, []);

  useEffect(() => {
    if(location.pathname === '/products/add'){
      dispatch(setCurrentEditProduct(null))
    }
    reset();
  }, [location]);

  useEffect(() => {
    if (product && isEditMode) {
      Object.keys(product).forEach(key => {
        if (key === 'variants') {
          if(product.inventories ) {
            product.variants.forEach((variant, i) => {
              setValue(`variants[${i}]`, {name: variant.name, id: variant.id})
              setValue(`variants[${i}].values`, variant.values.map(value => ({
                id: value.id,
                name: value.name
              })))
            })
          } else {
            setValue(`variants`, [])
          }
        } else if (key === 'inventories') {
          if(product.inventories && product.inventories.length) {
            product.inventories.forEach((inventory, i) => {
              setValue(`inventories[${i}]`, {
                id: inventory.id,
                available_quantity: inventory.available_quantity,
                price: inventory.price,
                sku: inventory.sku,
                sell_out_of_stock: inventory.sell_out_of_stock ? 1 : 0,
              })
              setValue(`inventories[${i}].variant_combination`, inventory.variant_combination.map(value => ({
                id: value.id,
                name: value.name,
                value: value.value,
              })))
            })
          } else {
            setValue(`inventories`, [])
          }
        } else if (key === 'status') {
          setValue(key, product[key] ? "1" : "0");
        }
        else {
          setValue(key, product[key] ? (product[key] + "") : "");
        }
      });
    }
  }, [product]);

  const cloneClickHandler = ()=> {
    console.log(435)
  }
  const nextPrevItemClickHandler = (type)=> {
    console.log(type)
  }

  return(
    <>

      <Card title={`${product ? 'Edit' : 'Add'} Product`}  >
        {isPending ?
          <Loading/> :
          <>
            {product && product.validity === 'invalid' && isEditMode ?
              <div className={'d_flex_center'}>
                  <h4>Not Found!</h4>
                  <h4></h4>
              </div> :
              <ProductFormNew watch={watch}
                              setError={setError}
                              setValue={setValue}
                              getValues={getValues}
                              control={control}
                              clearErrors={clearErrors}
                              handleSubmit={handleSubmit}
                              errors={errors}
                              form={form}
                              register={register}/>
            }
          </>
        }
      </Card>

    </>
  );
}

export default AddEditProducts;