import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../util/validationMessage";
import Modal from "../../../components/UI/Modal";
import Input from "../../../components/UI/form/Input";
import Button from "../../../components/UI/Button";
import {storeCourier, updateCourier} from "../../../api/couriers";

const CarrierFormComponent = ({editRecord = null, openModal, setOpenModal}) => {
  const {setValue, reset, register, setError, handleSubmit, formState: { errors }} = useForm();

  useEffect(() => {
    reset();

    if (editRecord) {
      Object.keys(editRecord).forEach(key => {
        if(key === 'status'){
          setValue(key, ""+editRecord[key]);
        } else {
          setValue(key, editRecord[key]);
        }
      });
    }
  }, [openModal, editRecord]);


  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: editRecord ? (data) => updateCourier(editRecord.id, data) : storeCourier,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Courier ${editRecord ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'get-carrier',
      });
      setOpenModal(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }


  return(
    <>
      <Modal title={`${editRecord ? 'Edit' : 'Add'} Carriers`} open={openModal} setOpen={setOpenModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_12">
              <Input label="Name"
                     name="name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_12">
              <Input label="Code"
                     name="code"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
          </div>

          <div className="d_flex_end">
            <Button
              text="save"
              isPending={isPending}
              type={"submit"}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

export default CarrierFormComponent;