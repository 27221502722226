import {createColumnHelper} from "@tanstack/react-table";
import Card from "../../../../components/UI/Card";
import Table from "../../../../components/UI/Table";
import {getProductReviews} from "../../../../api/products";
import ReviewTableActions from "../../../../views/products/settings/review/ReviewTableActions";

const ReviewListPage = () => {
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('title', {
      id: 'title',
      header: 'Title'
    }),
    columnHelper.accessor('rating', {
      id: 'rating',
      header: 'Rating'
    }),
    columnHelper.accessor('customer.name', {
      id: 'customer.name',
      header: 'Customer'
    }),
    columnHelper.accessor('product.sku', {
      id: 'product.sku',
      header: 'Product'
    }),


    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <ReviewTableActions   row={props.row.original} />
    }),
  ];
  return (
    <>
      <Card title={"Product Reviews"}   >
        <Table
          queryKey={"reviews"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getProductReviews}
        />
      </Card>
    </>
  );

}

export default ReviewListPage