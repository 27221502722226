import { useState, useEffect } from 'react';
import Card from "../../components/UI/Card";
import Button from "../../components/UI/Button";
import { generateMonthlySalesReport } from "../../api/report";
import { saveAs } from 'file-saver';

export default function MonthlySalesByReport() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    setSelectedMonth(currentMonth);
  }, [currentMonth]);

  const generateReportHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await generateMonthlySalesReport({ month: selectedMonth, year: selectedYear });
      const byteCharacters = atob(response);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `Monthly_Sales_Report_${selectedYear}_${selectedMonth}.xlsx`);
    } catch (error) {
      console.error('Error generating report:', error);
    }
  }

  return (
    <Card title="Monthly Sales by Brand">
      <form onSubmit={generateReportHandler}>
        <div className="row">
          <div className="col_2">
            <select className="form_global" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>

          <div className="col_2">
            <select className="form_global" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>

          <div className="col_4">
            <Button text="Generate" type="submit" />
          </div>
        </div>
      </form>
    </Card>
  );
}