import {createSlice} from "@reduxjs/toolkit";

export const homePageSectionSlice = createSlice({
  name: 'homePageSection',
  initialState:{
    editSection: null,
  },
  reducers:{
    setEditSection:(state, action)=>{
      state.editSection = action.payload
    }
  }
});

export const {
  setEditSection
} = homePageSectionSlice.actions

export default homePageSectionSlice.reducer;