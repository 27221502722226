import {useState} from "react";
import {createColumnHelper} from "@tanstack/react-table";
import Card from "../../../components/UI/Card";
import Table from "../../../components/UI/Table";
import {getShippingMethods, syncCarriersService} from "../../../api/shippingMethods";
import ShippingMethodTableActions from "../../../views/settings/shipping-method/ShippingMethodTableActions";
import ShippingMethodCardActions from "../../../views/settings/shipping-method/ShippingMethodCardActions";
import ShippingMethodFormComponent from "../../../views/settings/shipping-method/ShippingMethodFormComponent";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";

const ShippingMethodPageComponent = () => {
  const [openModal, setOpenModal] = useState(false)
  const [shippingMethod, setShippingMethod] = useState(null)
  const queryClient = useQueryClient()
  const [toastId, setToastId] = useState(null);

  const editHandler = (row) =>{
    setShippingMethod(row)
    setOpenModal(true)
  }

  const addNewShipping = () =>{
    setShippingMethod(null);
    setOpenModal(true);
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),
    columnHelper.accessor('code', {
      id: 'code',
      header: 'Code'
    }),
    columnHelper.accessor('carrierCode', {
      id: 'carrierCode',
      header: 'Carrier Code'
    }),
    columnHelper.display( {
      id: 'status',
      header: 'Status',
      cell: props => <button className={ `btn btn_sm has_border_radius ${props.row.original.status === '1' ? 'btn_success' : 'btn_primary'}`}>{props.row.original.status === '1' ? 'Active' : 'Inactive'}</button>
    }),
    columnHelper.display( {
      id: 'domestic',
      header: 'Domestic',
      cell: props => <span className={'w_b_label'}>{props.row.original.domestic === '1' ? 'Yes' : 'No'}</span>
    }),
    columnHelper.display( {
      id: 'international',
      header: 'International',
      cell: props => <span className={'w_b_label'}>{props.row.original.international === '1' ? 'Yes' : 'No'}</span>
    }),
    columnHelper.accessor('courier.name', {
      id: 'courier.name',
      header: 'Courier'
    }),

    columnHelper.accessor('fee', {
      id: 'fee',
      header: 'Fee',
      cell: props => <span> ${props.row.original.fee.toFixed(2)} </span>
    }),
    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <ShippingMethodTableActions editRecord={editHandler} row={props.row.original} />
    }),
  ];


  const {mutate, isPending} = useMutation({
    mutationFn: syncCarriersService,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'shipping-methods',
      });
      toast.update(toastId, { render: "Carriers has been sync!", type: "success", isLoading: false, autoClose: true  });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const syncShippingService = () => {
    confirmAlert({
      title: 'Sync Shipping Service',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate()
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }

  return (
    <>
      <Card title={"Shipping Methods"}  CardActions={<ShippingMethodCardActions clickHandler={syncShippingService} newShipping={addNewShipping}/>}>
        <Table
          queryKey={"shipping-methods"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getShippingMethods}
        />
      </Card>

      <ShippingMethodFormComponent editRecord={shippingMethod}
                            openModal={openModal}
                            setOpenModal={setOpenModal} />
    </>
  )
}

export default ShippingMethodPageComponent