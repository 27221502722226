import {useSelector} from "react-redux";
import Card from "../../../../components/UI/Card";

const VendorInformation = () => {
  const {orderForm} = useSelector(state => state.orders)
  return(
    <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">
        <span> Vendor Information</span>
      </h1>
      {(orderForm && orderForm.vendor) &&
        <div className="order_summery_table">
        <table className="table border_none">
        <tbody>
        <tr>
        <td className="text_left width_100p fw_600">Name</td>
        <td>{orderForm.vendor.name}</td>
        </tr>
        <tr>
        <td className="text_left fw_600">Email</td>
        <td>{orderForm.vendor.email}</td>
        </tr>
        <tr>
        <td className="text_left fw_600">Phone</td>
        <td>{orderForm.vendor.phone}</td>
        </tr>

        </tbody>
        </table>
        </div>
      }
    </Card>
  )
}

export default VendorInformation