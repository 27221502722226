import API from "../util/api";

export const getCustomers = async (params) => {
    let data = null;

    await API.get('/customers', {params})
        .then(res => {
            data = res.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const searchCustomer = async (params) => {
    let data = null;
    await API.get('/customers/search', {params})
      .then(res => {
          data = res.data;
      })
      .catch((err) => {
          throw new Error(JSON.stringify(err.response.data))
      });

    return data;
}

export const getCustomerDetails = async (payload) => {
    let data = null;
    await API.get('/customers/'+payload)
        .then(res => {

            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const storeCustomer = async (payload) => {
    let data = null;

    await API.post('/customers', payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const updateCustomer = async (id, payload) => {
    let data = null;

    await API.patch(`/customers/${id}`, payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const changeCustomerActiveStatus = async (payload) => {
    await API.post('/customers/status-change/'+payload.id, {active: payload.status})
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return true;
}

export const customerMaillingList = async (payload) => {
    await API.post('/customer/mailing-list/'+payload.id, {mailing_list: payload.mailing_list})
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return true;
}

export const customerDelete = async (payload) => {
    await API.delete('/customers/'+payload.id)
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return true;
}