import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";
import React from "react";

const IncompleteTableActions = ({row}) => {
    return (
        <Link to={`/incomplete-checkout/${row.id}/${row.type}`} className="mr_5">
            <Button text={'Details'} variant={'btn_black'} />
        </Link>
    );
}

export { IncompleteTableActions }