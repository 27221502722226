import {useForm} from "react-hook-form";
import Card from "../../components/UI/Card";
import Loading from "../../components/UI/Loading";
import ProductImportForm from "../../views/products/product/ProductImportForm";

const ImportProducts = () => {
  const isPending = false

  const form = useForm({
    defaultValues: {
      file: null
    },
  });

  const {getValues, setValue, clearErrors,
    control, register,
    setError,   watch,
    handleSubmit, formState: { errors }} = form;


  // const {mutate, isPending} = useMutation({
  //   mutationFn: () => getProductDetails(params.id),
  //   onSuccess: (data) => {
  //     dispatch(setCurrentEditProduct(data))
  //   }
  // })

  return(
    <>

      <Card title={`Import Products`}>
        {isPending ?
          <Loading/> :
          <>
              <ProductImportForm
                  watch={watch}
                  setError={setError}
                  setValue={setValue}
                  getValues={getValues}
                  control={control}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  form={form}
                  register={register}
              />
          </>
        }
      </Card>

    </>
  );
}

export default ImportProducts;