import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";

const PageCardActions = ({clickHandler}) => {
return(
  <Link to={'/pages/new'} >
  <Button
    text="+ Add New Page"
    variant={"btn_black"}
  />
  </Link>
)
}

export default PageCardActions