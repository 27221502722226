import Button from "../../../../../components/UI/Button";
import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import { productCombinedAction} from "../../../../../api/products";

const ProductModificationActionsComponent = ({activeTab, selectUnselect, selectedProducts, actionComplete, categories=[]})=> {

  const [form, setForm] = useState({product_ids: [], action: '', category: ''})

  const selectToggle = (type)=> {
    selectUnselect(type)
  }

  const [toastId, setToastId] = useState(null);
  const {mutate} = useMutation({
    mutationFn: ()=>productCombinedAction(form),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Action Successfull!", type: "success", isLoading: false, autoClose: true  });
      actionComplete('complete')
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const selectedItemActionConfirmation = (type)=>{
    if(selectedProducts.length){
      setForm(queryParam => ({...queryParam, action:type, product_ids: [...selectedProducts]}));
      mutate(form)
    }
  }
  const selectCategory = (e)=>{
    setForm(queryParam => ({...queryParam, category: e.target.value}));
  }

  return (
    <div className="d_flex_btwn pt_10 pb_15">
      <div className="d_flex_inline">
        <div className="form_row mb_0">
          <div className="form_input">
            <select className="form_global width_150p mr_10" onChange={(e)=>selectCategory(e)}>
              <option value={''}>Move</option>
              {categories && categories.map(category=>(
                <>
                  <option value={category.id}>{category.name}</option>
                  {category.sub_categories.length && category.sub_categories.map((sub)=>(
                    <>
                    <option value={`${category.id},${sub.id}`}>--{sub.name}</option>
                      {sub.sub_categories.length && sub.sub_categories.map((third)=>(

                          <option value={`${category.id},${sub.id},${third.id}`}>----{third.name}</option>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </select>
            {(selectedProducts && selectedProducts.length && form.category) ?
              <Button text={"Move"}
                      onClickHandler={() => selectedItemActionConfirmation('move')}
                      variant={"btn_black"} />
            : ''}
          </div>
        </div>
      </div>

      <div className="d_flex_inline">
        <span onClick={()=> selectToggle('select')} className="td_underline mr_10 cursor_pointer">Select All</span>
        {selectedProducts && selectedProducts.length ? <span onClick={()=> selectToggle('unselect')} className="td_underline mr_10 cursor_pointer">UnSelect All</span> :''}
        <Button text={activeTab ? 'Deactivate' : 'Activate'}
                onClickHandler={() => selectedItemActionConfirmation(activeTab ? 'deactivate' : 'activate')} />

        {!activeTab &&
        <Button text={"Delete"}
                classes={'ml_10'}
                onClickHandler={() => selectedItemActionConfirmation('delete')}
                variant={"btn_danger"} />}

      </div>
    </div>
  );
}

export default ProductModificationActionsComponent;