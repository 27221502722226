import styles from './SubHeadingComponent.module.css';

const SubHeadingComponent = ({heading, classes = ''}) => {
  return (
    <>
      <h1 className={`common_sub_title custom_border_bottom  ${classes}`}>{heading}</h1>
    </>
  );
};

export default SubHeadingComponent;