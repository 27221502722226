import API from "../util/api";

export const getIncompleteCheckout = async (params) => {
  let data = null;

  await API.get('/incomplete-checkout', {params:{...params}})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const getIncompleteCheckoutDetails = async (payload) => {
  let data = null;
  await API.get('/incomplete-checkout/'+payload.id+'?type='+payload.type)
      .then(res => {

        data = res.data;
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return data;
}