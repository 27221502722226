import { NavLink, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {activeRoute} from "../../util/helper";
import {useQuery} from "@tanstack/react-query";
import {getAllPage} from "../../api/page";

const LeftNavComponent = () => {
  const [parentIndex, setParentIndex] = useState(null);
  const [childIndex, setChildIndex] = useState(null);

  const selectParentMenu = (index) => {
    // setParentIndex((prevIndex) => (prevIndex === index ? null : index));
    setParentIndex(null);
  };


  const location = useLocation()
  useEffect(() => {
    let currentRoute = activeRoute(location.pathname)
    if(currentRoute.length)
      setParentIndex(currentRoute[0])
    if(currentRoute.length > 1)
      setChildIndex(currentRoute[1])

    if(!currentRoute.length)
      setParentIndex(null)
  }, [location]);

  const redirectHandler = (event, to) => {
    if (to === location.pathname) {
      event.preventDefault();
    }
  };
  const hasChildMenu = (list) => {
    if (list.includes(childIndex))
      return true;
    return false
  };


  const {data: pages} = useQuery({
    queryKey:[`pages`],
    queryFn: () => getAllPage()
  })

  return (
    <div id="left_nav">
      <ul className="left_nav_list">
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${!parentIndex ? 'active' : ''}`}>
            <NavLink className={' width_full'} onClick={(event) => redirectHandler(event, '/')}
                     end to="/"> <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/icon-new/dashboard.png" alt=""/>
            </span><span>Dashboard</span></NavLink>
          </div>
        </li>
        <li>
          <div data-toggle="accordion" data-target="#vendors"
               className={`left_f_list cursor_pointer ${parentIndex === 'vendors' ? ' open active' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/icon-new/vendor.png" alt=""/>
            </span> <span>Vendors</span>
          </div>
          <ul className={`sub_accordion first_level ${parentIndex === 'vendors' ? ' current' : ''}`} id={'vendors'}>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/vendors')} end to="/vendors">Vendor List</NavLink>
            </li>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/vendors/add')} end to="/vendors/add">Add New
                Vendor</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div data-toggle="accordion" data-target="#customers"
               className={`left_f_list cursor_pointer ${parentIndex === 'customers' ? ' open active' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
            <img src="/assets/media/images/left-icon/icon-new/customers.png" alt=""/>
            </span> <span>Customers</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'customers' ? ' current' : ''}`}
              id={'customers'}>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/customers')}
                       end to="/customers">Customer List</NavLink>
            </li>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/customers/add')}
                       end to="/customers/add">Add New Customer</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'orders' ? 'active open' : ''}`}>
            <NavLink onClick={(event) => redirectHandler(event, '/orders')}
                     end to="/orders"> <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/icon-new/order.png" alt=""/>
            </span><span>Orders</span></NavLink>
          </div>
        </li>
        <li>
          <div
            className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'incomplete-checkout' ? 'active' : ''}`}>
            <NavLink className="width_full" end to="/incomplete-checkout"> <span className={'left_img'}>
            <img src="/assets/media/images/left-icon/icon-new/page.png" alt=""/>
            </span> <span>Incomplete Checkout</span> </NavLink>
          </div>
        </li>
        <li>
          <div data-toggle="accordion" data-target="#products"
               className={`left_f_list cursor_pointer ${parentIndex === 'products' ? 'active open' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/icon-new/product.png" alt=""/>
            </span> <span>Products</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'products' ? ' current' : ''}`} id={'products'}>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/products')}
                       end to="/products">Product List</NavLink>
            </li>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/products/add')}
                       to="/products/add">Add New Product</NavLink>
            </li>
            <li>
              <NavLink onClick={(event) => redirectHandler(event, '/products/import')}
                       to="/products/import">Import Product</NavLink>
            </li>

            <li className="left_nav_level2">
              <div data-toggle="accordion_level2" data-target="#Attribute"
                   className={`left_f_list_level2 ${hasChildMenu(['categories', 'static-menus']) ? 'open' : ''}`}>
                <a className={"cursor_pointer"}> <span>Settings</span></a>
              </div>
              <ul
                className={`sub_accordion sub_accordion2 second_level ${hasChildMenu(['categories', 'static-menus']) ? 'current d_block' : ''} `}
                id="Attribute">
                <li><NavLink end to="/products/categories">categories</NavLink></li>
                <li><NavLink end to="/products/static-menus">Static Menu</NavLink></li>
                <li><NavLink to="products/settings/reviews">Reviews</NavLink></li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <div
            className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'home-page-sections' ? 'active' : ''}`}>
            <NavLink end to="/home-page-sections"> <span className={'left_img'}>
            <img src="/assets/media/images/left-icon/icon-new/section.png" alt=""/>
            </span> <span>Home Page Section</span> </NavLink>
          </div>
        </li>

        <li>
          <div data-toggle="accordion" data-target="#banners"
               className={`left_f_list cursor_pointer ${parentIndex === 'banners' ? ' open active' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/icon-new/banner.png" alt=""/>
            </span> <span>Banners</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'banners' ? ' current' : ''}`} id={'banners'}>
            <li><NavLink end to="/banners/1">Main Banner Desktop</NavLink></li>
            <li><NavLink end to="/banners/3">Main Banner Mobile</NavLink></li>
          </ul>
        </li>

        <li>
          <div data-toggle="accordion" data-target="#pages"
               className={`left_f_list cursor_pointer ${parentIndex === 'pages' ? ' open active' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
            <img src="/assets/media/images/left-icon/icon-new/page.png" alt=""/>
            </span> <span>Pages</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'pages' ? ' current' : ''}`} id={'pages'}>
            <li><NavLink end to={`/pages`}>All Pages</NavLink></li>
            {pages && pages.data.map((page) => (
              <li key={`page_${page.id}`}><NavLink end to={`/pages/edit/${page.id}`}>{page.title_en}</NavLink></li>
            ))}
            <li><NavLink end to={`/pages/new`}>+ Add New Page</NavLink></li>
          </ul>
        </li>
        {/*<li>*/}
        {/*  <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'social-media' ? 'active' : ''}`  }>*/}
        {/*    <NavLink end to="/social-media"> <span className={'left_img'}><i className="font_16p fas fa-share-alt"></i></span> <span>Social Media</span> </NavLink>*/}
        {/*  </div>*/}
        {/*</li>*/}
        <li>
          <div data-toggle="accordion" data-target="#settings"
               className={`left_f_list cursor_pointer ${parentIndex === 'settings' ? ' open active' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/left-ic-7.png" alt=""/>
            </span> <span>Settings</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'settings' ? ' current' : ''}`} id={'settings'}>
            <li><NavLink end to={`/settings/customer-service-information`}>Customer Service Info</NavLink></li>
            <li><NavLink end to="/settings/shipping-methods">Shipping Methods</NavLink></li>
            {/*<li><NavLink end to="/settings/couriers">Couriers</NavLink></li>*/}
            <li><NavLink end to="/settings/carriers">Carriers</NavLink></li>
          </ul>
        </li>
        <li>
          <div data-toggle="accordion" data-target="#transactions"
               className={`left_f_list cursor_pointer ${parentIndex === 'transactions' ? ' open active' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/icon-new/Transaction.png" alt=""/>
            </span> <span>Transactions History</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'transactions' ? ' current' : ''}`}
              id={'transactions'}>
            <li><NavLink end to="/transactions/transactions" className={'color_black'}>Transactions</NavLink></li>
            <li><NavLink end to="/transactions/withdraw-requests" className={'color_black'}>Withdraw Requests</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className={`left_f_list no_arrow cursor_pointer ${parentIndex === 'coupons' ? 'active' : ''}`}>
            <NavLink end to="/coupons"> <span className={'left_img'}>
            <img src="/assets/media/images/left-icon/icon-new/coupon.png" alt=""/>
            </span> <span>Coupons</span> </NavLink>
          </div>
        </li>
        <li>
          <div data-toggle="accordion" data-target="#reports"
               className={`left_f_list cursor_pointer ${parentIndex === 'reports' ? ' open active' : ''}`}
               onClick={selectParentMenu}>
            <span className={'left_img'}>
              <img src="/assets/media/images/left-icon/icon-new/page.png" alt=""/>
            </span> <span>Report</span>
          </div>

          <ul className={`sub_accordion first_level  ${parentIndex === 'reports' ? ' current' : ''}`}
              id={'reports'}>
            <li><NavLink end to="/reports/monthly-sales-by-brand" className={'color_black'}>Monthly Sales By Brand</NavLink></li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default LeftNavComponent;