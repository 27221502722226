import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../../../components/UI/Table";
import {getStaticMenusVideo} from "../../../../api/staticMenuVideo";
import Button from "../../../../components/UI/Button";
import StaticMenuVideoTableActions from "./StaticMenuVideoTableActions";
import {useEffect, useState} from "react";
import Modal from "../../../../components/UI/Modal";

const StaticMenuVideoTable = ({editRecord}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//vjs.zencdn.net/8.17.3/video.min.js';
        script.async = true;

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '//vjs.zencdn.net/8.17.3/video-js.min.css';

        document.head.appendChild(script);
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(script);
            document.head.removeChild(link);
        };
    }, [editRecord]);

    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('url', {
            id: 'url',
            header: 'Video Type',
            cell: props => <span >{props.row.original.type === 'youtube' ? 'Youtube' : 'Video' }</span>
        }),
        columnHelper.accessor('status', {
            id: 'status',
            header: 'Status',
            cell: props => <Button text={props.row.original.status ? "Active" : 'Inactive'} classes={`btn_sm has_border_radius  ${!props.row.original.status && 'btn_warning'}`} />
        }),
        columnHelper.accessor('id', {
            id: 'id',
            header: 'Play',
            cell: props => <span className={'cursor_pointer font_18p'} onClick={()=>handlePlayer(props.row.original)}>{props.row.original.type === 'youtube' ? <i className="fab fa-youtube"></i> :
                <i className="fas fa-play-circle"></i>}</span>
        }),

        columnHelper.display( {
            id: 'actions',
            header: "Actions",
            classes:'text_right',
            cell: props => <StaticMenuVideoTableActions   editRecord={editRecord} row={props.row.original} />
        }),
    ];

    const [videoPlayer, setVideoPlayer] = useState(null);
    const [videoModal, setVideoModal] = useState(false);

    const handlePlayer =(row) =>{
        setVideoPlayer(row)
        setVideoModal(true)
    }
    const closeModal =() =>{
        setVideoPlayer(null)
        setVideoModal(false)
    }
    return (
        <>
            <Table
                queryKey={"static-menu-videos"}
                columns={columns}
                queryParams={{per_page: 10}}
                queryFn={getStaticMenusVideo}
            />

            <Modal open={videoModal} setOpen={closeModal} size={"modal_850p"}>
                {videoPlayer &&
                    <>
                        {videoPlayer.type === 'video' ?
                        <video
                            id="my_video"
                            className="video-js vjs-default-skin video_player"
                            controls
                            preload="auto"
                            width="600"
                            height="300"
                            data-setup="{}"
                        >
                            <source src={videoPlayer.url} type="video/mp4"/>

                        </video>
                            :
                            <>
                                <div className="youtube-embed" dangerouslySetInnerHTML={{ __html: videoPlayer.url }}>

                                </div>
                            </>
                        }
                    </>
                }
            </Modal>


        </>
    )
}
export default StaticMenuVideoTable