import API from "../util/api";

export const getStaticMenusVideo = async (params) => {
    let data = null;

    await API.get('/static-menu-videos', {params})
        .then(res => {
            data = res.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });
    return data;
}
export const getStaticMenuVideoDetails = async (payload) => {
    let data = null;
    await API.get('/static-menu-videos/' + payload)
        .then(res => {

            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}
export const deleteStaticMenuVideo = async (params) => {
    let data = null;

    await API.delete('/static-menu-videos/'+params.id)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const storeStaticMenuVideo = async (payload) => {
    let data = null;

    await API.post('/static-menu-videos', payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const sortStaticMenuVideo = async (payload) => {
    let data = null;
    await API.post('/static-menu-videos/sort', payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const updateStaticMenuVideo = async (id, payload) => {
    let data = null;
    payload.append('_method','PATCH')
    await API.post(`/static-menu-videos/${id}`, payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

