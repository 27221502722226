import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {
  getSingleHomePageSections,
  storeHomePageSections,
  updateHomePageSections
} from "../../api/homePageSections";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import Button from "../../components/UI/Button";
import Card from "../../components/UI/Card";
import HomeSectionBannerForm from "../../views/home-page-section/banner/HomeSectionBannerForm";
import HomeSectionProductComponent from "../../views/home-page-section/product/HomeSectionProductComponent";
import HomeSectionFormCommonComponent from "../../views/home-page-section/HomeSectionFormCommonComponent";
import HomeSectionTabComponent from "../../views/home-page-section/tab/HomeSectionTabComponent";
import {useLoaderData, useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setEditSection} from "../../store/slices/homePageSectionSlice";

export async function preloadEditSection({params}){
  const [section] = await Promise.all([
    getSingleHomePageSections(params.id)
  ]);
  return {section};
}

const HomePageSectionForm = () => {
  const {editSection} = useSelector(state => state.homePageSection)
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  const loaderData = useLoaderData();
  if(loaderData) {
    let section = loaderData.section
    if(section){
      dispatch(setEditSection(section))
    }
  }

  const { reset, register, watch, setError, clearErrors, setValue,getValues, handleSubmit, control, formState: { errors }} = useForm({
    defaultValues: {
      status: "1",
    },
  });
  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: editSection ? (data) => updateHomePageSections(editSection.id, data) : (data)=> storeHomePageSections(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Home Section ${editSection ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === `home-page-section`,
      });
      reset();
      navigate('/home-page-sections');
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    let formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key] ? data[key] : '');
    })

    if(data.banner_1 && data.banner_1[0]) {
      const file = data.banner_1[0];
      if (file)
        formData.append('banner_1', file)
    }

    if(data.banner_2 && data.banner_2[0]) {
      const file = data.banner_2[0];
      if (file)
        formData.append('banner_2', file)
    }

    if(data.products && data.products.length > 0) {
      const ids = data.products.map(({ id }) => id);
      formData.append('products', ids)
    }

    if(data.tabs && data.tabs.length > 0) {
      formData.delete('tabs')
      formData.append('tabs', JSON.stringify(data.tabs))
    }

    mutate(formData)
  }

  useEffect(() => {
    if (!params.id) {
      reset();
    }
  }, [location]);

  return (
    <Card title={'Create New Home Page Section'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HomeSectionFormCommonComponent
          getValues={getValues}
          setValue={setValue}
          register={register}
          errors={errors}
          watch={watch}
        />
        <div className="row">

          {(watch('type') === 'product_type_1' || watch('type') === 'product_type_2' || watch('type') === 'product_type_3' || watch('type') === 'product_type_4')  &&
          <div className="col_12 ">
            <HomeSectionProductComponent
              getValues={getValues}
              setValue={setValue}
              register={register}
              errors={errors}
              watch={watch}
              control={control}
              clearErrors={clearErrors}
            />
          </div>
          }

          {(watch('type') === 'banner_type_1' || watch('type') === 'banner_type_2') &&
          <div className="col_12 ">
            <HomeSectionBannerForm
              getValues={getValues}
              setValue={setValue}
              register={register}
              errors={errors}
              watch={watch}
            />
          </div>
          }

          {watch('type') === 'tab' &&
          <div className="col_12 mt_10">
            <HomeSectionTabComponent
              getValues={getValues}
              setValue={setValue}
              register={register}
              errors={errors}
              watch={watch}
              control={control}
              clearErrors={clearErrors}
            />
          </div>
          }

          <div className="col_12">
            <div className="d_flex_end">
              <Button
                text="save"
                isPending={isPending}
                type={"submit"}
              />
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
}

export default HomePageSectionForm