import {Link} from "react-router-dom";
import Button from "../../../components/UI/Button";
import Card from "../../../components/UI/Card";
import React, {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {deleteCouponCode, updateCouponCode} from "../../../api/coupon";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import AddCodeForm from "./AddCodeForm";
import AddCodeCardButton from "./AddCodeCardButton";

const CouponPromoCodesCard = ({coupon, deleteConfirm})=>{
    const [toastId, setToastId] = useState(null);
    const [codes, setCodes] = useState(coupon.codes ? coupon.codes : []);
    const [openModal, setOpenModal] = useState(false)
    const {mutate,isPending} = useMutation({
        mutationFn: deleteCouponCode,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Code Deleted!", type: "success", isLoading: false, autoClose: true  });
            deleteConfirm()
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })

    const deleteHandler = (id) => {
        confirmAlert({
            title: 'Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mutate({id: id})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });

    };

    const {mutate:statusMutute} = useMutation({
        mutationFn: (data) => updateCouponCode(data),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Code Updated!", type: "success", isLoading: false, autoClose: true  });
            setCodes(response)
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })

    const handleChange = (e, id) => {
        statusMutute({active: e.target.checked, id:id})
    }

    return (
        <>
            <Card title={"Promotion Codes"} CardActions={<AddCodeCardButton setModalStatus={setOpenModal} />}>
                <div className="row">
                    <div className="col_12">
                        <div className="table_responsive">
                            {coupon.codes.length ?
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th> Redemptions</th>
                                        <th>Created</th>
                                        <th>Expires</th>
                                        <th>Status</th>
                                        <th className={'text_right'}>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {codes && codes.map((item, i)=>(
                                        <tr key={'code' + i}>
                                            <td>{`${item.code}`}</td>
                                            <td>{item.times_redeemed ? item.times_redeemed : 0}{item.max_redemptions ? `/${item.max_redemptions}` : ''}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.expires_at}</td>
                                            <td>
                                                <div className={`form_row`}>
                                                    <div className="form_checkbox">
                                                        <input type="checkbox"
                                                               id={`status ${i}`}
                                                               checked={item.active ? true : false}
                                                               onClick={(e) => handleChange(e, item.id)}/>
                                                        <label htmlFor={`status ${i}`}></label>
                                                    </div>

                                                </div>
                                            </td>
                                            <td className={'text_right'}>
                                                <Link to={"/coupons-code/" + item.id} className="mr_5">
                                                    <Button text={'Details'} size={'btn_sm'}
                                                            classes={'has_border_radius'}/>
                                                </Link>
                                                <Button text={'Delete'} size={'btn_sm'} variant={'btn_danger'}
                                                        classes={'has_border_radius'} isPending={isPending}
                                                        onClickHandler={() => deleteHandler(item.id)}/>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> : ''
                            }
                        </div>
                    </div>
                </div>
            </Card>
            <AddCodeForm
                coupon={coupon}
                openModal={openModal}
                setOpenModal={setOpenModal}
                updateConfirm={deleteConfirm}
            />
        </>
    )
}

export default CouponPromoCodesCard