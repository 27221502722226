import {createSlice} from "@reduxjs/toolkit";

export const staticMenuSlice = createSlice({
    name: 'staticMenu',
    initialState: {
        selectedMenus: [],
        allMenus: [],
        currentEditMenu: null,
    },
    reducers: {
        selectUnselectMenu: (state, action) => {
            const id = action.payload;
            const existingItemIndex = state.selectedProducts.findIndex(item => item === +id);

            if (existingItemIndex !== -1) {
                state.selectedProducts = state.selectedProducts.filter(item => item !== +id);
            } else {
                state.selectedProducts.push(+id);
            }
        },
        setSelectToggle: (state, action) => {
            if(action.payload.type === 'select'){
                state.selectedProducts = []
                state.allProducts.map((item) => state.selectedProducts.push(item.id))
            }

            if(action.payload.type === 'unselect'){
                state.selectedProducts = []
            }
        },
        setAllMenus: (state, action) => {
            state.allMenus = action.payload
        },
        setCurrentEditMenu: (state, action) => {
            state.currentEditMenu = action.payload
        },
    }
});

export const {
    selectUnselectProduct,
    setAllProducts,
    setCurrentEditMenu,
    setSelectToggle
} = staticMenuSlice.actions;

export default staticMenuSlice.reducer;