import ProductFilterComponent from "./product/List/includes/ProductFilterComponent";
import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@tanstack/react-query";
import {getProducts} from "../../api/products";
import {
  setAllProducts,
  setParams,
  setProductPagination,
  setRequestStatus,
  setSendRequest
} from "../../store/slices/productSlice";
import React, {useEffect, useState} from "react";
import Pagination from "../../components/UI/Pagination";
import SelectableProductSingleComponent from "./SelectableProductSingleComponent";
import Modal from "../../components/UI/Modal";

const SelectableProductListComponent = ({selectUnselectProduct, index, selectedProducts}) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state.product);
  const [openModal, setOpenModal] = useState(false);

  const {mutate:getProductMutate, isPending, response} = useMutation({
    mutationFn: (variables) => getProducts({...store.initialParams, status: 1, per_page: 16}),

    onSuccess: (response, payload) => {
      dispatch(setAllProducts(response.data))
      dispatch(setProductPagination(response.meta))
    }
  });

  useEffect(()=>{
    if(store.sendRequest) {
      getProductMutate()
    }
  },[store.sendRequest])

  useEffect(()=>{
    getProductMutate()
  },[])

  useEffect(()=>{
    if(!isPending)
      dispatch(setSendRequest(false))

    dispatch(setRequestStatus(isPending))
  },[isPending])

  const changePage = (page) => {
    dispatch(setParams({...store.initialParams, page}))
    dispatch(setSendRequest(true))
  }

  const setInitialParams = (params) => {
    dispatch(setParams({...params}))
    dispatch(setSendRequest(true))
  }

  const openFilterModal = () => {
    setOpenModal(true)
  }

  return (
    <>
      <h1 className="common_sub_title d_flex_btwn custom_border_bottom pb_10">
        <span>Products</span>
      </h1>
      <ProductFilterComponent setInitialParams={setInitialParams} initialParams={store.initialParams} />

      <div className="item_list_wrapper page_section_list_wrapper width_full">
        <div className="item_list_wrap">
          {store.allProducts.map((product, i) => (
            <SelectableProductSingleComponent selectProduct={selectUnselectProduct} product={product} key={i} index={index} selectedProducts={selectedProducts}/>
          ))}
        </div>
        {(store.paginations && store.paginations.per_page < store.paginations.total) ?
          <Pagination meta={store.paginations} changePageFn={changePage} isPlaceholderData={false}/> : ''}
      </div>

    </>
  );
}
export default SelectableProductListComponent