import API from "../util/api";

export const getVendors = async (params = {}) => {
  let data = null;

  await API.get('/vendors', {params})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
};

export const changeActiveStatus = async (payload) => {
  await API.post('/vendor/active/' + payload.id, {
    active: payload.status
  }).catch((err) => {
    throw new Error(JSON.stringify(err.response.data))
  });

  return true;
};

export const changeVerifyStatus = async (payload) => {
  await API.post('/vendor/verified/' + payload.id, {
    status: payload.status
  }).catch((err) => {
    throw new Error(JSON.stringify(err.response.data))
  });

  return true;
};

export const storeVendors = async (payload) => {
  let data = null;

  await API.post('/vendors', payload)
      .then(res => {
        data = res.data.data;
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return data;
}

export const updateVendor = async (vendorId, payload) => {
  let data = null;
  payload.append('_method','PATCH')
  await API.post(`/vendors/${vendorId}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getVendorDetails = async (payload) => {
  let data = null;
  await API.get('/vendors/'+payload)
      .then(res => {

        data = res.data.data;
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return data;
}
export const getVendorAccounts = async (payload) => {
  let data = null;
  await API.get('/commission-balance-info/'+payload)
      .then(res => {

        data = res.data;
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return data;
}
export const vendorDelete = async (payload) => {
  await API.delete('/vendors/'+payload.id)
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return true;
}

export const updateVendorUser = async (vendorId, payload) => {
    let data = null;
    await API.patch(`/update-vendor-user/${vendorId}`, payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}