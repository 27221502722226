import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/slices/authSlice";
import { NavLink, useNavigate} from "react-router-dom";
import {useState, useEffect, useRef} from "react";


const HeaderComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = (event) => {
    event.preventDefault();

    dispatch(logout())
    navigate('/login');
  };
  const user = useSelector(state => state.auth.user)
  const [dropdown, setDropdown] = useState(false)

  const dropdownRef = useRef(null)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdown]);

  const toggleDropdown = (event) => {
    setDropdown(!dropdown);
    event.stopPropagation();
  };

  return (
    <header className="header_area header_type_2 fixed-top">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="header_main_wrap">
              <div className="header_left">
                <div className="logo">
                  <div className="hamburger_menu">
                    <div className="toggle">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <NavLink to="/" className={'cursor_pointer'}><img src="/assets/media/images/logo-white.png" alt=""/></NavLink>
                </div>

              </div>
              <div className="header_right_menu">
                <ul>
                  <li className="vendor_logo"><a className={'cursor_pointer'}>{user ? user.name : 'Vendor'}</a></li>

                  <li className="logged_in_link"><a className={'cursor_pointer'}><img src="/assets/media/images/header_icon3.png" alt="" /></a>
                  </li>
                  <li ref={dropdownRef} className="logged_in_link"><a className={'cursor_pointer'} onClick={toggleDropdown}><img src="/assets/media/images/header_icon4.png" alt="" /></a>
                    <div className="dropdown">
                      <div className={`dropdown_menu ${dropdown ? 'd_block' : ''}`}>
                        <ul>
                          <li><NavLink end to="/change-password" className={'color_black'}>Change Password</NavLink></li>
                          <li>
                            <a  className={'color_black cursor_pointer'} onClick={logoutHandler}> Log out</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;