import Card from "../../components/UI/Card";
import {useForm} from "react-hook-form";
import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";
import {useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {updatePassword} from "../../api/auth";

const UpdatePasswordForm = () => {
  const [toastId, setToastId] = useState(null);
  const {isPending, mutate} = useMutation({
    mutationFn:  (data) => updatePassword(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Password Updated`, type: "success", isLoading: false, autoClose: true });
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const {setError, reset, handleSubmit, register, formState: { errors }} = useForm();
  const onSubmit = (data) => {
    mutate(data)
  }
  return (
      <Card title={"Update Password"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_12">
              <Input label={'Current Password'}
                     name={'current_password'}
                     errors={errors}
                     inline={true}
                     type={'password'}
                     validationRules={  {required: true } }
                     register={register}/>
            </div>
            <div className="col_12">
              <Input label={'New Password'}
                     name={'new_password'}
                     errors={errors}
                     inline={true}
                     type={'password'}
                     validationRules={  {required: true } }
                     register={register}/>
            </div>
            <div className="col_12">
              <Input label={'New Password Confirm'}
                     name={'new_password_confirm'}
                     errors={errors}
                     inline={true}
                     type={'password'}
                     validationRules={  {required: true } }
                     register={register}/>
            </div>
            <div className="col_12">
              <div className="d_flex_end">
                <Button
                  text="update"
                  isPending={isPending}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </form>
      </Card>
  );
}

export default UpdatePasswordForm