import {Link} from "react-router-dom";
import Button from "../../../../components/UI/Button";
const SubmitActionButtonGroup = ({showBackListButton = false, isloading})=> {
  return (
    <>
      <div className={`row ${showBackListButton && 'product_submit_btn_row'}`}>
        <div className={`${showBackListButton ? 'd_flex_btwn' : 'd_flex_end width_full pr_10'}`}>
          {showBackListButton &&
          <Link to="/products" className={'mr_10'}>
            <Button
              text="Back to List"
              variant={"btn_black"}
              type={"submit"}
            />
          </Link>
          }
          <Button
            text="Save"
            isPending={isloading}
            type={"submit"}
          />
        </div>
      </div>
    </>
  );
}

export default SubmitActionButtonGroup