import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const UploadedMediaItemComponent = ({isPending = false,
                                      currentDeleteItem = '',
                                      image,
                                      deleteProduct,
                                      indexKey}) => {
  const errorHandle = (e) => {
    e.target.src = "/images/no-image.png"
  }

  return (
    <>
      <div className="inner">
        <div className="inner_wrap">
          {isPending && currentDeleteItem === indexKey ?
            <div className="remove_list opacity_1_important">
              <button type="button" className="btn btn_black btn_xs">
                <span className="false"><FontAwesomeIcon icon={faSpinner} spin/></span>
              </button>
            </div> :
            <div className="remove_list">
              <button type="button" className="btn btn_black btn_xs"  onClick={() => {deleteProduct(image, indexKey)}}>
                <span className="false"><i className="lni lni-close"></i></span>
              </button>
            </div>
          }
          <img src={image.url} onError={errorHandle} className={'width_full'} alt=""/>
        </div>
      </div>
    </>

  )
}

export default UploadedMediaItemComponent;