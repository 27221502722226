import Select from "../../../../../components/UI/form/Select";
import RadioBox from "../../../../../components/UI/form/RadioBox";
import Input from "../../../../../components/UI/form/Input";
import {useMutation} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {getCategories, getVendorCategories} from "../../../../../api/categories";
import TextEditor from "../../../../../components/UI/form/TextEditor";
import {useSelector} from "react-redux";
import {getVendors} from "../../../../../api/vendor";

import {getErrorObj, getValidationMessage} from "../../../../../util/validationMessage";
import SelectInputField from "../../../../../components/UI/form/SelectInputField";

const InformationComponent = ({watch, setError, control, setValue, handleSubmit, errors, register, getValues}) => {
  const product = useSelector(state => state.product.currentEditProduct)

  const [categories, setCategories] = useState([])
  const [vendorCategories, setVendorCategories] = useState([])
  const [vendors, setVendors] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [subSubCategories, setSubSubCategories] = useState([])
  const [vendorSubCategories, setVendorSubCategories] = useState([])
  const [vendorSubSubCategories, setVendorSubSubCategories] = useState([])

  const {mutate:categoryMutate} = useMutation({
    mutationFn:()=> getCategories({status: 1}),
    onSuccess: (response, payload) => {
      setCategories(response)
    },
  })
  const { mutate: vendorsMutation} = useMutation({
    mutationFn: getVendors,
    onSuccess: (response, payload) => {
      setVendors(response.data)
    },
  })


  useEffect(()=>{
    if(!categories.length){
      categoryMutate()
    }
  }, [categories])

  useEffect(()=>{
    if(!vendors.length) {
      vendorsMutation()
    }

  }, [vendors])

  useEffect(()=>{
    if(categories) {
      let subcategories = categories.find(cat => +cat.id === +getValues('parent_category_id'))?.sub_categories
      setSubCategories(subcategories)
    }
  }, [watch('parent_category_id')])

  useEffect(()=>{
    if(subCategories && subCategories.length) {
      let subcategories = subCategories.find(cat => +cat.id === +getValues('second_category_id'))?.sub_categories
      setSubSubCategories(subcategories)
    }
  }, [watch('second_category_id')])

  useEffect(()=>{
    if(product && categories.length){
      setTimeout(()=>{
        setValue("parent_category_id", +product.parent_category_id)
      }, 100)
      setTimeout(()=>{
        setValue("second_category_id", product.second_category_id)
      }, 200)
      setTimeout(()=>{
        setValue("third_category_id", product.third_category_id)
      }, 300)
      setValue("is_physical", product.is_physical ? true : false)
      setValue("tracking_inventory", product.tracking_inventory ? true : false)
      setValue("sell_out_of_stock", product.sell_out_of_stock ? true : false)
    }

    if(product && vendorCategories.length){
      setTimeout(()=>{
        setValue("vendor_parent_category_id", +product.vendor_parent_category_id)
      }, 100)
      setTimeout(()=>{
        setValue("vendor_second_category_id", product.vendor_second_category_id)
      }, 200)
      setTimeout(()=>{
        setValue("vendor_third_category_id", product.vendor_third_category_id)
      }, 300)
    }

  }, [product, categories])

  useEffect(()=>{
    if(product && vendors.length){
      setValue("vendor_id", product.vendor_id)
    }
  }, [product, vendors])


  useEffect(() => {
    if (getValues('vendor_id')) {
      async function fetchData() {
        // You can await here
        const response = await getVendorCategories({vendor: getValues('vendor_id'), status:1});
        setVendorCategories(response)
      }
      fetchData();
    }
  }, [watch('vendor_id')]);


  useEffect(()=>{
    if(vendorCategories) {
      let subcategories = vendorCategories.find(cat => +cat.id === +getValues('vendor_parent_category_id'))?.sub_categories
      setVendorSubCategories(subcategories)
    }
  }, [watch('vendor_parent_category_id')])

  useEffect(()=>{
    if(vendorSubCategories && vendorSubCategories.length) {
      let subcategories = vendorSubCategories.find(cat => +cat.id === +getValues('vendor_second_category_id'))?.sub_categories
      setVendorSubSubCategories(subcategories)
    }
  }, [watch('second_category_id')])


  return(
    <>
      <div className={"row"}>
        <div className="col_6 pr_30">
          <div className="row">
            <div className="col_12">
              <RadioBox label="Status"
                        name="status"
                        inline={true}
                        errors={errors}
                        options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>
            <div className="col_12">
              <Select
                name={`vendor_id`}
                inline={true}
                options={vendors}
                label={"Vendor"}
                errors={errors}
                validationRules={{required: true}}
                register={register} />
            </div>
            <div className="col_12">
              <Input label="SKU"
                     name="sku"
                     inline={true}
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>

            <div className="col_12">
              <Input label="Sale.Price"
                     name="price"
                     inline={true}
                     errors={errors}
                     type={"double"}
                     validationRules={{required: true}}
                     register={register}/>
            </div>
            <div className="col_12">
              <Input label="Original.Price"
                     name="original_price"
                     inline={true}
                     errors={errors}
                     type={"double"}
                     validationRules={{required: false}}
                     register={register}/>
            </div>
            <div className="col_12">
              <Input label="Brand"
                     name="brand"
                     inline={true}
                     errors={errors}
                     validationRules={{type: 'string'}}
                     register={register}/>
            </div>
          </div>
        </div>

        {/*second column*/}

        <div className="col_6 pl_30">
          <div className="row">
            <div className="col_12">
              <Input label="Name"
                     name="name"
                     inline={true}
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>

            <div className="col_12">
              <div className={`form_row_inline ${getErrorObj(errors, 'parent_category_id') && 'has_error'}`}>
                <label className="required">
                  Category
                </label>
                <div className="form_input ">
                  <SelectInputField
                    name={`parent_category_id`}
                    options={categories}
                    errors={errors}
                    classes={'mr_5'}
                    validationRules={{required: true}}
                    register={register}
                  />

                  <SelectInputField
                    name={`second_category_id`}
                    options={subCategories}
                    errors={errors}
                    validationRules={{required: false}}
                    classes={'mr_5'}
                    register={register} />

                  <SelectInputField
                    name={`third_category_id`}
                    options={subSubCategories}
                    errors={errors}
                    classes={''}
                    validationRules={{required: false}}
                    register={register} />

                  {getErrorObj(errors, 'parent_category_id') && <small>{getValidationMessage(getErrorObj(errors, 'parent_category_id'), 'Home Shopping Category')}</small>}

                </div>
              </div>
            </div>
            <div className="col_12">
              <div className={`form_row_inline ${getErrorObj(errors, 'vendor_parent_category_id') && 'has_error'}`}>
                <label className="required">
                  VendorCategory
                </label>
                <div className="form_input ">
                  <SelectInputField
                    name={`vendor_parent_category_id`}
                    options={vendorCategories}
                    errors={errors}
                    validationRules={{required: true}}
                    register={register}
                    classes={'mr_5'}
                  />

                  <SelectInputField
                    name={`vendor_second_category_id`}
                    options={vendorSubCategories}
                    errors={errors}
                    validationRules={{required: false}}
                    register={register}
                    classes={'mr_5'}
                  />

                  <SelectInputField
                    name={`vendor_third_category_id`}
                    options={vendorSubSubCategories}
                    errors={errors}
                    validationRules={{required: false}}
                    register={register}
                    classes={''}
                  />

                  {getErrorObj(errors, 'vendor_parent_category_id') && <small>{getValidationMessage(getErrorObj(errors, 'vendor_parent_category_id'), 'Category')}</small>}

                </div>
              </div>
            </div>


            <div className="col_12">

            </div>

            <div className="col_12">
              <div className="row">
                <div className="col_6">
                  <Input label="Weight"
                         name="weight"
                         inline={true}
                         errors={errors}
                         validationRules={{type: 'double'}}
                         register={register}/>
                </div>
                <div className="col_6">
                  <Select
                    label={"Weight Unit"}
                    name={`weight_unit`}
                    inline={true}
                    options={[{id: "kg", name: "KG"}, {id:"lb", name: "LB"}, {id: "oz", name: "OZ"}, {id:"g", name: "G"}]}
                    errors={errors}
                    validationRules={{required: false}}
                    register={register} />
                </div>
              </div>
            </div>
            <div className="col_12">
              <div className="row">
                <div className="col_4">
                  <Input label="Box Length"
                         name="length"
                         inline={true}
                         errors={errors}
                         type={'number'}
                         placeholder={'in'}
                         validationRules={{type: 'double'}}
                         register={register}/>
                </div>
                <div className="col_4">
                  <Input label="Box Width"
                         name="width"
                         inline={true}
                         type={'number'}
                         errors={errors}
                         placeholder={'in'}
                         validationRules={{type: 'double'}}
                         register={register}/>
                </div>
                <div className="col_4">
                  <Input label="Box Height"
                         name="height"
                         type={'number'}
                         inline={true}
                         errors={errors}
                         placeholder={'in'}
                         validationRules={{type: 'double'}}
                         register={register}/>
                </div>
              </div>
            </div>


            <div className="col_12">
              <Input label="  Youtube Embed Video"
                     name="youtube_url"
                     inline={true}
                     errors={errors}
                     validationRules={{type: 'string'}}
                     register={register}/>
            </div>

            {/*<div className="col_6 ">*/}
            {/*  <Checkbox name={`is_physical`}*/}
            {/*inline={true}*/}
            {/*            label={"Is Physical"}*/}
            {/*            classes="mb_0 pt_15"*/}
            {/*            register={register}*/}
            {/*            checked={getValues('is_physical') ? 1 : 0} />*/}
            {/*</div>*/}
            {/*<div className="col_6 ">*/}
            {/*  <Checkbox name={`tracking_inventory`}*/}
            {/*inline={true}*/}
            {/*            label={"Tracking Inventory"}*/}
            {/*            classes="mb_0 pt_15"*/}
            {/*            register={register}*/}
            {/*            checked={getValues('tracking_inventory') ? 1 : 0} />*/}

            {/*</div>*/}

            {/*<div className="col_6 ">*/}
            {/*  <Checkbox name={`sell_out_of_stock`}*/}
            {/*inline={true}*/}
            {/*            label={"Sell out of stock"}*/}
            {/*            classes="mb_0 pt_15"*/}
            {/*            register={register}*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
        </div>
        <div className="col_12">
          <TextEditor
            label="Short Description"
            name="short_description"
            inline={true}
            placeholder={"Max. 500 letters allowed. The following special characters are not allowed: <, >, {, }, ^, [, ], =, @, ;"}
            errors={errors}
            validationRules={{type: 'string'}}
            data={ getValues('short_description')}
            onchangeHandler={(data) => setValue('short_description', data)}
            register={register}
          />
        </div>
        <div className="col_12 ">
          <TextEditor
            label="Description"
            name="description"
            inline={true}
            placeholder={"Max. 500 letters allowed. The following special characters are not allowed: <, >, {, }, ^, [, ], =, @, ;"}
            errors={errors}
            validationRules={{type: 'string'}}
            data={ getValues('description')}
            onchangeHandler={(data) => setValue('description', data)}
            register={register}
          />
        </div>

      </div>
    </>
  );
}

export default InformationComponent