import {Link} from "react-router-dom";
import Button from "../../../../components/UI/Button";
import React from "react";

const ProductListCardActions = () => {
  return(
    <>
      <h1 className="common_title ">

        <div className="d_flex_btwn">
          <span>Product List</span>
          <span>
            <Link to={'/products/add'}>
              <Button text={<span className="mr_5 d_flex_center"><i className="lni lni-plus mr_5"></i> Add Product</span>} />
            </Link>
         </span>
        </div>
      </h1>
    </>
  );
}

export default ProductListCardActions