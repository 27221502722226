import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";
import UploadAdapter from "./UploadAdapter";


export const TextEditor = ({onchangeHandler = null,inline=false, data= '', label, name, placeholder = 'Description', validationRules = {}, errors =null,  classes = ''})=>{

    const config = {
        language: "en",
        height: 300,
        extraPlugins: [uploadPlugin],
        ui: {
            viewportTopOffset: 60,
        }
    };

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return UploadAdapter(loader, true);
        };
    }

    return (
        <div className={`${ classes } ${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
            <label className={validationRules.required ? 'required' : ''}>{label}</label>
            <div className="form_input">
                <CKEditor
                    editor={ ClassicEditor }
                    config={config}
                    data={data}
                    onChange={ ( event, editor ) => {
                        onchangeHandler(editor.getData())
                    } }
                />
            </div>

            {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
        </div>

    );
}

export default TextEditor