import {Link} from "react-router-dom";
import Button from "../../../../components/UI/Button";
const SubmitActionButtonGroup = ({isloading})=> {
  return (
    <>
      <div className="row mt_10">
        <div className="col_12">
          <div className="d_flex_end">
            <a className={'btn btn_black btn_md mr_10'} href="/sample_product_excel.xlsx" download>download sample file</a>
            <Button
              text="Upload"
              isPending={isloading}
              type={"submit"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmitActionButtonGroup