import { useDispatch, useSelector } from 'react-redux';
import EmptyImage from "../../components/UI/EmptyImage";
import {selectUnselectProduct} from "../../store/slices/productSlice";
import {noImagePlaceholder} from "../../util/helper";

const SelectableProductSingleComponent = ({product, selectProduct, index, selectedProductList= [], selectedProducts})=> {
  const dispatch = useDispatch();

  const handleCheckboxChange = (event)=>{
    if (!selectedProducts) {
      dispatch(selectUnselectProduct(product.id));
    }
    selectProduct(product)
  }

  const store = useSelector(state => state.product);

  return (
    <>
      <div className="item_list_inner">
        <div className="item_list_inner_wrap">

          <div className="img">
            <div className="left_box">
              <div className="form_checkbox">
                {selectedProducts && selectedProducts.length > 0 ? (
                    <input type="checkbox" id={`item_list_${index}_${product.id}`} name="checkbox" checked={selectedProducts.find(item => item.id === product.id) ? true : false} onChange={(event)=>{handleCheckboxChange(event,'name')}}  />
                ) : (
                    <input type="checkbox" id={`item_list_${index}_${product.id}`} name="checkbox" checked={store.selectedProducts.find(item => item === product.id) ? true : false} onChange={(event)=>{handleCheckboxChange(event,'name')}}  />
                )}
                <label htmlFor={`item_list_${index}_${product.id}`}></label>
              </div>

            </div>

            {product.thumbnail ?
              <img src={product.thumbnail} alt="" onError={noImagePlaceholder} />
              :
              <EmptyImage />
            }
          </div>
          <div className="text">
            <p className="style_no mb_0">{ product.sku }</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectableProductSingleComponent;