import Input from "../../../components/UI/form/Input";
import Select from "../../../components/UI/form/Select";
import SelectableProductListComponent from "../../products/SelectableProductListComponent";
import HomeSectionSelectedProducts from "./HomeSectionSelectedProducts";
import {useEffect, useState} from "react";
import {setSelectToggle} from "../../../store/slices/productSlice";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const HomeSectionProductComponent = ({errors, register, getValues, setValue, watch, control, clearErrors}) => {
  const dispatch = useDispatch()
  const params = useParams()
  const {editSection} = useSelector(state => state.homePageSection)

  const [selectedProducts, setSelectedProducts] = useState([]);

  const productTypes = [
    {
      name: 'New Arrival',
      id: 'new_arrival'
    },
    {
      name: 'Best Selling',
      id: 'best_selling'
    },
    {
      name: 'Custom Select Product',
      id: 'custom_select_product'
    },
  ]

  const [product, setProduct] = useState(null);

  const selectUnselectProduct = (product) => {
    clearErrors('products')
    setProduct(product)

    const isExisting = selectedProducts.some(item => item.id === product.id);
    if (isExisting) {
      const updateProducts = selectedProducts.filter(item => item.id !== product.id)
      setSelectedProducts(updateProducts)
    } else {
      setSelectedProducts((prevState) => [...prevState, product])
    }
  }

  const removeSelectedProduct = (index, id) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts.splice(index, 1);
    setSelectedProducts(updatedProducts);
  };

  useEffect(()=>{
    setProduct(null)
  }, [watch(`products`)])

  useEffect(()=>{
    if (!params.id) {
      setValue('products', [])
      dispatch(setSelectToggle({type: 'unselect'}))
    } else {
      setSelectedProducts(editSection.products);
    }
  }, [watch(`product_type`)])

  return (
    <>
      <div className="row">
        <div className="col_4 pr_20">
          <Input label="Title"
                 name="title"
                 inline={true}
                 errors={errors}
                 validationRules={{required:true}}
                 register={register}/>
        </div>
        <div className="col_4 pr_20">
          <Input label="Description"
                 name="description"
                 inline={true}
                 errors={errors}
                 register={register}/>
        </div>
        <div className="col_4">
          <Select
            name={`product_type`}
            options={productTypes}
            inline={true}
            label={"Product Type"}
            errors={errors}
            validationRules={{required: true}}
            register={register} />
        </div>

      </div>
      {watch('product_type') === 'custom_select_product' &&
      <div className="row">
        <div className="col_12">
          <SelectableProductListComponent selectUnselectProduct={(product) => selectUnselectProduct(product)} selectedProducts={selectedProducts}/>
        </div>
        <div className="col_12">
          {errors.products && <div className="text_red">{errors.products.message}</div>}
          <HomeSectionSelectedProducts
              name={`products`}
              control={control}
              index={1}
              product={product}
              selectedProducts={selectedProducts}
              removeSelectedProduct={removeSelectedProduct}
          />
        </div>
      </div>
      }
    </>
  );
}

export default HomeSectionProductComponent