import {Link} from "react-router-dom";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import Card from "../../components/UI/Card";
import {getWithdrawRequestList} from "../../api/commissionTransaction";
import CommissionTransactionFilter from "../../views/commission-transactions/CommissionTransactionFilter";
import {useState} from "react";
import WithdrawTableAction from "../../views/withdraw-request/WithdrawTableAction";
import Button from "../../components/UI/Button";

const WithdrawRequestList = () => {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "created_at",
      header: "Created At",
      cell: props => <span> {props.row.original.created_at}</span>,
    }),
    columnHelper.display({
      id: "type",
      header: "Transaction Type",
      cell: props => <span className={`${props.row.original.type === 'commission' ? '' : 'text_red'} capitalized`} > {props.row.original.type}</span>,
    }),
    columnHelper.display({
      id: "vendor",
      header: "Vendor",
      cell: props => <Link className={"text_blue"} to={`/vendor-details/${props.row.original.vendor_id}`}>{props.row.original.vendor ? props.row.original.vendor : ''}</Link>,
    }),
    columnHelper.display({
      id: "vendor_balance",
      header: "Amount",
      cell: props => <span> ${props.row.original.vendor_balance.toFixed(2)}</span>,
    }),
    columnHelper.display({
      id: "status",
      header: "Status",
      cell: props => <Button text={props.row.original.status} size={'btn_xs has_border_redius'} classes={`has_border_redius ${props.row.original.status === 'APPROVED' ? '' : 'color_warning'}`} />,
    }),
    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <WithdrawTableAction id={props.row.original.id} row={props.row.original} />
    }),
  ];

  const [params, setParams] = useState({
    per_page: 20,
    page: 1,
    search: '',
    start_date: '',
    end_date: '',
    vendor_id: '',
  })

  return (
    <>
      <Card title={`${'Withdraw Requests'}`}>
        <CommissionTransactionFilter params={params} setParams={setParams} />
        <Table queryKey={`withdrawRequestList`}
               columns={columns}
               queryParams={params}
               queryFn={getWithdrawRequestList} />
      </Card>
    </>
  );
}

export default WithdrawRequestList