import Card from "../../components/UI/Card";
import React, {useState} from "react";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../components/UI/Table";
import {getCoupons} from "../../api/coupon";
import CouponListAction from "../../views/coupon/CouponListAction";
import CouponListCardAction from "../../views/coupon/CouponListCardAction";
import CouponTableFilterComponent from "../../views/coupon/CouponTableFilterComponent";
import CustomerFilterComponent from "../../views/customers/CustomerFilterComponent";

const CouponList = ()=>{
    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.accessor('name', {
            id: "name",
            header: "Name"
        }),
        columnHelper.accessor('type', {
            id: "type",
            header: "Type"
        }),


        columnHelper.display({
            id: "percentage_off",
            header: "Terms",
            cell: props => <span>{props.row.original.percentage_off ? `${props.row.original.percentage_off}% off` : `$${props.row.original.amount_off} off` }</span>,
        }),

        columnHelper.display({
            id: "vendor_id",
            header: "Creator",
            cell: props => <span className={props.row.original.vendor ? 'xxx' : 'yyy'}>{props.row.original.vendor ? props.row.original.vendor.name : 'Stylepick' }</span>,
        }),

        columnHelper.display({
            id: "times_redeemed",
            header: "Redemptions",
            cell: props => <span>{props.row.original.times_redeemed ? props.row.original.times_redeemed : 0} {props.row.original.max_redemptions ? `/${props.row.original.max_redemptions}` : ''}</span>,
        }),
        columnHelper.accessor('redeem_by', {
            id: "redeem_by",
            header: "Expires"
        }),

        columnHelper.display({
            id: "actions",
            header: "Actions",
            classes: 'text_right',
            cell: props => <CouponListAction row={props.row.original} />,
        }),
    ];

    const [params, setParams] = useState({
        per_page: 20,
        search: '',
        type: '',
        page:1
    })
    return (
        <Card title={"Coupons"} CardActions={<CouponListCardAction />}>
            <CouponTableFilterComponent searchHandler={setParams} params={params} />
            <Table queryKey={`coupons`}
                   columns={columns}
                   queryParams={{...params}}
                   queryFn={getCoupons} />
        </Card>
    )
}

export default CouponList