import {Link} from "react-router-dom";
import Button from "../../components/UI/Button";

const HomeSectionCardActions = () => {
  return (
    <Link to={'/home-page-sections/add'}>
      <Button
          text="+ Add New Section"
          variant={"btn_black"}
      />
    </Link>
  );
}

export default HomeSectionCardActions