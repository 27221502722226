import {useMutation, useQueryClient} from "@tanstack/react-query";
import {changeCustomerActiveStatus} from "../../api/customers";
import {toast} from "react-toastify";
import {useState} from "react";

const CustomerTableStatusAction = ({customer}) =>{

    const [toastId, setToastId] = useState(null);

    const queryClient = useQueryClient()

    const {mutate, isPending} = useMutation({
        mutationFn: changeCustomerActiveStatus,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Customer Status Update", type: "success", isLoading: false, autoClose: true  });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'customers',
            });
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })

    const handleStatusChange = (event) => {
        let selectedStatus = event.target.value;
        mutate({id: customer.id, status: selectedStatus})
    }
    return (
        <>
            <div className="form_input">
                <select className="form_global" value={customer.status} onChange={handleStatusChange}>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                </select>
            </div>
        </>
    );
}

export default CustomerTableStatusAction;