
import API from "../../../util/api";

export default function UploadAdapter(loader, image_optimize = false) {
  const URL = `/upload-image`;

  return {
    upload: () => {

      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          const formData = new FormData();
          formData.append('image', file);
          formData.append('is_optimize', image_optimize);
          let data = null;
          await API.post(URL,formData)
              .then(res => {
                data = res.data.location;
              })
              .catch((err) => {
                throw new Error(JSON.stringify(err.response.data))
              });

          resolve({
            default: data
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    abort: () => {}
  };
}