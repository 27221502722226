import EmptyImage from "../../../../../components/UI/EmptyImage";
import {Link} from "react-router-dom";
import {noImagePlaceholder} from "../../../../../util/helper";

const ProductComponent = ({selectedProducts = [], product, selectSingleProduct})=> {

  const handleCheckboxChange = ()=>{
    selectSingleProduct(product.id)
  }

  return (
    <>
      <div className="item_list_inner">
        <div className="item_list_inner_wrap">
          <div className="list_icon">
            {product.parent_category && <p className="date" title={product.parent_category.name}>{product.parent_category.name}</p> }
            <ul>
              <li><Link to={`/products/edit/${product.id}`}>
                <span><img src="/assets/media/images/edit.png" alt="" /></span>
              </Link>
              </li>
            </ul>
          </div>
          <div className="img">
            <div className="left_box">
              <div className="form_checkbox">
                <input type="checkbox" id={`item_list_${product.id}`} checked={selectedProducts.find(item => item === product.id) ? true : false} name="checkbox" onChange={(event)=>{handleCheckboxChange(event,'name')}}  />
                <label htmlFor={`item_list_${product.id}`}></label>
              </div>

            </div>
            <Link to={`/products/edit/${product.id}`}>
              {product.thumbnail ?
                <img src={product.thumbnail} alt="" onError={noImagePlaceholder} />
                :
                <EmptyImage />
              }
            </Link>
          </div>
          <div className="text">
            <p className="style_no mb_0">{ product.sku }</p>
            <p className="price p_relative d_flex_btwn">${ product.price.toFixed(2) } {product.original_price && (
                <del className="text_red">${ product.original_price?.toFixed(2) }</del>
            )}
            </p>
            <p className="date_normal color_grey">{ product.created_at }</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductComponent;