import Button from "../../components/UI/Button";
import React, {useEffect, useState} from "react";

export const CustomerFilterComponent = ({searchHandler, params})=>{
  const queryparams = {
    per_page: 20,
    page: 1,
    search: ''
  }

  const [filterParams, setFilterParams] = useState({...queryparams});

  const handleInputChange = (event) => {
    setFilterParams(old=> ({...old, search: event.target.value}))
  }
  const sendSearchRequest = ()=>{
    searchHandler({...filterParams, page:1});
  }

  const resetSearch = ()=>{
    searchHandler({...queryparams})
    setFilterParams({...queryparams})
  }

  const handlePerPage = (e) => {
    searchHandler({...filterParams,  per_page: e.target.value, page:1});
  }

  const enterpress = (e) => {
    if(e.key === 'Enter'){
      sendSearchRequest()
    }
  }

  return (
    <>
      <div className="order_filter_content">
        <div className="d_flex_start mb_20">
          <select className="form_global width_260p mr_10" value={params.per_page} onChange={(event)=>{handlePerPage(event)}}>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="86">86</option>
            <option value="100">100</option>
          </select>
          <div className="form_row mb_0 width_full">
            <div className="form_input">
              <div className="search_input">
                <input type="text" className="form_global"
                       onKeyPress={(e)=>enterpress(e)}
                       placeholder={"search by name, email or phone number"}
                       value={filterParams? filterParams.search : ''}
                       onChange={(event)=>handleInputChange(event)}
                />
              </div>
              <Button text={"Search"}
                      classes={'mr_5 ml_5'}
                      onClickHandler={sendSearchRequest} />

              <Button text={"Reset"}
                      onClickHandler={resetSearch} />
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default CustomerFilterComponent