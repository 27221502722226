import Button from "../../../../components/UI/Button";

const VideoLinksActions = ({openModal})=> {
    return (
        <Button
            text="+ Add a New Video"
            onClickHandler={()=> openModal(true)}
            variant={"btn_black"}
        />
    );
}

export default VideoLinksActions