import API from "../util/api";

export const getHomePageSections = async (params) => {
  let data = null;

  await API.get('/home-page-sections', {params})
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const getSingleHomePageSections = async (params) => {
  let data = null;

  await API.get(`/home-page-sections/${params}`)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}


export const storeHomePageSections = async (payload) => {
  let data = null;

  await API.post('/home-page-sections', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateHomePageSections = async (id, payload) => {
  let data = null;
  payload.append('_method','PATCH')
  await API.post(`/home-page-sections/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const deleteHomePageSections = async (payload) => {
  await API.delete('/home-page-sections/'+payload.id)
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return true;
}

export const sortHomeSection = async (payload) => {
  let data = null;
  await API.post('/home-page-sections/sort', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}