import {createColumnHelper} from "@tanstack/react-table";
import React, {useState} from "react";
import Table from "../../components/UI/Table";
import Card from "../../components/UI/Card";
import {getIncompleteCheckout} from "../../api/incompleteCheckout";
import {IncompleteTableActions} from "../../views/incomplete-checkout/IncompleteTableActions";

const IncompleteIndex = () => {
    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.accessor('updated_at', {
            id: "updated_at",
            header: "Last update"
        }),

        columnHelper.display({
            id: "customer_name",
            header: "Customer Name",
            cell: props => <span className={`${props.row.original.type === 'customer' ? 'color_success' : 'color_warning'}`}>{props.row.original.customer_name}</span>,
        }),

        columnHelper.accessor('quantity', {
            id: "quantity",
            header: "Quantity"
        }),

        columnHelper.display({
            id: "total",
            header: "Total",
            classes: 'text_right',
            cell: props => <span>${props.row.original.total.toFixed(2)}</span>,
        }),

        columnHelper.display({
            id: "actions",
            header: "Actions",
            classes: 'text_right',
            cell: props => <IncompleteTableActions row={props.row.original} />,
        }),
    ];

    const [params, setParams] = useState({
        per_page: 20,
        search: '',
        page:1
    })

  return (
      <Card title={"Incomplete Checkout"} >
          <Table queryKey={`incomplete-checkout`}
                 columns={columns}
                 queryParams={{...params}}
                 queryFn={getIncompleteCheckout} />
      </Card>
  );
}

export default IncompleteIndex