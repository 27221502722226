import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../../util/validationMessage";
import Modal from "../../../../components/UI/Modal";
import Select from "../../../../components/UI/form/Select";
import RadioBox from "../../../../components/UI/form/RadioBox";
import Input from "../../../../components/UI/form/Input";
import Button from "../../../../components/UI/Button";
import {getCategories, removeCategoryBanner, storeCategory, updateCategory} from "../../../../api/categories";
import TextArea from "../../../../components/UI/form/TextArea";
import {confirmAlert} from "react-confirm-alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const CategoryForm = ({category = null, openModal,  setOpenModal, removeBanner, removePending, removeBannerColumn}) =>{
  const {getValues, setValue, watch, reset, register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      status: "1",
      sort: "1",
    },
  });

  useEffect(() => {
    setValue('second_parent_id', '');
    if(categories && categories.length) {
      const category = categories.find(item => item.id === +getValues('parent_id'))

      if (category) {
        setSubCategories(category.sub_categories || []);
      }
    }
  }, [watch('parent_id')]);

  const {data: categories} = useQuery({
    queryKey:['categories'],
    queryFn: () => getCategories(),
    initialData: () => []
  })

  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    reset();

    if (category) {
      Object.keys(category).forEach(key => {
        if (key === 'status' ) {
          setValue(key, category[key] ? "1" : "0");
        } else {
          setValue(key, category[key]);
        }
      });

      if (category.parent_id) {
        const foundObject = findObjectByIdInMultiArray(categories, category.parent_id);
        if(foundObject && foundObject.parent_id){
          setValue('parent_id', foundObject.parent_id);
          setTimeout(() => {
            setValue('second_parent_id', foundObject.id);
          }, 50);

        } else {
          setValue('parent_id', foundObject.id);
        }
      }
    }
  }, [openModal, category]);

  function findObjectByIdInMultiArray(array, id) {
    let result = null;
    if(array) {
      result = array.find(item => item.id === id)

      if (!result) {
        for (let element of array) {
          if (element.sub_categories && element.sub_categories.length) {
            result = element.sub_categories.find(item => item.id === id)
            if (result) {
              return result;
            }
          }
        }
      }
    }
    return result;

  }

  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: category ? (data) => updateCategory(category.id, data) : storeCategory,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, {
        render: `Category ${category ? 'Updated' : 'Added'}`,
        type: "success",
        isLoading: false,
        autoClose: true
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'categories',
      });
      setOpenModal(false)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, {
        render: errorMsg,
        type: "error",
        isLoading: false,
        autoClose: true
      });
    }
  });


  const onSubmit = (data) => {
    clearErrors();
    const file = data.file[0];
    const iconImage = data.icon_image[0];
    const featureImage = data.feature_image_file[0];
    const smallBanner = data.small_banner_file[0];
    let formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key] ? data[key] : '');
    })

    if(file)
      formData.append('file', file)

    if(iconImage)
      formData.append('icon_image', iconImage)

    if(featureImage)
      formData.append('feature_image_file', featureImage)

    if(smallBanner)
      formData.append('small_banner_file', smallBanner)

    mutate(formData)
  }

  return(
    <>
      <Modal title={`${category ? 'Edit' : 'Add'} Category`} open={openModal} setOpen={setOpenModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_6 pr_20">
              <Input label="Name"
                     name="name"
                     errors={errors}
                     validationRules={{required: true, type: 'string'}}
                     register={register}/>
            </div>
            <div className="col_6 ">
              <RadioBox label="Status"
                        name="status"
                        errors={errors}
                        optionValue={'id'}
                        optionLabel={'name'}
                        options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                        validationRules={{required:true, type: 'string'}}
                        register={register}/>
            </div>
            <div className="col_6 pr_20">
              <Select
                label="Parent Category"
                name="parent_id"
                options={categories || []}
                errors={errors}
                validationRules={{required: false}}
                register={register}/>
            </div>

            <div className="col_6">
              <Select
                label="Second Parent Category"
                name="second_parent_id"
                options={subCategories}
                errors={errors}
                validationRules={{required: false}}
                register={register}/>
            </div>

            <div className="col_6 pr_20">
              <Input label="Icon"
                     name="icon_image"
                     message={'Recommended ratio e.g: (160x160)'}
                     type={"file"}
                     errors={errors}
                     register={register}/>
              {(category && category.icon) &&
              <div className="col_12 pb_5">
                <div className="item_img_upload_wrap">
                  <div className="image_show_wrap">
                    <div className="width_full d_flex_inline">
                      <div className="inner p_0" style={{'width':'max-content'}}>
                        <div className="inner_wrap">
                          {removePending && removeBannerColumn === 'icon' ?
                            <div className="remove_list opacity_1_important">
                              <button type="button" className="btn btn_black btn_xs">
                                <span className="false"><FontAwesomeIcon icon={faSpinner} spin/></span>
                              </button>
                            </div> :
                            <div className="remove_list">
                              <button type="button" className="btn btn_black btn_xs"  onClick={()=>removeBanner('icon')}>
                                <span className="false"><i className="lni lni-close"></i></span>
                              </button>
                            </div>
                          }
                          <img id={'icon'} src={category.icon} height={"60"}  alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>


            <div className="col_6">
              <Input label="Banner"
                     name="file"
                     type={"file"}
                     message={'Recommended ratio (24:5) e.g: (1920X400)'}
                     errors={errors}
                     register={register}/>

              {(category && category.banner) &&
              <div className="col_12 pb_5">
                <div className="item_img_upload_wrap">
                  <div className="image_show_wrap">
                    <div className="width_full d_flex_inline">
                      <div className="inner p_0" style={{'width':'max-content'}}>
                        <div className="inner_wrap">
                          {removePending && removeBannerColumn === 'banner' ?
                            <div className="remove_list opacity_1_important">
                              <button type="button" className="btn btn_black btn_xs">
                                <span className="false"><FontAwesomeIcon icon={faSpinner} spin/></span>
                              </button>
                            </div> :
                            <div className="remove_list">
                              <button type="button" className="btn btn_black btn_xs"  onClick={()=>removeBanner('banner')}>
                                <span className="false"><i className="lni lni-close"></i></span>
                              </button>
                            </div>
                          }
                          <img id={'banner'} src={category.banner} height={"60"}  alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>


            <div className="col_6">
              <Input label="Feature Image"
                     name="feature_image_file"
                     message={'Recommended ratio e.g: (500X413)'}
                     type={"file"}
                     errors={errors}
                     register={register}/>
              {(category && category.feature_image) &&
              <div className="col_12 pb_5">
                <div className="item_img_upload_wrap">
                  <div className="image_show_wrap">
                    <div className="width_full d_flex_inline">
                      <div className="inner p_0" style={{'width':'max-content'}}>
                        <div className="inner_wrap">
                          {removePending && removeBannerColumn === 'feature_image' ?
                            <div className="remove_list opacity_1_important">
                              <button type="button" className="btn btn_black btn_xs">
                                <span className="false"><FontAwesomeIcon icon={faSpinner} spin/></span>
                              </button>
                            </div> :
                            <div className="remove_list">
                              <button type="button" className="btn btn_black btn_xs"  onClick={()=>removeBanner('feature_image')}>
                                <span className="false"><i className="lni lni-close"></i></span>
                              </button>
                            </div>
                          }
                          <img id={'feature_image'} src={category.feature_image} height={"60"}  alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>


            <div className="col_6">
              <Input label="Small Banner"
                     name="small_banner_file"
                     type={"file"}
                     errors={errors}
                     message={'Recommended ratio e.g: (428X150)'}
                     register={register}/>
              {(category && category.small_banner) &&
              <div className="col_12 pb_5" >
                <div className="item_img_upload_wrap">
                  <div className="image_show_wrap">
                    <div className="width_full d_flex_inline">
                      <div className="inner p_0" style={{'width':'max-content'}}>
                        <div className="inner_wrap">
                          {removePending && removeBannerColumn === 'small_banner' ?
                            <div className="remove_list opacity_1_important">
                              <button type="button" className="btn btn_black btn_xs">
                                <span className="false"><FontAwesomeIcon icon={faSpinner} spin/></span>
                              </button>
                            </div> :
                            <div className="remove_list">
                              <button type="button" className="btn btn_black btn_xs"  onClick={()=>removeBanner('small_banner')}>
                                <span className="false"><i className="lni lni-close"></i></span>
                              </button>
                            </div>
                          }
                          <img id={'small_banner'} src={category.small_banner} height={"60"}  alt=""/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>


            <div className="col_12">
              <TextArea label="Description"
                        name="description"
                        errors={errors}
                        validationRules={{required: false, type: 'string'}}
                        register={register}/>
            </div>

            <div className="col_12">
              <Input label="Meta Title"
                     name="meta_title"
                     errors={errors}
                     validationRules={{required: false, type: 'string'}}
                     register={register}/>
            </div>

            <div className="col_12 ">
              <TextArea label="Meta Description"
                        name="meta_description"
                        errors={errors}
                        validationRules={{required: false, type: 'string'}}
                        register={register}/>
            </div>

          </div>
          <div className="d_flex_end">
            <Button
              text="save"
              isPending={isPending}
              type={"submit"}
            />
          </div>
        </form>
      </Modal>
    </>
  );
}

export default CategoryForm;