const Card = ({title = null, CardActions = null, cardClass='', cardBodyCls='', children}) => {
  return (
    <>
      {title &&
      <h1 className="common_title">
        <div className={`${CardActions && "d_flex_btwn m_0 p_0" }`}>
          <span>{ title }</span>
          <span>{CardActions}</span>
        </div>
      </h1> }

      <div className={`card ${cardClass}`}>
        <div className={`card_body  ${cardBodyCls}`}>
          <div className="row">
            <div className="col_12">
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;