import Button from "../../../../components/UI/Button";
import {Link} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {deleteProductReviews} from "../../../../api/products";

const ReviewTableActions = ({row}) => {

  const [toastId, setToastId] = useState(null);

  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: deleteProductReviews,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Review Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'reviews',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const deleteHandler = () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: row.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }
  return (
    <>
      <Link to={`/products/settings/reviews/${row.id}`}><Button
        text="Details"
        classes={'has_border_radius'}
        variant={"btn_black"}
      />
      </Link>

      <Button
        text="Delete"
        classes={'ml_5'}
        variant={"btn_danger has_border_radius"}
        onClickHandler={deleteHandler}
      />
    </>
  );
}

export default ReviewTableActions