import Select from 'react-select';
import {useEffect, useState} from "react";
import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";
const MultiSelect = ({ label,
                       optionValue = 'id',
                       optionLabel = 'name',
                       options = [],
                       name,
                       inline = false,
                       setValue,
                       defaultValues = [],
                       validationRules = {},
                       errors =null})=> {

  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(()=> {
    if (selectedOption) {
      let ids = []
      Object.keys(selectedOption).forEach(key => {
        ids.push(selectedOption[key][optionValue])
      });
      setValue(name, ids);
    }
  }, [selectedOption])

  return (
    <div className={`form_row ${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
      {label && <label className={validationRules.required ? 'required' : ''}>{label}</label>}
      <div className="form_input">
        <Select className ={"form_global multi_select"}
                isMulti={true}
                isClearable
                defaultValue={defaultValues}
                onChange={setSelectedOption}
                getOptionValue={(option) => `${option[optionValue]}`}
                getOptionLabel={(option) => `${option[optionLabel]}`}
                options={options} />
      </div>

      {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
    </div>
  );
}

export default MultiSelect