import {getOrderGroup, getOrderTypes} from "../../api/orders";
import {useLoaderData} from "react-router";
import Card from "../../components/UI/Card";
import {useEffect, useState} from "react";
import SubHeadingComponent from "../../components/UI/SubHeadingComponent";
import {tr} from "faker/lib/locales";
import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";

export async function loadGroupDetails({params}){
    const [group, orderTypes] = await Promise.all([
        getOrderGroup(params.id),
        getOrderTypes()
    ]);
    return {group, orderTypes}
}

const OrderGroup = () => {
    const {group, orderTypes} = useLoaderData()
    const [orders, setOrders] = useState([])
    const [groupData, setGroupData] = useState(null)

    const getOrderStatus = (type) => {
        let row = '';
        if(orderTypes){
            Object.keys(orderTypes).forEach(key => {
                if(orderTypes[key].id === type){
                    row = orderTypes[key].type
                }
            });
        }
        return row;
    }

    useEffect(()=>{
        if(group){
            setOrders(group.data?.orders)
            setGroupData(group.data)
        }
    }, [group])

    return (
        <>
            <h1 className="common_title">Group Information</h1>
            {groupData &&
                <div className="row">
                    <div className="col_4">
                        <Card cardBodyCls={'pt_5'}>
                            <SubHeadingComponent heading={'Payment information'} classes={'m_0 p_0'}/>
                            <div className="">
                                <table className={'table'}>
                                    <tbody>
                                    <tr>
                                        <td> Payment Status</td>
                                        <td className={`text_right ${groupData.payment_status === 'success' ? 'color_success' : 'color_danger'}`}>{groupData.payment_status}</td>
                                    </tr>
                                    <tr>
                                        <td> Payment Method</td>
                                        <td className={"text_right"}>{groupData.payment_method === 'stripe' ? "Card" : "Cash On Delivery"}</td>
                                    </tr>
                                    {groupData.card_brand ?
                                        <>
                                            <tr>
                                                <td>Card Type</td>
                                                <td className={"text_right"}>{groupData.card_brand}</td>
                                            </tr>
                                            <tr>
                                                <td>Card Number</td>
                                                <td className={"text_right"}>**** **** {groupData.card_number}</td>
                                            </tr>
                                        </> : ''
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>

                    {orders.length && orders[0].customer ?
                        <div className="col_4">
                            <Card cardBodyCls={'pt_5'}>
                                <SubHeadingComponent heading={'Customer'} classes={'m_0 p_0'}/>
                                <div className="">
                                    <table className={'table'}>
                                        <tbody>
                                        <tr>
                                            <td>Name</td>
                                            <td className={`text_right`}>{orders[0].customer.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td className={`text_right`}>{orders[0].customer.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone</td>
                                            <td className={`text_right`}>{orders[0].customer.phone}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </Card>
                        </div> : ''
                    }

                    <div className="col_4">
                        <Card cardBodyCls={'pt_5'}>
                            <SubHeadingComponent heading={'Summary'} classes={'m_0 p_0'}/>
                            <div className="">
                                <table className={'table'}>
                                    <tbody>
                                    <tr>
                                        <td>SubTotal</td>
                                        <td className={`text_right`}>${groupData.sub_total.toFixed(2)}</td>
                                    </tr>
                                    {groupData.discount &&
                                        <tr>
                                            <td>Discount ({groupData.coupon_code})</td>
                                            <td className={`text_right color_danger`}>-${groupData.discount.toFixed(2)}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>Shipping</td>
                                        <td className={"text_right"}>{groupData.free_shipping ? <span className={"color_danger"}>Free</span> : ''} ${orders.length ? orders[0].shipping_cost.toFixed(2) : '0'}</td>
                                    </tr>

                                    <tr>
                                        <td><strong>Total</strong></td>
                                        <td className={"text_right"}><strong>${groupData.total.toFixed(2)}</strong></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </div>


                </div>
            }
            {orders.length > 0 &&
                <Card title={"Orders"}>
                    <div className="table_responsive">
                        <table className="table">
                            <thead>
                            <tr>
                                <th className={'fw_600'}>SL. No</th>
                                <th className={'fw_600'}>Date</th>
                                <th className={'fw_600'}>Order Number</th>
                                <th className={'fw_600'}>Vendor Name</th>
                                <th className={'fw_600'}>Payment Method</th>
                                <th className={'fw_600'}>Commission Rate</th>
                                <th className={'fw_600'}>Commission Amount</th>
                                <th className={'fw_600'}>Status</th>
                                <th className={'fw_600'}>Total</th>
                                <th className={'fw_600 text_right'}>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.map((order, index) => (
                                <tr key={`tr_${index}`}>
                                    <td>{index + 1}</td>
                                    <td>{order.created_at}</td>
                                    <td>{order.order_number}</td>
                                    <td>{order.vendor?.name}</td>
                                    <td>{order.payment_method}</td>
                                    <td>{order.vendor_commission_rate}</td>
                                    <td>${order.vendor_commission_amount.toFixed(2)}</td>
                                    <td> {getOrderStatus(order.status)}</td>
                                    <td>${order.total.toFixed(2)}</td>
                                    <td className={'text_right'}>
                                        <Link  to={"/orders/"+order.id}>
                                            <Button text={"view orders"}
                                                    variant={"btn_black"} size={'btn_sm'} classes={'has_border_radius'} />
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                </Card>
            }
        </>
    );
}

export default OrderGroup;