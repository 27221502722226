import Card from "../../../components/UI/Card";

const OrderVendorCommission = ({order}) => {
  return (
    <>
      {(order && order.vendor_commission_rate) ? <Card>
      <h1 className="common_sub_title d_flex_btwn mt_0 mb_5">Commission</h1>
        <div className="order_summery_table">
          <table className="table border_none">
            <tbody>
            <tr>
              <td className="text_left width_100p fw_600">Commission Rate</td>
              <td>{order.vendor_commission_rate}% </td>
            </tr>
            <tr>
              <td className="text_left fw_600">Commission Amount</td>
              <td>${order.vendor_commission_amount ? order.vendor_commission_amount.toFixed(2) : '0.00'} </td>
            </tr>
            </tbody>
          </table>
        </div>

    </Card> : ''}
    </>
  );
}

export default OrderVendorCommission