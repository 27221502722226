const SelectInputField = ({ label = "", optionValue = 'id', optionLabel = 'name', options = [], inline = false, name, register, validationRules = {}, errors =null, classes=''}) => {
return (
  <select className={`form_global ${classes}`} {...register(name, validationRules)}>
    <option value="">Select Item</option>
    {options instanceof Array &&
    <>
      {options.map(option =>
        <option key={`option_item_${label}_${option.id}`}
                value={option[optionValue]}>
          {option[optionLabel]}
        </option>)
      }
    </>
    }
  </select>
);
}

export default SelectInputField