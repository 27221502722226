import Input from "../../../components/UI/form/Input";
import {useSelector} from "react-redux";

const HomeSectionBannerForm = ({errors, register, getValues, setValue, watch}) => {
  const {editSection} = useSelector(state => state.homePageSection)
  return(
    <>
      <div className="row">
        <div className="col_6">
          <Input label="Banner"
                 name="banner_1"
                 errors={errors}
                 inline={true}
                 message={'Recommended e.g: (1920 X 786)'}
                 type={"file"}
                 validationRules={ {required: false }  }
                 register={register}/>

          {editSection && editSection.banner_1 && <img src={editSection.banner_1} className={'width_100p'} alt=""/>}
        </div>

        <div className="col_6">
          <Input label={watch('type') === 'banner_type_1' ? 'Small banner' : 'Mobile banner'}
                 name="banner_2"
                 errors={errors}
                 inline={true}
                 message={watch('type') === 'banner_type_1' ? 'Recommended e.g: (1216 X 1133)' : 'Recommended e.g: (965 X 450)'}
                 type={"file"}
                 validationRules={{required: false}}
                 register={register}/>
          {editSection && editSection.banner_2 && <img src={editSection.banner_2} className={'width_100p'} alt=""/>}
        </div>

      </div>
    </>
  );
}

export default HomeSectionBannerForm