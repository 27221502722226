import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import Button from "../../../../../components/UI/Button";
import {getCategories} from "../../../../../api/categories";

const ProductFilterComponent = ({initialParams, setInitialParams}) => {

  const [params, setParams] = useState({...initialParams});

  const [subCategories, setSubCategories] = useState([])
  const [subSubCategories, setSubSubCategories] = useState([])

  const {data: categories} = useQuery({
    queryKey:['categories'],
    queryFn: () => getCategories(),
    initialData: () => []
  })

  useEffect(()=>{
    if(categories && categories.length) {
      let subcategories = categories.find(cat => +cat.id === + params.parent_category_id)?.sub_categories
      setSubCategories(subcategories)
    }
  }, [params.parent_category_id])

  useEffect(()=>{
    if(subCategories) {
      let subcategories = subCategories.find(cat => +cat.id === + params.second_category_id)?.sub_categories
      setSubSubCategories(subcategories)
    }
  }, [params.second_category_id])

  const HandleCheckboxChange = (event,column) => {
    setParams((prevParams) => ({
      ...prevParams,
      [column]: event.target.checked == true? 1 : 0
    }));
  }

  const handleInputChange = (event, column, index = 0) => {
    setParams((prevParams) => ({
      ...prevParams,
      [column]: event.target.value,
      page:1,
    }));

    if(column === 'parent_category_id' || column === 'second_category_id' || column === 'third_category_id' || column === 'vendor_id' || column === 'sort' || column === 'per_page'){
      setInitialParams({
        ...params,
        [column]: event.target.value,
        page:1,
      })
    }
  }

  const enterPressHandler = (e) => {
    if(e.key === 'Enter'){
      setInitialParams(params)
    }
  }

  const sendSearchRequest = ()=>{
    setInitialParams(params)
  }
  const resetSearch = ()=>{
    setParams({...initialParams, search: '', name:1, description: 0, style_no: 1})
    setInitialParams({...initialParams, search: '', name:1, description: 0, style_no: 1})
  }

  return (
    <>
      <div className="card color_filter_content p_15">
        <div className="d_flex_start mb_15">
          <span className="mr_10">Search By</span>
          <div className="form_checkbox mr_10">
            <input type="checkbox" checked={params.style_no ? true : false} onClick={(event)=>HandleCheckboxChange(event, 'style_no')} id="i2"/>
            <label htmlFor="i2" >Vendor Style No.</label>
          </div>
          <div className="form_checkbox mr_10">
            <input type="checkbox" checked={params.description ? true : false} onClick={(event)=>HandleCheckboxChange(event, 'description')} id="i3"/>
            <label htmlFor="i3">Full Description</label>
          </div>
          <div className="form_checkbox mr_10">
            <input type="checkbox" checked={params.name ? true : false} onClick={(event)=>HandleCheckboxChange(event, 'name')} id="i4"/>
            <label htmlFor="i4">Item Name</label>
          </div>
        </div>

        <div className="form_row">
          <div className="form_input">
            <div className="search_input">
              <input type="text" className="form_global"
                     value={params.search}
                     onKeyPress={enterPressHandler}
                     placeholder={"Search by name, description and SKU"}
                     onChange={(event)=>handleInputChange(event, 'search')}
              />
            </div>
            <Button text={"Search"}
                    variant={"btn_black"}
                    classes={'mr_5 ml_5'}
                    onClickHandler={sendSearchRequest} />

            <Button text={"Reset"}
                    variant={"btn_black"}
                    onClickHandler={resetSearch} />
          </div>
        </div>
        <div className="form_row">
          <div className="form_input">
            <select className="form_global max_width_200p" value={params.sort} onChange={(event)=>{handleInputChange(event,'sort')}}>
              <option value="created_at-desc">Latest First</option>
              <option value="created_at-asc">Oldest First</option>
              <option value="sort-desc">Sort Number</option>
              <option value="updated_at-desc">Last Update</option>
              <option value="price-asc">Price Low to High</option>
              <option value="price-desc">Price High to Low</option>
              <option value="sku-asc">SKU</option>
            </select>


            <select className="form_global max_width_200p ml_5" value={params.parent_category_id} onChange={(event)=>{handleInputChange(event,'parent_category_id', 0)}}>
              <option value="">All Category</option>
              {(categories && categories.length) && categories.map((cat)=>{
                return (<option key={cat.id} value={cat.id}>{cat.name}</option> )
              })}
            </select>

            <select className="form_global max_width_200p ml_5" value={params.second_category_id } onChange={(event)=>{handleInputChange(event,'second_category_id', 0)}}>
              <option value="">All Category</option>
              {(subCategories && subCategories.length) && subCategories.map((cat)=>{
                return (<option key={cat.id} value={cat.id}>{cat.name}</option> )
              })}
            </select>


            <select className="form_global max_width_200p ml_5" value={params.third_category_id} onChange={(event)=>{handleInputChange(event,'third_category_id', 0)}}>
              <option value="">All Category</option>
              {(subSubCategories && subSubCategories.length) && subSubCategories.map((cat)=>{
                return (<option key={cat.id} value={cat.id}>{cat.name}</option> )
              })}
            </select>

            <div className="d_flex_end">
              <select className="form_global width_200p ml_5" value={params.per_page} onChange={(event)=>{handleInputChange(event,'per_page')}}>
                <option value="36">36</option>
                <option value="50">50</option>
                <option value="86">86</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductFilterComponent;