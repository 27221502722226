import {useState} from "react";
import Card from "../../../components/UI/Card";
import {createColumnHelper} from "@tanstack/react-table";
import Table from "../../../components/UI/Table";
import CarrierTableActions from "../../../views/settings/carriers/CarrierTableActions";
import CarrierFormComponent from "../../../views/settings/carriers/CarrierFormComponent";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {getCarriers, syncCarriers} from "../../../api/couriers";
import CarrierCardActions from "../../../views/settings/carriers/CarrierCardActions";

const CarriersPageComponent = () => {
  const [openModal, setOpenModal] = useState(false)
  const [courier, setCourier] = useState(null)
  const queryClient = useQueryClient()
  const [toastId, setToastId] = useState(null);

  const editHandler = (row) =>{
    setCourier(row)
    setOpenModal(true)
  }

  const addNewCourier = () =>{
    setCourier(null);
    setOpenModal(true);
  }


  const {mutate, isPending} = useMutation({
    mutationFn: syncCarriers,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'get-carrier',
      });
      toast.update(toastId, { render: "Carriers has been sync!", type: "success", isLoading: false, autoClose: true  });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const syncAccount = () => {
    confirmAlert({
      title: 'Sync',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate()
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Name'
    }),

    columnHelper.accessor('code', {
      id: 'code',
      header: 'Code'
    }),
    columnHelper.accessor('shippingProviderId', {
      id: 'shippingProviderId',
      header: 'Shipping Provider Id'
    }),
    columnHelper.display( {
      id: 'actions',
      header: "Actions",
      classes:'text_right',
      cell: props => <CarrierTableActions editRecord={editHandler} row={props.row.original} />
    }),
  ];

  return (
    <>
      <Card title={"Carriers"}  CardActions={<CarrierCardActions clickHandler={syncAccount} newCarrier={addNewCourier}/>}>
        <Table
          queryKey={"get-carrier"}
          columns={columns}
          queryParams={{per_page: 10}}
          queryFn={getCarriers}
        />
      </Card>

      <CarrierFormComponent editRecord={courier}
                 openModal={openModal}
                 setOpenModal={setOpenModal} />
    </>
  )
}

export default CarriersPageComponent;