import Input from "../../../../../components/UI/form/Input";
import Checkbox from "../../../../../components/UI/form/Checkbox";

export const InventoryItem = ({control,index, errors = [], register, watch, setValue, getValues, addNewVariant, removeRow})=>{

  return (
    <>
      <tr>
        <td>
          {getValues(`inventories[${index}].variant_combination`) && getValues(`inventories[${index}].variant_combination`).map((field, i) => (
            <span key={i}> {field.value}{(getValues(`inventories[${index}].variant_combination`).length - 1) > i ? "/" : ''} </span>
          ))}
        </td>
        <td className="text_center">
          <Input
            name={`inventories[${index}].sku`}
            placeholder={`Original SKU - ${getValues('sku')}`}
            errors={errors}
            classes={'width_60p'}
            register={register}/>
        </td>
        <td className="text_right">
          <Input
            name={`inventories[${index}].price`}
            errors={errors}
            classes={'width_60p'}
            placeholder={`Original price - ${getValues('price')}`}
            register={register}/>
        </td>
        <td className="text_right">
          <Input
            name={`inventories[${index}].available_quantity`}
            errors={errors}
            classes={'width_60p'}
            register={register}/>
        </td>
        <td className="text_right">
          <Checkbox name={`inventories[${index}].sell_out_of_stock`}
                    label={"Out of stock"}
                    classes="mb_0 pt_10"
                    register={register}
                    checked={getValues(`inventories[${index}].sell_out_of_stock`) ? 1 : 0} />
        </td>
      </tr>
    </>
  );
}

export default InventoryItem;