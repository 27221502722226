import {createBrowserRouter} from "react-router-dom";
import RootLayout, {loader} from "../views/layouts/RootLayout";
import ErrorPage from "../pages/ErrorPage";
import HomePage, {loader as dashboardLoader} from "../pages/HomePage";
import LoginPage from "../pages/auth/LoginPage";
import Vendor from "../pages/vendor/VendorList";
import Customer from "../pages/customer/CustomerList";
import CustomerAddEdit from "../pages/customer/CustomerAddEdit";
import VendorAddEdit from "../pages/vendor/VendorAddEdit";
import ProductList from "../pages/products/ProductList";
import ColorList from "../pages/products/settings/colors/ColorList";
import SizeList from "../pages/products/settings/size/SizeList";
import MasterColorList from "../pages/products/settings/master-colors/MasterColorList";
import MadeInList from "../pages/products/settings/made-ins/MadeInList";
import CategoryList from "../pages/products/settings/categories/CategoryList";
import VendorOrderList, {loader as vendorLoader} from "../pages/orders/VendorOrderList";
import AddEditProducts  from "../pages/products/AddEditProducts";
import CourierPageComponent from "../pages/settings/couriers/CourierPageComponent";
import InvoiceList from "../pages/invoices/InvoiceList";
import ShippingMethodPageComponent from "../pages/settings/shipping-methods/ShippingMethodPageComponent";
import PageSectionPage, {sectionLoader} from "../pages/page-sections/PageSectionPage";
import PageComponent from "../pages/page/PageComponent";
import SocialMedia from "../pages/social-media/SocialMedia";
import CommonBanner from "../pages/banners/common-banner/CommonBanner";
import Sections from "../pages/page-sections/Sections";
import VendorDetails, {loader as vendorDetailsLoad} from "../pages/vendor/VendorDetails";
import OrderForm, {loaderOrderDetails} from "../pages/orders/OrderForm";
import CustomerInformation, {preloadCustomerService} from "../pages/settings/customer-information/CustomerInformation";
import PageForm, {loadPage} from "../pages/page/PageForm";
import CommissionTransactions from "../pages/commission-transactions/CommissionTransactions";
import UpdatePasswordForm from "../pages/update-password/UpdatePasswordForm";
import CouponList from "../pages/coupons/CouponsList";
import CouponDetails, {loadCoupon} from "../pages/coupons/CouponDetails";
import CodeDetails, {loadCode} from "../pages/coupons/CodeDetails";
import CouponForm from "../pages/coupons/CouponForm";
import ImportProducts from "../pages/products/ImportProducts";
import ReviewListPage from "../pages/products/settings/review/ReviewListPage";
import HomePageSections from "../pages/home-page-section/HomePageSections";
import StaticMenuList from "../pages/products/settings/static-menus/StaticMenuList";
import AddEditStaticMenu from "../pages/products/settings/static-menus/AddEditStaticMenu";
import HomePageSectionForm, {preloadEditSection} from "../pages/home-page-section/HomePageSectionForm";
import WithdrawRequestList from "../pages/withdraw-request/WithdrawRequestList";
import CarriersPageComponent from "../pages/settings/carriers/CarriersPageComponent";
import ReviewDetails from "../pages/products/settings/review/ReviewDetails";
import IncompleteIndex from "../pages/incomplete-checkout/IncompleteIndex";
import IncompleteDetails, {loadIncompleteDetails} from "../pages/incomplete-checkout/IncompleteDetails";
import OrderGroup, {loadGroupDetails} from "../pages/order-group/OrderGroup";
import MonthlySalesByReport from "../pages/reports/MonthlySalesByReport";

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    loader: loader,
    children: [
      { index: true,
        element: <HomePage />,
        loader: dashboardLoader
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: '/vendors',
        element: <Vendor />,
      },
      {
        path: '/vendors/add',
        element: <VendorAddEdit />,
      },
      {
        path: '/vendors/edit/:id',
        element: <VendorAddEdit />,
      },
      {
        path: '/customers',
        element: <Customer />,
      },
      {
        path: '/customers/add',
        element: <CustomerAddEdit />,
      },
      {
        path: '/customers/edit/:id',
        element: <CustomerAddEdit />,
      },
      {
        path: '/products',
        element: <ProductList />,
      },
      {
        path: '/products/add',
        element: <AddEditProducts />
      },
      {
        path: '/products/edit/:id',
        element: <AddEditProducts />
      },
      {
        path: '/products/import',
        element: <ImportProducts />
      },
      {
        path: '/products/settings',
        children: [
          {
            path: 'colors',
            element: <ColorList />,
          },
          {
            path: 'sizes',
            element: <SizeList />,
          },
          {
            path: 'master-colors',
            element: <MasterColorList />,
          },
          {
            path: 'made-ins',
            element: <MadeInList />,
          },
          {
            path: 'reviews',
            element: <ReviewListPage />,
          },
          {
            path: 'reviews/:id',
            element: <ReviewDetails />,
          },
        ]
      },

      {
        path: '/orders',
        element: <VendorOrderList />,
        loader: vendorLoader
      },
      {
        path: '/invoices',
        element: <InvoiceList />,
      },
      {
        path: '/orders/:id',
        element: <OrderForm />,
        loader: loaderOrderDetails
      },
      {
        path: '/vendor-details/:id',
        element: <VendorDetails />,
        loader: vendorDetailsLoad
      },
      {
        path: '/change-password',
        element: <UpdatePasswordForm />
      },
      {
        path: '/settings',
        children: [
          {
            path: 'couriers',
            element: <CourierPageComponent />,
          },
          {
            path: 'carriers',
            element: <CarriersPageComponent />,
          },
          {
            path: 'shipping-methods',
            element: <ShippingMethodPageComponent />,
          },
          {
            path: 'customer-service-information',
            element: <CustomerInformation />,
            loader: preloadCustomerService
          },
        ]
      },
      {
        path: '/transactions',
        children: [
          {
            path: 'transactions',
            element: <CommissionTransactions />
          },
          {
            path: 'withdraw-requests',
            element: <WithdrawRequestList />
          }
        ]
      },
      {
        path: '/home-page-sections',
        element: <HomePageSections />
      },
      {
        path: '/home-page-sections/add',
        element: <HomePageSectionForm />
      },
      {
        path: '/home-page-sections/:id',
        element: <HomePageSectionForm />,
        loader: preloadEditSection
      },
      {
        path: '/banners',
        children: [
          {
            path: ':type',
            element: <CommonBanner />,
          },
        ]
      },
      {
        path: '/page-sections',
        element: <Sections />
      },
      {
        path: '/page-sections/:id',
        element: <PageSectionPage />,
        loader: sectionLoader
      },
      {
        path: '/page-sections/create',
        element: <PageSectionPage />,
        loader: sectionLoader
      },
      {
        path: '/pages',
        element: <PageComponent />
      },
      {
        path: '/pages/new',
        element: <PageForm />,
        loader: loadPage
      },
      {
        path: '/pages/edit/:slug',
        element: <PageForm />,
        loader: loadPage
      },
      {
        path: '/social-media',
        element: <SocialMedia />
      },
      {
        path: '/products',
        element: <ProductList />,
      },
      {
        path: '/products/categories',
        element: <CategoryList />,
      },
      {
        path: '/products/static-menus',
        element: <StaticMenuList />,
      },
      {
        path: '/products/static-menus/add',
        element: <AddEditStaticMenu />
      },
      {
        path: '/products/static-menus/edit/:id',
        element: <AddEditStaticMenu />
      },

      {
        path: '/coupons',
        element: <CouponList />,
      },
      {
        path: '/coupons/create',
        element: <CouponForm />,
      },
      {
        path: '/coupons/:id',
        element: <CouponDetails />,
        loader: loadCoupon
      },
      {
        path: '/coupons-code/:id',
        element: <CodeDetails />,
        loader: loadCode
      },
      {
        path: '/incomplete-checkout',
        element: <IncompleteIndex />
      },
      {
        path: '/incomplete-checkout/:id/:type',
        element: <IncompleteDetails />,
        loader: loadIncompleteDetails
      },
      {
        path: '/order-group/:id',
        element: <OrderGroup />,
        loader: loadGroupDetails
      },
      {
        path: '/reports/monthly-sales-by-brand',
        element: <MonthlySalesByReport />
      },
    ],
  },
]);

export default router;