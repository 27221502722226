import Button from "../../components/UI/Button";
import {Link} from "react-router-dom";

const  CouponListCardAction = ()=>{
    return (
        <Link  to={"/coupons/create"} className=" ">
            <Button text={'Add New Coupon'} variant={'btn_black'} />
        </Link>
    );
}
export default CouponListCardAction