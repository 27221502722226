import Button from "../../../components/UI/Button";
import {Link} from "react-router-dom";

const ShippingMethodCardActions = ({clickHandler, newShipping}) => {
  return (
    <>
      <Button
        text="Sync Shipping Method"
        variant={"btn_black"}
        classes={'mr_10'}
        onClickHandler={clickHandler}
      />
      <Button
        text="+ New Shipping Method"
        variant={"btn_black"}
        onClickHandler={newShipping}
      />
    </>

  );
};

export default ShippingMethodCardActions;