import Card from "../../../components/UI/Card";
import TabCardActions from "./TabCardActions";
import {useEffect, useState} from "react";
import {useFieldArray} from "react-hook-form";
import Input from "../../../components/UI/form/Input";
import RadioBox from "../../../components/UI/form/RadioBox";
import HomeSectionProduct from "../product/HomeSectionProduct";
import {useSelector} from "react-redux";

const HomeSectionTabComponent = ({errors, register, getValues, setValue, watch, control, clearErrors}) => {
  const {editSection} = useSelector(state => state.homePageSection)


  const [tabItems, setTabItem] = useState([])
  const newTabClickHandler = ()=> {
    clearErrors('tabs');
    setTabItem(old => ([...old, tabObject]))
    append({...tabObject})
  }

  const tabObject = {
    id: '',
    title: `New tab ${tabItems.length + 1}`,
    sort:  tabItems.length + 1,
    status: '1',
    products: []
  }

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "tabs"
  });

  useEffect(()=>{
    if(editSection && editSection.tabs.length){
      setTabItem(editSection.tabs)
      setTimeout(()=>{
        editSection.tabs.map(tab=>{
          append({...tab, status:tab.status+''})
        })
      },500)
    }
  }, [editSection])


  return (
    <>
      <h1 className="common_title">
        <div className="d_flex_btwn m_0 p_0">
          <span>
              <button type="button" className="btn btn_black btn_md" onClick={newTabClickHandler}><span
                className="false">+ Add New Tab</span></button>
          </span>
        </div>
      </h1>
      <Card cardClass={'border_none p_0'} cardBodyCls={'p_0'}>
        {errors.tabs && <div className="text_red">{errors.tabs.message}</div>}
        <div className="row">
          <div className="col_12">
            <div className="accordion_wrap">
              {fields.map((field, index) => (
                <div className="accordion" key={field.sort}>
                  <div className="accordion_heading">
                    <div className="accordion_heading_inner left" data-toggle="collapse" data-target={`#accordian_${field.index}${field.sort}`}>{watch(`tabs.${index}.title`) &&  getValues(`tabs.${index}.title`) ? getValues(`tabs.${index}.title`) :  field.title}</div>
                  </div>
                  <div className="accordion_body" id={`accordian_${field.index}${field.sort}`}>
                    <div className="row">

                      <div className="col_3 pr_20">
                        <Input label="Title"
                               inline={true}
                               name={`tabs.${index}.title`}
                               errors={errors}
                               validationRules={{required:true}}
                               register={register}/>
                      </div>

                      <div className="col_3 pr_20">
                        <Input label="Sort"
                               inline={true}
                               name={`tabs.${index}.sort`}
                               errors={errors}
                               validationRules={{required:true}}
                               register={register}/>
                      </div>
                      <div className="col_1"></div>
                      <div className="col_5">
                        <RadioBox label="Status"
                                  inline={true}
                                  name={`tabs.${index}.status`}
                                  errors={errors}
                                  options={[{id: "1", name: ` ${watch(`tabs.${index}.title`)} Active`}, {id: "0", name:` ${watch(`tabs.${index}.title`)} Inactive`}]}
                                  validationRules={{required:true, type: 'string'}}
                                  register={register}/>
                      </div>
                    </div>

                    <HomeSectionProduct index={index} key={`tab_bara_${index}`} control={control} watch={watch} setValue={setValue} clearErrors={clearErrors} errors={errors} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default HomeSectionTabComponent