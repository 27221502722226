import Card from "../../../components/UI/Card";
import React from "react";
import CouponUpdateCardButton from "../CouponUpdateCardButton";

const CouponDetailsCard = ({coupon, setModalStatus})=>{
    return (
      <Card title={"Details"} cardClass={'checkout_summery height_full'} CardActions={<CouponUpdateCardButton setModalStatus={setModalStatus} />}>
          <div className="row">
              <div className="col_6">
                  <div className="checkout_summery ">

                      <table className="table border_none">
                          <tbody>
                          <tr>
                              <td className={'width_150p fw_600'}>Name</td>
                              <td>{coupon.name}</td>
                          </tr>
                          <tr>
                              <td className={' width_150p fw_600'}>Created</td>
                              <td>{coupon.created_at}</td>
                          </tr>
                          <tr>
                              <td className={'width_150p fw_600'}>Times of Redeemed</td>
                              <td>{coupon.times_redeemed}</td>
                          </tr>
                          <tr>
                              <td className={'width_150p fw_600'}>Valid</td>
                              <td>{coupon.valid ? 'Yes' : 'No'}</td>
                          </tr>

                          </tbody>
                      </table>
                  </div>
              </div>
              <div className="col_6">
                  <div className="checkout_summery ">
                      <table className="table border_none">
                          <tbody>
                          {coupon.percentage_off &&
                          <tr>
                              <td className={'width_150p fw_600'}>Percentage discount</td>
                              <td>{coupon.percentage_off}% off</td>
                          </tr>
                          }

                          {coupon.amount_off &&
                          <tr>
                              <td className={'width_150p fw_600'}>Fixed amount discount</td>
                              <td>${coupon.amount_off}</td>
                          </tr>
                          }
                          <tr>
                              <td className={'width_150p fw_600'}>Maximum redemptions</td>
                              <td>{coupon.max_redemptions}</td>
                          </tr>
                          <tr>
                              <td className={'width_150p fw_600'}>Expires on</td>
                              <td>{coupon.redeem_by}</td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </Card>
    )
}

export default CouponDetailsCard