import Button from "../../../../../components/UI/Button";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {deleteStaticMenu} from "../../../../../api/staticMenus";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import StaticMenuListActions from "../StaticMenuListActions";
import {useState} from "react";
import {NavLink} from "react-router-dom";

export const StaticMenuAccordianItem = ({staticMenu}) =>{
  const [toastId, setToastId] = useState(null);
  const queryClient = useQueryClient();

  const {mutate} = useMutation({
    mutationFn: deleteStaticMenu,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Static Menu Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'staticMenus',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })
  const deleteHandler = (staticMenu) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: staticMenu.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    })
  }

  return(
    <div className="accordion">
      <div className="accordion_heading p_relative">
        <div className={`${staticMenu.sub_categories && staticMenu.sub_categories.length ? 'accordion_heading_inner left' : 'last_category'} `} data-toggle="collapse" data-target={`#parent${staticMenu.id}`}>{staticMenu.name}</div>
        <div className="category_actions p_absolute">
          <NavLink className="" to={`/products/static-menus/edit/${staticMenu.id}`}>
            <span className={'cursor_pointer'}><img src="/assets/media/images/edit.png" alt="" /></span>
          </NavLink>

          <span className="cursor_pointer pl_15" onClick={()=> deleteHandler(staticMenu)}><img src="/assets/media/images/trash.png" alt="" /></span>
          <span className="move pl_15"><i className="lni lni-move"></i></span>
        </div>
      </div>
    </div>
  )
}

export default StaticMenuAccordianItem