import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setOrderParams} from "../../../store/slices/orderSlice";
import Button from "../../../components/UI/Button";
import {useQuery} from "@tanstack/react-query";
import {getVendors} from "../../../api/vendor";

const OrderFilterComponent = () => {
  const defaultSearch = {order_number: '', name: '', vendor_id: ''}
  const dispatch = useDispatch();
  const store = useSelector(state => state.orders)
  const [params, setParams] = useState({...store.initialParams});
  const [search_by_column, setSearchColumn] = useState('order_number');
  const [searchParams, setSearchParams] = useState({...defaultSearch});

  const handleInputChange = (event, column) => {
    setParams((prevParams) => ({
      ...prevParams,
      [column]: event.target.value
    }));
  }
  const searchInputChange = (event, column) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      [column]: event.target.value
    }));
  }

  const {data: vendors} = useQuery({
    queryKey:['vendors'],
    queryFn: getVendors,
    initialData: () => []
  })

  const searchClickHandler = ()=>{
    setParams((prevParams) => ({
      ...prevParams,
      ...searchParams,
      search_by_column
    }));
  }
  const resetSearch = ()=>{
    setSearchColumn('')
    setParams((prevParams) => ({
      ...prevParams,
      ...defaultSearch,
      search_by_column:''
    }));
    setSearchParams((prevParams) => ({
      ...defaultSearch}));
  }

  useEffect(()=> {
    setParams(store.initialParams)
  }, [store.initialParams])

  useEffect(()=> {
    dispatch(setOrderParams(params))
  }, [params])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchClickHandler()
    }
  }

  return (
    <>
      <div className="oder_filter_content pb_10">
        <div className="form_row">
          <div className="form_input">
            <select className="form_global width_200p mr_5" value={params.per_page} onChange={(event)=>{handleInputChange(event,'per_page')}}>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
            </select>
            <select className="form_global width_200p mr_5" value={params.sort} onChange={(event)=>{handleInputChange(event,'period')}}>
              <option value="">Input Period</option>
              <option value="1">Today</option>
              <option value="2">This Week</option>
              <option value="3">This Month</option>
              <option value="5">This Year</option>
              <option value="6">Yesterday</option>
              <option value="8">Last Month</option>
              <option value="10">Last Year</option>
              <option value="13">Last 7 Days</option>
              <option value="14">Last 30 Days</option>
              <option value="15">Last 90 Days</option>
              <option value="16">Last 365 Days</option>
            </select>
            <select className="form_global width_200p mr_5" value={searchParams.vendor_id} onChange={(event)=>{handleInputChange(event,'vendor_id', 0)}}>
              <option value="">Select Vendor</option>
              {(vendors.data  && vendors.data.length) && vendors.data.map((cat)=>{
                return (<option key={cat.id} value={cat.id}>{cat.name}</option> )
              })}
            </select>
            <select className="form_global width_200p mr_5" value={search_by_column} onChange={(event)=>{setSearchColumn(event.target.value)}}>
              <option value="order_number">Order Number</option>
              <option value="username">User Name</option>
              <option value="email">email</option>
              <option value="fname">Name</option>
              <option value="phone">Phone</option>
            </select>
            <div className="search_input">
              <input
                type="text"
                className="form_global"
                placeholder="Search"
                value={searchParams.name}
                onKeyDown={handleKeyPress}
                onChange={(event)=>searchInputChange(event, 'name')}
              />
            </div>
            <Button text={"Search"}
                    variant={"btn_black"}
                    classes={"ml_5 mr_5"}
                    onClickHandler={searchClickHandler} />
            <Button text={"Reset"}
                    variant={"btn_black "}
                    onClickHandler={resetSearch} />
          </div>
        </div>
      </div>



    </>
  )
}

export default OrderFilterComponent;