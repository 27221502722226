import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export default function Modal({ children, open, setOpen, title = "", size = "modal_850p"}) {
  const dialog = useRef();

  const closeHandler = () => {
    dialog.current.close();
    setOpen(false);
  };

  useEffect(() => {
    const modal = dialog.current;

    if (open) {
      modal.showModal();
    }

    return () => modal.close();
  }, [open]);

  return createPortal(
    <dialog ref={dialog} onClose={closeHandler}>
      <div className={`modal ${open && 'open_modal'}`} data-modal="smodal">
        {open && <div className="modal_overlay" onClick={closeHandler} />}
        <div className="modal_wrap">
          <span className="close_icon close_icon_md" onClick={closeHandler}></span>
          <div className="modal_header">
            <h3>{title}</h3>
          </div>
          <div className="modal_inner">
            <div className={`modal_content p_20 ${size}`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </dialog>,
    document.getElementById('modal')
  );
}