import Button from "../../../../components/UI/Button";

const ColorListActions = ({clickHandler}) => {
  return (
  <Button
    text="+ Add New Color"
    variant={"btn_black"}
    onClickHandler={clickHandler}
  />
  );
};

export default ColorListActions;