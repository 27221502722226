import API from "../util/api";

export const getStaticMenus = async (params) => {
  let data = null;

  await API.get('/static-menus', {params})
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });
  return data;
}
export const getStaticMenuDetails = async (payload) => {
  let data = null;
  await API.get('/static-menus/' + payload)
      .then(res => {

        data = res.data.data;
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err.response.data))
      });

  return data;
}
export const deleteStaticMenu = async (params) => {
  let data = null;

  await API.delete('/static-menus/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const storeStaticMenu = async (payload) => {
  let data = null;

  await API.post('/static-menus', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const sortStaticMenu = async (payload) => {
  let data = null;
  await API.post('/static-menus/sort', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateStaticMenu = async (id, payload) => {
  let data = null;
  payload.append('_method','PATCH')
  await API.post(`/static-menus/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const removeStaticMenuBanner = async (id, payload) => {
  let data = null;

  await API.post(`/static-menus-banner/${id}`, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
