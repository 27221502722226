import Card from "../../../components/UI/Card";
import React from "react";

const CouponSummaryCard = ({coupon})=>{
    return (
      <Card title={`${coupon ? coupon.name : 'Coupon Summary'}`} cardClass={'border_none p_0'} cardBodyCls={'p_0'}>
          <div className="card mini-stats mt_0">
              <div className="card_heading">
                              <span className="mr_5">
                                  <i className="font_16p fa fa-user"></i>
                              </span>
                  Summary
              </div>
              <div className="card_body">
                  <div className="mini-stats-content">
                      <div className="inner">
                          <h2>Terms</h2>
                          {coupon.amount_off && <p>${coupon.amount_off} off for {coupon.duration}</p>}
                          {coupon.percentage_off && <p>${coupon.percentage_off}% off   {coupon.duration}</p>}
                      </div>
                      <div className="inner" style={{"borderRight":"1px solid rgb(222, 222, 221)"}}>
                          <h2>Usage</h2>
                          <p> {coupon.times_redeemed} out of {coupon.max_redemptions} redemption</p>
                      </div>
                      <div className="inner">
                          <h2>Expires on</h2>
                          <p> {coupon.redeem_by}  </p>
                      </div>
                  </div>
              </div>
          </div>
      </Card>
    )
}

export default CouponSummaryCard