import {getErrorObj, getValidationMessage} from "../../../util/validationMessage";

const Input = ({ inline = false,
                 label = "",
                 name,
                 register,
                 validationRules = {},
                 errors =null,
                 type = 'text',
                 classes = '',
                 message = '',
                  disable=false,
                 placeholder=''
               }) => {

  const style = !inline ? {'top':'68%'} : null


  return (
    <div className={`${ classes } ${inline ? ' form_row_inline' : 'form_row'} ${getErrorObj(errors, name) && 'has_error'}`}>
      {label && <label className={validationRules.required ? 'required' : ''}>{label}</label>}
      <div className={`form_input ${!label && 'width_full'}`}>
        <input {...register(name, validationRules)}
               type={type}
               step={type === 'number' ? '0.01' : ''}
               placeholder={placeholder}
               disabled={disable}
               className={`form_global`} />
      </div>
      {message && <span style={style } className="p_right_center color_danger">{message}</span>}
      {getErrorObj(errors, name) && <small>{getValidationMessage(getErrorObj(errors, name), label)}</small>}
    </div>
  );
};

export default Input;