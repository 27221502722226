import Button from "../../../components/UI/Button";

const CourierCardActions = ({clickHandler}) => {
  return (
  <Button
    text="+ Add New Courier"
    variant={"btn_black"}
    onClickHandler={clickHandler}
  />
  );
};

export default CourierCardActions;