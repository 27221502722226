import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../../util/validationMessage";
import RadioBox from "../../../../components/UI/form/RadioBox";
import Input from "../../../../components/UI/form/Input";
import Button from "../../../../components/UI/Button";
import {removeStaticMenuBanner, storeStaticMenu, updateStaticMenu} from "../../../../api/staticMenus";
import TextArea from "../../../../components/UI/form/TextArea";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {removeCategoryBanner} from "../../../../api/categories";
import {setCurrentEditMenu} from "../../../../store/slices/staticMenuSlice";

const StaticMenuForm = ({selectedProductsForStaticMenu}) =>{
  const staticMenu = useSelector(state => state.staticMenu.currentEditMenu)
  const params = useParams();
  const isEditMode = !!params.id;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {getValues, setValue, watch, reset, register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      status: "1",
      sort: "1",
    },
  });

  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  const {isPending, mutate} = useMutation({
    mutationFn: staticMenu ? (data) => updateStaticMenu(staticMenu.id, data) : storeStaticMenu,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, {
        render: `Category ${staticMenu ? 'Updated' : 'Added'}`,
        type: "success",
        isLoading: false,
        autoClose: true
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'static-menus',
      });
      reset();
      navigate('/products/static-menus');
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, {
        render: errorMsg,
        type: "error",
        isLoading: false,
        autoClose: true
      });
    }
  });

  const onSubmit = (data) => {
    clearErrors();
    const file = data.file[0];
    let formData = new FormData()
    Object.keys(data).forEach(key => {
      formData.append(key, data[key] ? data[key] : '');
    })

    if (selectedProductsForStaticMenu) {
      const productIds = selectedProductsForStaticMenu.map(product => product.id);

      formData.append('selected_products', productIds)
    }

    if(file)
      formData.append('file', file)

    mutate(formData)
  }

  useEffect(() => {
    if (staticMenu && isEditMode) {
      Object.keys(staticMenu).forEach(key => {
        if (key === 'status') {
          setValue(key, staticMenu[key] ? "1" : "0");
        }
        else {
          setValue(key, staticMenu[key] ? (staticMenu[key] + "") : "");
        }
      })
    }
  },[staticMenu]);
  const [removeBannerColumn, setRemoveBannerColumn] = useState(null)
  const {isPending:removePending, mutate:removeMutate} = useMutation({
    mutationFn:  (data) => removeStaticMenuBanner(staticMenu.id, data) ,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, {
        render: `Banner has been removed'`,
        type: "success",
        isLoading: false,
        autoClose: true
      });

      dispatch(setCurrentEditMenu(res.data))
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      toast.update(toastId, {
        render: errorMsg,
        type: "error",
        isLoading: false,
        autoClose: true
      });
    }
  });
  const removeBanner = (column) => {
    removeMutate({column:column})
    setRemoveBannerColumn(column)
  }

  return(
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col_6 pr_20">
            <Input label="Name"
                   name="name"
                   errors={errors}
                   inline={true}
                   validationRules={{required: true, type: 'string'}}
                   register={register}/>
          </div>

          <div className="col_6">
            <Input label="Image"
                   name="file"
                   type={"file"}
                   inline={true}
                   message={'Recommended ratio (24:5) e.g: (1920X400)'}
                   errors={errors}
                   register={register}/>
            {(staticMenu && staticMenu.banner) &&
            <div className="text_right pb_15 ">
              <div className="item_img_upload_wrap">
                <div className="image_show_wrap">
                  <div className="width_full d_flex_end">
                    <div className="inner" style={{'width':'max-content'}}>
                      <div className="inner_wrap">
                        {removePending && removeBannerColumn === 'banner' ?
                          <div className="remove_list opacity_1_important">
                            <button type="button" className="btn btn_black btn_xs">
                              <span className="false"><FontAwesomeIcon icon={faSpinner} spin/></span>
                            </button>
                          </div> :
                          <div className="remove_list">
                            <button type="button" className="btn btn_black btn_xs"  onClick={()=>removeBanner('banner')}>
                              <span className="false"><i className="lni lni-close"></i></span>
                            </button>
                          </div>
                        }
                        <img id={'icon'} src={staticMenu.banner} height={"60"}  alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
        <div className="row">
          <div className="col_6 pr_20">
            <Input label="Meta Title"
                   name="meta_title"
                   errors={errors}
                   inline={true}
                   validationRules={{required: false, type: 'string'}}
                   register={register}/>

            <RadioBox label="Status"
                      name="status"
                      errors={errors}
                      optionValue={'id'}
                      inline={true}
                      optionLabel={'name'}
                      options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                      validationRules={{required:true, type: 'string'}}
                      register={register}/>
          </div>

          <div className="col_6">
            <TextArea label="Meta Description"
                      name="meta_description"
                      errors={errors}
                      inline={true}
                      validationRules={{required: false, type: 'string'}}
                      register={register}/>
          </div>
        </div>


        <div className="d_flex_end">
          <Button
            text="save"
            isPending={isPending}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
}

export default StaticMenuForm;