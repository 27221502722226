import {useFieldArray} from "react-hook-form";
import React, {useEffect, useState} from "react";
import Button from "../../../../components/UI/Button";
import {useDispatch, useSelector} from "react-redux";
import {removeOrderFormProduct, updateOrderItem} from "../../../../store/slices/orderSlice";
import {noImagePlaceholder, unstructureJson} from "../../../../util/helper";

const OrderProductList = ({ setValue, register, errors, getValues, control, watch}) => {

  const { fields:productFields, append, remove} = useFieldArray({
    control,
    name: 'products',
  });
  const {products} = useSelector(state => state.orders.orderForm);
  const dispatch = useDispatch()

  useEffect(()=>{
  }, [products])

  const updateQty = (e,i) => {
    dispatch(updateOrderItem({quantity:e.target.value, index:i}))
  }

  return (
    <>
      <div className="view_order_details">
      <div className="img_wrap">
        {products && products.map((product, i)=>(
        <div className="inner p_relative" key={'product_'+i}>
          <div className="inner_wrap">
            <img src={product.url} width={100} alt="" onError={noImagePlaceholder} />
              <div className="text">
                {/*<div className={"d_flex_inline"}>*/}
                {/*  <div className="form_row mb_0">*/}
                {/*    <div className="form_input">*/}
                {/*      <input type="number" className={"form_global height_25p"} value={product.quantity} onChange={(e)=>updateQty(e, i)} />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <p>Quantity: {product.quantity}</p>
                {product.sku && <p>{product.sku}</p>}
                {product.product_variant_values && <p>
                   {unstructureJson(product.product_variant_values)}
                </p>}

                <p className={"mt_5"}>Unit Price:${product.price.toFixed(2)}</p>
                <p>Total price: ${(product.price * product.quantity) .toFixed(2)}</p>

              </div>
          </div>
        </div>))
        }
      </div>
      </div>
    </>
);
}

export default OrderProductList