import {createSlice} from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: 'product',
    initialState: {
        selectedProducts: [],
        selectedProductsForStatic: [],
        allProducts: [],
        activeTab: 1,
        paginations: null,
        sendRequest: false,
        requestStatus: true,
        initialParams: {
            page: 1,
            search: '',
            search_columns: {
                name: 1,
                description: 1,
                style_no: 1
            },
            name: 1,
            description: 1,
            style_no: 1,
            sort: 'created_at',
            sort_order: 'desc',
            status: 1,
            vendor_id: '',
            parent_category_id: '',
            second_category_id: '',
            third_category_id: '',
            per_page: 50
        },
        currentEditProduct: null,
    },
    reducers: {
        selectUnselectProduct: (state, action) => {
            const id = action.payload;
            const existingItemIndex = state.selectedProducts.findIndex(item => item === +id);

            if (existingItemIndex !== -1) {
                state.selectedProducts = state.selectedProducts.filter(item => item !== +id);
            } else {
                state.selectedProducts.push(+id);
            }
        },
        selectUnselectProductForStaticMenu: (state, action) => {
            const id = action.payload;
            const existingItemIndex = state.selectedProductsForStatic.findIndex(item => item === +id);

            if (existingItemIndex !== -1) {
                state.selectedProductsForStatic = state.selectedProductsForStatic.filter(item => item !== +id);
            } else {
                state.selectedProductsForStatic.push(+id);
            }
        },
        setSelectProductsForStaticMenus: (state, action) => {
            const ids = action.payload;
            state.selectedProducts = ids;
        },
        setSelectToggle: (state, action) => {
            if(action.payload.type === 'select'){
                state.selectedProducts = []
                state.allProducts.map((item) => state.selectedProducts.push(item.id))
            }

            if(action.payload.type === 'unselect'){
                state.selectedProducts = []
            }
        },
        setTabStatus: (state, action) => {
            state.activeTab = action.payload === 'active_item' ? 1 : 0
        },
        setParams: (state, action) => {
            state.initialParams = action.payload
        },
        setProductPagination: (state, action) => {
            state.paginations = action.payload
        },
        setAllProducts: (state, action) => {
            state.allProducts = action.payload
        },
        setSendRequest: (state, action) => {
            state.sendRequest = action.payload
        },
        setRequestStatus: (state, action) => {
            state.requestStatus = action.payload
        },
        setCurrentEditProduct: (state, action) => {
            state.currentEditProduct = action.payload
        },
    }

});
export const {
    selectUnselectProduct,
    selectUnselectProductForStaticMenu,
    setSelectProductsForStaticMenus,
    setTabStatus,
    setAllProducts,
    setProductPagination,
    setRequestStatus,
    setParams,
    setSendRequest,
    setCurrentEditProduct,
    setSelectToggle
} = productSlice.actions;
export default productSlice.reducer;
