import Button from "../../../../components/UI/Button";

const MasterColorListActions = ({clickHandler}) => {
  return (
  <Button
    text="+ Add New Master Color"
    variant={"btn_black"}
    onClickHandler={clickHandler}
  />
  );
};

export default MasterColorListActions;