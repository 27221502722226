import {configureStore} from "@reduxjs/toolkit";
import authSliceReducer from './slices/authSlice';
import productSliceReducer from "./slices/productSlice";
import orderSlice from "./slices/orderSlice";
import bannerSlice from "./slices/bannerSlice";
import vendorUserSlice from "./slices/vendorUserSlice";
import staticMenuSlice from "./slices/staticMenuSlice";
import homePageSectionSlice from "./slices/homePageSectionSlice";
export default configureStore({
  reducer: {
    auth: authSliceReducer,
    product: productSliceReducer,
    orders: orderSlice,
    banners: bannerSlice,
    vendorUser: vendorUserSlice,
    staticMenu: staticMenuSlice,
    homePageSection: homePageSectionSlice
  }
})