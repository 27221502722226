import SelectableProductListComponent from "../../products/SelectableProductListComponent";
import HomeSectionSelectedProducts from "./HomeSectionSelectedProducts";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export default function HomeSectionProduct({index, control, watch, setValue, clearErrors, errors}) {
  const [product, setProduct] = useState(null);
    const [selectedTabProducts, setSelectedTabProducts] = useState([]);
    const {editSection} = useSelector(state => state.homePageSection)

  const selectUnselectProduct = (product) => {
    if(errors.tabs && errors.tabs.length && errors.tabs[index] ) {
      clearErrors(`tabs[${index}].products`)
    }
    setProduct(product)
      
      const isExisting = selectedTabProducts.some(item => item.id === product.id);
      if (isExisting) {
          const updateProducts = selectedTabProducts.filter(item => item.id !== product.id)
          setSelectedTabProducts(updateProducts)
      } else {
          setSelectedTabProducts((prevState) => [...prevState, product])
      }
  }

    const removeSelectedProduct = (index, id) => {
        const updatedProducts = [...selectedTabProducts];
        updatedProducts.splice(index, 1);
        setSelectedTabProducts(updatedProducts);
    };

  useEffect(()=>{
    setProduct(null)
  }, [watch(`tabs[${index}].products`)]);

  useEffect(()=>{
      if (editSection && editSection.tabs.length > 0 && editSection.tabs[index]?.products) {
          setSelectedTabProducts([...editSection.tabs[index]?.products]);
      }
  }, []);

  return (
    <div className="row">
      <div className="col_12">
        <SelectableProductListComponent index={index} selectUnselectProduct={(product)=>selectUnselectProduct(product)} selectedProducts={selectedTabProducts}/>
      </div>
      <div className="col_12">
        {errors.tabs && errors.tabs[index] && errors.tabs[index].products && <div className="text_red">{errors.tabs[index].products.message}</div>}
        <HomeSectionSelectedProducts name={`tabs[${index}].products`} control={control} setValue={setValue}  product={product} selectedProducts={selectedTabProducts} removeSelectedProduct={removeSelectedProduct} />
      </div>
    </div>
  );
}