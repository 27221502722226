import {useEffect, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getVendors} from "../../api/vendor";
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
const CommissionTransactionFilter = ({params, setParams}) => {

  const queryparams = {
    per_page: 20,
    page: 1,
    search: '',
    start_date: '',
    end_date: '',
    vendor_id: '',
  }
  const [filterParams, setFilterParams] = useState({...queryparams});

  const handlePerPage = (e) => {
    setParams({...filterParams,  per_page: e.target.value, page:1});
    setFilterParams({...filterParams,  per_page: e.target.value, page:1});
  }

  const {data: vendors} = useQuery({
    queryKey:['vendors'],
    queryFn: () => getVendors(),
    initialData: () => []
  })

  const filterVendor = (event) => {
    setParams({...filterParams,  vendor_id: event.target.value, page:1});
    setFilterParams({...filterParams,  vendor_id: event.target.value, page:1});
  }

  const dateInputRef = useRef(null);
  const endDate = useRef(null);

  useEffect(() => {
    $(dateInputRef.current).datepicker({
      dateFormat: "yy-mm-dd",
      onSelect: function(dateText) {
        setParams({...filterParams,  start_date: dateText, page:1});
        setFilterParams({...filterParams,  start_date: dateText, page:1});
      }
    });

    $(endDate.current).datepicker({
      dateFormat: "yy-mm-dd",
      onSelect: function(dateText) {
        setParams({...filterParams,  end_date: dateText, page:1});
        setFilterParams({...filterParams,  end_date: dateText, page:1});
      }
    });
  }, []);


  return (
    <>
      <div className="color_filter_content">

        <div className="row">
          <div className="col_12">
            <div className="form_row">
              <div className="form_input">
                <select className="form_global width_full mr_10" value={params.vendor_id} onChange={(event)=>{filterVendor(event)}}>
                  <option value="">All Vendors</option>
                  {vendors.data && vendors.data.length > 0 ?
                    vendors.data.map(item => <option key={'vendor_'+item.id} value={item.id}>{item.name}</option> ) : ''
                  }
                </select>
                <div className="form_input width_full datepicker_global mr_10">
                  <input type="text" placeholder={'Start Date'} className="datepicker form_global" ref={dateInputRef} />
                </div>
                <div className="form_input width_full datepicker_global mr_10">
                  <input type="text" placeholder={'End Date'} className="datepicker form_global" ref={endDate} />
                </div>
                <select className="form_global width_full" value={params.per_page} onChange={(event)=>{handlePerPage(event)}}>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="86">86</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommissionTransactionFilter