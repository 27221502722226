import API from "../util/api";
export const getTransactions = async (params) => {
  let data = null;

  await API.get('/commission-transactions', {params})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getWithdrawRequestList = async (params) => {
  let data = null;

  await API.get('/withdraw-request-list', {params})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const withdrawRequestStatusChange = async (id, payload) => {
  let data = null;

  await API.post(`/withdraw-request-status/${id}`, {...payload})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}