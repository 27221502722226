import API from "../util/api";

export const getCoupons = async (params) => {
    let data = null;

    await API.get('/coupons', {params})
        .then(res => {
            data = res.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const getCouponDetails = async (payload) => {
    let data = null;
    await API.get('/coupons/'+payload)
        .then(res => {

            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const getCodeDetails = async (payload) => {
    let data = null;
    await API.get('/coupon-codes/'+payload)
        .then(res => {

            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}


export const storeCoupon = async (payload) => {
    let data = null;

    await API.post('/coupons', payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const updateCoupon = async (id, payload) => {
    let data = null;
    await API.patch(`/coupons/${id}`, payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const updateCouponCode = async (payload) => {
    console.log(payload)
    let data = null;
    await API.patch(`/coupon-codes/${payload.id}`, payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const deleteCoupon = async (params) => {
    let data = null;

    await API.delete('/coupons/'+params.id)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}
export const deleteCouponCode = async (params) => {
    let data = null;

    await API.delete('/coupon-codes/'+params.id)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}

export const storeCouponCode = async (payload) => {
    let data = null;

    await API.post('/coupon-codes', payload)
        .then(res => {
            data = res.data.data;
        })
        .catch((err) => {
            throw new Error(JSON.stringify(err.response.data))
        });

    return data;
}