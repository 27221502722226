import Card from "../../../components/UI/Card";
import React from "react";

const CodeDetailsComponent = ({code})=>{
    return (
        <Card title={"Details"} cardClass={'checkout_summery height_full'}>
            <div className="row">
                <div className="col_6">
                    <table className="table border_none mb_0">
                        <tbody>
                        <tr>
                            <td className="width_260p">Created:</td>
                            <td>{code.created_at}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Expires on:</td>
                            <td>{code.expires_at}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Code:</td>
                            <td>{code.code}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Times Redeemed:</td>
                            <td>{code.times_redeemed}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Valid:</td>
                            <td>{code.active ? 'Yes' : 'No'}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Auto apply due to checkout ?</td>
                            <td>{code.auto_apply ? "yes" : "No"}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div className="col_6">
                    <table className="table border_none mb_0">
                        <tbody>
                        <tr>
                            <td className="width_260p">Times of Redeemed:</td>
                            <td>{code.times_redeemed}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Free Shipping:</td>
                            <td>{code.free_shipping ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">First-time Order Only:</td>
                            <td>{code.first_time_transaction ? "yes" : "No"}</td>
                        </tr>
                        <tr>
                            <td className="width_260p">Maximum Redemptions:</td>
                            <td>{code.max_redemptions}</td>
                        </tr>
                        {code.minimum_amount && <tr>
                            <td className="width_260p">Minimum Amount:</td>
                            <td>{code.minimum_amount}</td>
                        </tr>
                        }

                        </tbody>
                    </table>
                </div>
            </div>
        </Card>
    )
}

export default CodeDetailsComponent