import Select from "../../components/UI/form/Select";
import Input from "../../components/UI/form/Input";
import RadioBox from "../../components/UI/form/RadioBox";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectToggle} from "../../store/slices/productSlice";
import {useParams} from "react-router-dom";
import {setEditSection} from "../../store/slices/homePageSectionSlice";

const HomeSectionFormCommonComponent = ({errors, register, getValues, setValue, watch}) => {
  const [currentSectionType, setCurrentSectionType] = useState(null)
  const {editSection} = useSelector(state => state.homePageSection)
  const dispatch = useDispatch()
  const params = useParams()

  const sectionsNames = [
    {
      name: 'Banner Type 1',
      id: 'banner_type_1',
      image: '/assets/media/images/home-page-sections/banner-type-1.png',
    },
    {
      name: 'Banner Type 2',
      id: 'banner_type_2',
      image: '/assets/media/images/home-page-sections/banner-type-2.png',
    },
    {
      name: 'Product Type 1',
      id: 'product_type_1',
      image: '/assets/media/images/home-page-sections/product-type1.png',
    },
    {
      name: 'Product Type 2',
      id: 'product_type_2',
      image: '/assets/media/images/home-page-sections/product-type2.png',
    },
    {
      name: 'Product Type 3',
      id: 'product_type_3',
      image: '/assets/media/images/home-page-sections/product-type3.png',
    },
    {
      name: 'Product Type 4',
      id: 'product_type_4',
      image: '/assets/media/images/home-page-sections/product-type4.png',
    },
    {
      name: 'Tab',
      id: 'tab',
      image: '/assets/media/images/home-page-sections/tab.png',
    },
  ]

  useEffect(()=>{
    if(getValues('type')){
      let item = sectionsNames.find(item => item.id === getValues('type'))
      setCurrentSectionType(item)
    }

    if (!params.id) {
      setValue('products', [])
      dispatch(setSelectToggle({type: 'unselect'}))
      dispatch(setEditSection(null))
    }
  }, [watch('type')])

  useEffect(()=>{
    if(editSection){
      setValue('description', editSection.description)
      setValue('sort', editSection.sort)
      setValue('status', editSection.status+'')
      setValue('type', editSection.type)
      setValue('title', editSection.title)
      setValue('product_type', editSection.product_type)
    }
  }, [editSection])

  return (
    <div className="row mb_30">
      <div className="col_7">
        <div className="row">
          <div className="col_12 pr_30">
            <Select
              name={`type`}
              options={sectionsNames}
              label={"Type"}
              inline={true}
              errors={errors}
              validationRules={{required: true}}
              register={register} />
          </div>
          <div className="col_12 pr_30">
            <Input label="Sort"
                   name="sort"
                   inline={true}
                   errors={errors}
                   register={register}/>
          </div>
          <div className="col_12 pr_30">
            <RadioBox label="Status"
                      name="status"
                      errors={errors}
                      inline={true}
                      options={[{id: "1", name: 'Active'}, {id: "0", name:"Inactive"}]}
                      validationRules={{required:true, type: 'string'}}
                      register={register}/>
          </div>
        </div>
      </div>
      <div className="col_5 pl_30">
        <div className="row">
          {currentSectionType && <div className="12 ">
            <a className={'p_relative'} target={'_blank'} href={currentSectionType.image}>
              <img className={'width_full'} src={currentSectionType.image} alt=""/>
            </a>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default HomeSectionFormCommonComponent