import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import { useState} from "react";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../../../util/validationMessage";
import Modal from "../../../../components/UI/Modal";
import Card from "../../../../components/UI/Card";
import VideoLinksActions from "../../../../views/products/settings/static-menus/VideoLinksActions";
import Input from "../../../../components/UI/form/Input";
import RadioBox from "../../../../components/UI/form/RadioBox";
import Button from "../../../../components/UI/Button";
import { storeStaticMenuVideo, updateStaticMenuVideo} from "../../../../api/staticMenuVideo";
import StaticMenuVideoTable from "../../../../views/products/settings/static-menus/StaticMenuVideoTable";

const VideoURLInput = ({staticMenu}) => {

    const queryClient = useQueryClient();
    const [toastId, setToastId] = useState(null);
    const [modalStatus, setModalStatus] = useState(false);
    const [editVideo, setEditVideo] = useState(null);

    const {getValues, setValue, watch, reset, control, register, setError, clearErrors, handleSubmit, formState: { errors }} = useForm({
        defaultValues:{
            status: '1',
            type: 'video',
        }
    });

    const {isPending, mutate} = useMutation({
        mutationFn: editVideo ? (data) => updateStaticMenuVideo(editVideo.id, data) : (data)=> storeStaticMenuVideo(data),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, {
                render: `Video Link ${editVideo ? 'Updated' : 'Added'}`,
                type: "success",
                isLoading: false,
                autoClose: true
            });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'static-menu-videos',
            });
            reset();
            closeBannerModal()
            setEditVideo(null)
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            if (error && error.message && error.message.errors) {
                setValidationMessage(error.message.errors, setError);
                errorMsg = error.message.message;
            }
        }
    });

    const onSubmit = (data) => {
        clearErrors();
        let file = null;

        if(data.type === 'video'){
            file = data.url[0];
        }

        let formData = new FormData()
        Object.keys(data).forEach(key => {
            formData.append(key, data[key] ? data[key] : '');
        })

        if(file)
            formData.append('url', file)

        formData.append('static_menu_id', staticMenu.id)


        mutate(formData)
    }

    const closeBannerModal = () => {
        reset()
        setModalStatus(false);
    }

    const handleEditClick = (row) => {
        setValue('url', row.type === 'youtube' ? row.url : '')
        setValue('status', row.status+'')
        setValue('type', row.type)
        setEditVideo(row)
        setModalStatus(true)
    }

    return (
        <>
            <div className="row">
                <div className="col_12">
                    <Card title={'Video Links'}  CardActions={<VideoLinksActions openModal={setModalStatus} />} >
                        <StaticMenuVideoTable editRecord={handleEditClick} />
                    </Card>
                </div>
            </div>

            <Modal title={`${editVideo ? 'Edit' : 'Add'} Video`} open={modalStatus} setOpen={closeBannerModal} size={"modal_600p"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col_12">
                        <RadioBox label="Type"
                                  name="type"
                                  errors={errors}
                                  inline={true}
                                  options={[{id: "video", name: 'Video'}, {id: 'youtube', name: "Youtube"}]}
                                  validationRules={{required: true, type: 'string'}}
                                  register={register}/>
                    </div>
                    <div className="col_12">
                        <Input label={watch('type') === 'youtube' ? 'Embed Link' : 'Video'}
                               type={watch('type') === 'youtube' ? 'text' : 'file'}
                               name="url"
                               errors={errors}
                               inline={true}
                               validationRules={{required: false, type: ''}}
                               register={register}/>
                    </div>
                    <div className="col_12">
                        <RadioBox label="Status"
                                  name="status"
                                  errors={errors}
                                  inline={true}
                                  options={[{id: "1", name: 'Active'}, {id: '0', name: "Inactive"}]}
                                  validationRules={{required: true, type: 'string'}}
                                  register={register}/>
                    </div>

                    <div className="col_12">
                        <div className="d_flex_end">
                            <Button
                                text="save"
                                isPending={isPending}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </form>
            </Modal>

        </>
    )
}

export default VideoURLInput