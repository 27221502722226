import Input from "../../components/UI/form/Input";
import Button from "../../components/UI/Button";
import Modal from "../../components/UI/Modal";
import {useForm} from "react-hook-form";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {setValidationMessage} from "../../util/validationMessage";
import {useDispatch, useSelector} from "react-redux";
import {setEditVendorUser} from "../../store/slices/vendorUserSlice";
import {updateVendorUser} from "../../api/vendor";

const BannerForm = ({modalStatus, setModalStatus, showTextField, type, setIsDataUpdated}) => {
  const { reset, register, setError, setValue, handleSubmit, formState: { errors }} = useForm({
    defaultValues: {
      type: type,
      status: "1",
    },
  });
  const {editVendorUser} = useSelector(state => state.vendorUser)
  const dispatch = useDispatch();
  const [tempShow, setTempFileShow] = useState(null)
  const queryClient = useQueryClient();
  const [toastId, setToastId] = useState(null);

  useEffect(()=>{
    if(editVendorUser){
      setValue('name', editVendorUser.name)
      setValue('email', editVendorUser.email)
      setValue('password', editVendorUser.password)
      setValue('password_confirmation', editVendorUser.password_confirmation)
      setModalStatus(true)
    }
  }, [editVendorUser])

  const addVendorUser = () => {}

  const {isPending, mutate} = useMutation({
    mutationFn: editVendorUser ? (data) => updateVendorUser(editVendorUser.id, data) : (data)=> addVendorUser(data),
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (res) => {
      toast.update(toastId, { render: `Vendor User ${editVendorUser ? 'Updated' : 'Added'}`, type: "success", isLoading: false, autoClose: true });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === `vendorUsers-${type}`,
      });
      setModalStatus(false)
      setIsDataUpdated(true)
      reset();
    },
    onError: (error) => {
      let errorMsg = "Something happened!";
      if (error && error.message && error.message.errors) {
        setValidationMessage(error.message.errors, setError);
        errorMsg = error.message.message;
      }

      toast.update(toastId, { render: errorMsg, type: "error", isLoading: false, autoClose: true });
    }
  });

  const onSubmit = (data) => {
    mutate(data)
  }

  const closeBannerModal = () => {
    reset();
    dispatch(setEditVendorUser(null));
    setModalStatus(false)
  }
  return (
    <>
      <Modal title={`${type === 'edit' ? 'Edit' : 'Add'} Vendor User`} open={modalStatus} setOpen={closeBannerModal} size={"modal_600p"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col_12">
              <Input label="Name"
                     inline={true}
                     name="name"
                     errors={errors}
                     register={register}/>
            </div>

            <div className="col_12">
              <Input label="Email"
                     inline={true}
                     name="email"
                     errors={errors}
                     register={register}/>
            </div>

            <div className="col_12">
              <Input label="Password"
                     name="password"
                     inline={true}
                     type="password"
                     errors={errors}
                     register={register}/>
            </div>
            <div className="col_12">
              <Input label="Confirm Password"
                     name="password_confirmation"
                     inline={true}
                     type="password"
                     errors={errors}
                     register={register}/>
            </div>

            <div className="col_12">
              <div className="d_flex_end">
                <Button
                  text="save"
                  isPending={isPending}
                  type={"submit"}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default BannerForm;