import API from "../util/api";

export const getProducts = async (params) => {
  let data = null;

  await API.get('/products', {params:{...params}})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const storeProduct = async (payload) => {
  let data = null;

  await API.post('/products', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const updateProduct = async (productId, payload) => {
  let data = null;

  await API.patch('/products/' + productId, payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const getProductDetails = async (payload) => {
  let data = null;
  await API.get('/products/' + payload)
    .then(res => {

      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}

export const productCombinedAction = async (payload) => {
  let data = null;

  await API.post('/products/combined-action', payload)
    .then(res => {
      getProducts()
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const getProductReviews = async (params) => {
  let data = null;

  await API.get('/products/reviews', {params:{...params}})
    .then(res => {
      data = res.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const deleteProductReviews = async (params) => {
  let data = null;

  await API.delete('/products/reviews/'+params.id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
export const getProductReviewDetails = async (id) => {
  let data = null;
  await API.get('/products/reviews/'+id)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}