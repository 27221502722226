import Card from "../../../../components/UI/Card";

const PaymentInformation = ({order, setValue, control, errors, getValues, register, watch}) => {
  const getMessageClass = (status) => {
    let className = '';
    if(status === 'Pending')
      className = 'color_warning';

    if(status === 'success')
      className = 'color_success_type_2';

    if(status === 'Fail')
      className = 'text_red';

    return className;
  }

  return (
      <Card>
        <h1 className="common_sub_title mt_0 mb_5">
          <span>Payment Information</span>
        </h1>
        {order && order.group &&
            <>
              <div className="checkout_summery ">
                <table className="table border_none mb_0">
                  <tbody>
                  <tr>
                    <td className="text_left width_100p fw_600">Payment Status</td>
                    <td className={`${order.group.payment_status === 'success' ? 'color_success' : 'color_danger'}`}>{order.group.payment_status}</td>
                  </tr>
                  <tr>
                    <td className="text_left width_100p fw_600">Payment Method</td>
                    <td>{order.group.payment_method === 'stripe' ? "Card" : "Cash On Delivery"}</td>
                  </tr>
                  {order.group.card_brand && order.group.payment_method === 'stripe' ?
                      <>
                        <tr>
                          <td className="text_left width_100p fw_600"><img style={{'width': '34px'}}
                                                                                     src="/assets/media/images/visa.png"
                                                                                     alt=""/></td>
                          <td>**** **** {order.group.card_number}</td>
                        </tr>
                      </> : ''
                  }
                  </tbody>
                </table>
              </div>
            </>
        }

      </Card>
  );
}

export default PaymentInformation