import Button from "../../components/UI/Button";
import React, {useState} from "react";
import RadioBox from "../../components/UI/form/RadioBox";

const CouponTableFilterComponent = ({searchHandler, params})=>{
    const queryparams = {
        per_page: 20,
        page: 1,
        search: '',
        type: '',
    }

    const [filterParams, setFilterParams] = useState({...queryparams});

    const handleInputChange = (event) => {
        setFilterParams(old=> ({...old, search: event.target.value}))
    }
    const sendSearchRequest = ()=>{
        searchHandler({...filterParams, page:1});
    }

    const resetSearch = ()=>{
        searchHandler({...queryparams})
        setFilterParams({...queryparams})
    }

    const handlePerPage = (e) => {
        searchHandler({...filterParams,  per_page: e.target.value, page:1});
    }

    const enterpress = (e) => {
        if(e.key === 'Enter'){
            sendSearchRequest()
        }
    }

    const handleOnchangeEvent = (e)=>{
        searchHandler({...filterParams,  type: e.target.value, page:1});
        setFilterParams({...filterParams,  type: e.target.value, page:1})
    }
    return (
      <>
          <div className="color_filter_content mb_15">
              <div className="form_row">
                  <div className="form_input">
                      <select className="form_global width_260p mr_10" value={params.per_page} onChange={(event) => {
                          handlePerPage(event)
                      }}>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="86">86</option>
                          <option value="100">100</option>
                      </select>
                      <div className="form_radio width_260p pt_10 pl_10">
                          <input type="radio"
                                 id={"all_coupon"}
                                 name={"coupon_type"}
                                 onChange={(e)=>handleOnchangeEvent(e)}
                                 checked={filterParams.type === '' ? true : false}
                                 value=""/>
                          <label className={'w_maxContent'} htmlFor={"all_coupon"}>All</label>
                      </div>
                      <div className="form_radio width_260p pt_10">
                          <input type="radio"
                                 id={"stylepick_coupon"}
                                 name={"coupon_type"}
                                 onChange={(e)=>handleOnchangeEvent(e)}
                                 checked={filterParams.type === 'stylepick' ? true : false}
                                 value="stylepick"/>
                          <label className={'w_maxContent'} htmlFor={"stylepick_coupon"}>Stylepick</label>
                      </div>

                      <div className="form_radio width_260p pt_10">
                          <input type="radio"
                                 id={"vendor_coupon"}
                                 name={"coupon_type"}
                                 onChange={(e)=>handleOnchangeEvent(e)}
                                 checked={filterParams.type === 'vendor' ? true : false}
                                 value="vendor"/>
                          <label className={'w_maxContent'} htmlFor={"vendor_coupon"}>Vendors</label>
                      </div>
                      <div className="search_input">
                          <input type="text" className="form_global"
                                 onKeyPress={(e) => enterpress(e)}
                                 placeholder={"search by name, duration, type"}
                                 value={filterParams ? filterParams.search : ''}
                                 onChange={(event) => handleInputChange(event)}
                          />

                      </div>
                      <Button text={"Search"}
                              variant={"btn_black"}
                              classes={'btn_black ml_5 mr_5 pl_20 pr_20 '}
                              size={'btn_md'}
                              onClickHandler={sendSearchRequest}/>

                      <Button text={"Reset"}
                              variant={"btn btn btn_black pl_20 pr_20 btn_black  ml"}
                              onClickHandler={resetSearch}/>
                  </div>
              </div>
          </div>

      </>
    )
}

export default CouponTableFilterComponent