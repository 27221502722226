import {Dropzone} from "dropzone";
import {useEffect, useState} from "react";
import "dropzone/src/dropzone.scss"

const ProductImageUploadDropzone = ({id, options = {}, successEventHandler, removeItemAfterSuccess = false}) => {
  const [dropzoneInit, setDropzoneInit] = useState(false);
  const user = JSON.parse(localStorage.getItem('user')) || null;

  useEffect(() => {
    if (!dropzoneInit) {
      setDropzoneInit(true);
      options.headers = {
        'Authorization': `Bearer ${user ? user.token : ''}`
      }

      const dropzoneInstance = new Dropzone(`#${id}`, options);
      dropzoneInstance.on("addedfile",
        file => {
          // console.log("A file has been added");
        }
      );
      dropzoneInstance.on("success", function(file, response) {
        successEventHandler(response)
        if(removeItemAfterSuccess) {
          dropzoneInstance.removeFile(file)
        }
      });
      dropzoneInstance.on("sending", function(file, xhr, formData) {
        formData.append("image", file);
      });
    }
  }, []);

  return (
    <div className="item_img_upload">
      <div className="image_upload dropzone" id={id}></div>
    </div>
  );
};

export default ProductImageUploadDropzone;