import InventoryItem from "./InventoryItem";
import {useEffect} from "react";
import InventoryCombinationHeading from "./InventoryCombinationHeading";
import {useFieldArray} from "react-hook-form";
import Input from "../../../../../components/UI/form/Input";
import Checkbox from "../../../../../components/UI/form/Checkbox";

export const InventoryComponent = ({currentTab, watch, setError, control, setValue, handleSubmit, errors, register, getValues})=>{

  const { fields: varientFields, append: appendVariant, remove:removeVariant} = useFieldArray({
    control,
    name: 'inventories',
  });

  useEffect(()=>{
    if(currentTab === 'inventory'){
      generateInventoryCombinnation();
    }
  }, [currentTab])

  useEffect(()=>{
    generateInventoryCombinnation();
  }, [watch('variants')])


  function generateCombinations(arrays) {
    const result = [];
    function generate(current, index) {
      if (index === arrays.length) {
        result.push(current.slice());
        return;
      }

      const currentArray = arrays[index].values;
      if(currentArray.length) {
        for (const element of currentArray) {
          current.push({value: element.name, name: arrays[index].name});
          generate(current, index + 1);
          current.pop();
        }
      }
    }

    generate([], 0);
    return result;
  }

  const generateInventoryCombinnation = ()=>{

    if(getValues('variants').length) {

      let array = []
      getValues('variants').map(item=> item.values.length && array.push(item))
      if(array.length) {
        const combinations = generateCombinations(array);
        setValue(`inventories`, combinations.map((inventory, i) => ({
          variant_combination: inventory,
          sku: varientFields && varientFields[i]?.sku ? varientFields[i].sku : '',
          price: varientFields && varientFields[i]?.price ? varientFields[i].price : '',
          available_quantity: varientFields && varientFields[i]?.available_quantity ? varientFields[i].available_quantity : 999,
          sell_out_of_stock: varientFields && varientFields[i]?.sell_out_of_stock ? varientFields[i].sell_out_of_stock+'' : '0',
        })))
      }
    } else {
      setValue(`inventories`, [])
    }
  }

  return (
    <>
      <div className="create_inventory_area">
        {varientFields.length ?
          <div className="table_responsive">
            <table className="table table_bordered">
              <InventoryCombinationHeading />
              <tbody>
              {varientFields && varientFields.map((field, index) => (
                <InventoryItem errors={errors}
                               key={`category_${index}`}
                               watch={watch}
                               setValue={setValue}
                               control={control}
                               register={register}
                               getValues={getValues}
                               index={index} />
              ))}
              </tbody>
            </table>
          </div>
          :
          <div className={'row'}>

            <div className="col_6">
              <Input label="Available Quantity"
                     name="available_quantity"
                     inline={true}
                     errors={errors}
                     validationRules={{type: 'number'}}
                     register={register}/>
            </div>
            <div className="col_6">
              <Checkbox name={`sell_out_of_stock`}
                        label={"Out of stock"}
                        classes="mb_0 pt_10"
                        register={register}
                        checked={getValues(`sell_out_of_stock`) ? 1 : 0} />
            </div>
          </div>
        }

      </div>
    </>
  );
}

export default InventoryComponent