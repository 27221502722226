import {getVendorAccounts, getVendorDetails} from "../../api/vendor";
import {useLoaderData} from "react-router-dom";
import Card from "../../components/UI/Card";
import Button from "../../components/UI/Button";
import {useEffect, useState} from "react";
import {setEditVendorUser} from "../../store/slices/vendorUserSlice";
import {useDispatch} from "react-redux";
import VendorUserForm from "../../pages/vendor/VendorUserForm";
import HeaderComponent from "../../views/layouts/HeaderComponent";
import SubHeadingComponent from "../../components/UI/SubHeadingComponent";

export async function loader({params}){
  const [vendorData, accounts] = await Promise.all([
    getVendorDetails(params.id),
    getVendorAccounts(params.id)
  ]);

  return {vendorData, accounts};
}

const VendorDetails = () => {
  let {vendorData, accounts} = useLoaderData();
  const dispatch = useDispatch();

  const [modalStatus, setModalStatus] = useState(false)
  const [showTextField, setTextField] = useState(false)
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [vendor, setVendor] = useState({});
  const [vendorBalance, setVendorBalance] = useState(null);

  useEffect(()=>{
    if(vendorData){
      setVendor(vendorData)
    }
  }, [vendorData])
  useEffect(()=>{
    if(accounts){
      setVendorBalance(accounts)
    }
  }, [accounts])

  const addEditVendorUser = (vendorUser) => {
    setModalStatus(true)
    dispatch(setEditVendorUser(vendorUser))
  }

  useEffect(() => {
    if (!modalStatus && isDataUpdated) {
      async function fetchData() {
        const vendorInfo = await getVendorDetails(vendor.id)
        setVendor(vendorInfo)
      }
      fetchData();
      setIsDataUpdated(false);
    }
  }, [modalStatus, isDataUpdated, vendor.id]);

  return (
    <>
      {vendor &&
      <>
        <h1 className="common_title">Vendor Details</h1>
        <div className="row">
          <div className="col_4">
            <Card cardBodyCls={'pt_10 pb_0'} cardClass={'checkout_summery height_full'}>
              <SubHeadingComponent heading={`Store Information`} classes={'mt_0 mb_5'} />
              <table className="table border_none mb_0">
                <tbody>
                <tr>
                  <td>Name</td>
                  <td>{vendor.name}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{vendor.email}</td>
                </tr>
                <tr>
                  <td>Legal Business Name</td>
                  <td>{vendor.legal_business_name}</td>
                </tr>
                <tr>
                  <td>Store Link</td>
                  <td>
                    <a className={' btn btn_transparent '} target={'_blank'} href={`${process.env.REACT_APP_FRONT_URL}/${vendor.slug}`}>Open</a>
                  </td>
                </tr>
                <tr>
                  <td>Logo</td>
                  <td><img width={100} src={vendor.logo} alt=""/></td>
                </tr>
                <tr>
                  <td>Commission Rate</td>
                  <td>{vendor.commission ? `${vendor.commission}%` : null}</td>
                </tr>
                </tbody>
              </table>
            </Card>
          </div>

          <div className="col_4">
            <Card cardBodyCls={'pt_10 pb_0'} cardClass={'checkout_summery height_full'}>
              <SubHeadingComponent heading={`Account's Information`} classes={'mt_0 mb_5'} />
              <table className="table border_none mb_0">
                <tbody>
                <tr>
                  <td>Total Commission</td>
                  <td>${vendorBalance? vendorBalance.total_commission.toFixed(2) : '0.0'}</td>
                </tr>
                <tr>
                  <td>Total Withdraw</td>
                  <td>${vendorBalance? vendorBalance.total_withdraw.toFixed(2) : '0.0'}</td>
                </tr>
                <tr>
                  <td>Withdraw Pending</td>
                  <td>${vendorBalance? vendorBalance.pending_withdraw.toFixed(2) : '0.0'}</td>
                </tr>
                <tr>
                  <td>Available balance</td>
                  <td>
                    ${vendorBalance? vendorBalance.withdrawable_amount.toFixed(2) : '0.0'}
                  </td>
                </tr>
                </tbody>
              </table>
            </Card>
          </div>

          <div className="col_4">
            <Card cardBodyCls={'pt_10 pb_0'} cardClass={'checkout_summery height_full'}>
              <SubHeadingComponent heading={`Address`} classes={'mt_0 mb_5'} />
              <table className="table border_none mb_0">
                <tbody>
                <tr>
                  <td>Address</td>
                  <td>{vendor.address}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>{vendor.city}</td>
                </tr>
                <tr>
                  <td>POst code</td>
                  <td>{vendor.postal_code}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{vendor.phone}</td>
                </tr>
                </tbody>
              </table>
            </Card>
          </div>

        </div>


        {vendor?.users?.length &&
        <Card cardClass={'mt_20'}>
          <h1 className="common_sub_title mt_0 mb_5  ">User</h1>
          <div className="table_responsive">

            <table className="table">
              <thead>
              <tr>
                <th>SL. No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Last Login</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {vendor.users.map((user, i)=>(
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.last_login}</td>
                  <td>{user.created_at}</td>
                  <td>
                    <Button text={'Edit'} variant={'btn_transparent'} classes={'has_border_radius'} size={'btn_sm'} onClickHandler={() => addEditVendorUser(user)} />

                    {/*<Link  to={"/vendor-details/"+user.id} className="mr_5">
                      <Button text={'Details'} variant={'btn_info'} />
                    </Link>*/}
                    {/*<Button text={'Delete'} variant={'btn_danger'} isPending={isPending} onClickHandler={deleteHandler} />*/}
                  </td>
                </tr>
              ))}

              </tbody>
            </table>
          </div>
          <VendorUserForm
            modalStatus={modalStatus}
            setModalStatus={setModalStatus}
            showTextField={showTextField}
            type={'edit'}
            setIsDataUpdated={setIsDataUpdated}
          />
        </Card>
        }
      </>
      }
    </>
  )
}

export default VendorDetails