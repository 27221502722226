import API from "../util/api";

export const importExcelProducts = async (payload) => {
  let data = null;

  await API.post('/product-import-export', payload)
    .then(res => {
      data = res.data.data;
    })
    .catch((err) => {
      throw new Error(JSON.stringify(err.response.data))
    });

  return data;
}
