import React, {useEffect, useState} from "react";
import ProductFilterComponent from "../../views/products/product/List/includes/ProductFilterComponent";
import { useSelector} from "react-redux";
import ActiveProductList from "./ActiveProductList";
import InActiveProductList from "./InActiveProductList";
import {useMutation} from "@tanstack/react-query";
import {getCategories} from "../../api/categories";
import ProductListCardActions from "../../views/products/product/List/ProductListCardActions";
import Card from "../../components/UI/Card";

const ProductList = ()=>{
  const store = useSelector(state => state.product);
  const [categories, setCategories] = useState([])
  const [activeTab, setActiveTab] = useState('active')
  const [initialParams, setParantInitialParams] = useState({...store.initialParams})
  const [totalActiveItem, setTotalActiveItem] = useState(0)
  const [totalInActiveItem, setTotalInActiveItem] = useState(0)

  function handleClick(active) {
    setActiveTab(active)
  }


  const {mutate:categoryGet, isPending} = useMutation({
    mutationFn: () => getCategories(),
    onSuccess:((res)=>{
      setCategories(res)
    })
  })

  useEffect(()=>{
    if(!categories.length){
      categoryGet()
    }
  }, [categories])

  const setInitialParams = (params) => {
    setParantInitialParams({...params, status: activeTab === 'active' ? 1 : 0})
  }
  return (
    <>
      <ProductListCardActions />
      <Card cardBodyCls={'item_list_wrapper pl_20 pr_20'}>
        <div className="row">
          <div className="col_12">
            <nav className="tabs">
              <ul className="">
                <li onClick={()=>handleClick('active')} className={`${activeTab === 'active' && 'active'}`}> Active Items
                  <button type="button" className="btn btn_xs btn_black has_border_radius ml_10">{totalActiveItem} </button>
                </li>
                <li  onClick={()=>handleClick('inactive')} className={`${activeTab === 'inactive' && 'active'}`}> Inactive Items
                  <button type="button" className="btn btn_xs btn_black has_border_radius ml_10">{totalInActiveItem}</button>
                </li>
              </ul>
            </nav>
            <div className="tab_content_wrapper pl_0 pr_0 border_none">
              <ProductFilterComponent initialParams={initialParams} setInitialParams={setInitialParams} />
              <div className={`tab_content ${activeTab === 'active' ? 'show' : ''}`}>
                <ActiveProductList activeTab={activeTab} setTotalActiveItem={setTotalActiveItem} initialParams={initialParams} categories={categories} setInitialParams={setInitialParams} />
              </div>
              <div className={`tab_content ${activeTab === 'inactive' ? 'show' : ''}`}>
                <InActiveProductList activeTab={activeTab} setTotalInActiveItem={setTotalInActiveItem} initialParams={initialParams} categories={categories} setInitialParams={setInitialParams} />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ProductList;