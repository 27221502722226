import {confirmAlert,} from "react-confirm-alert";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useState} from "react";
import Button from "../../components/UI/Button";
import {withdrawRequestStatusChange} from "../../api/commissionTransaction";

const WithdrawTableAction = ({id, row}) =>{
    const [toastId, setToastId] = useState(null);
    const queryClient = useQueryClient()

    const {mutate, isPending} = useMutation({
        mutationFn: (data) => withdrawRequestStatusChange(id, data),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Withdraw Declined!", type: "success", isLoading: false, autoClose: true  });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'withdrawRequestList',
            });
        },
        onError: (error) => {
            toast.update(toastId, { render: error.message.message, type: "error", isLoading: false, autoClose: true });
        }
    })

    const handleDecline = () => {
        confirmAlert({
            title: 'Decline',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mutate({id: id, status: 3})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    },
                }
            ]
        });
    }

    const handleApprove = () => {
        confirmAlert({
            title: 'Approve',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        mutate({id: id, status: 2})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    },
                }
            ]
        });
    }

    return (
        <>
            <Button text={'Approve'} variant={'btn_transparent has_border_radius mr_5'} size={'btn_sm'} isPending={isPending} onClickHandler={handleApprove} disabled={row.status === 'APPROVED' || row.status === 'DECLINED'} />
            <Button text={'Decline'} variant={'btn_danger has_border_radius'} size={'btn_sm'} isPending={isPending} onClickHandler={handleDecline} disabled={row.status === 'APPROVED' || row.status === 'DECLINED'} />
        </>
    );
}

export default WithdrawTableAction;