import {confirmAlert,} from "react-confirm-alert";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useState} from "react";
import Button from "../../components/UI/Button";
import {deletePageSection} from "../../api/pageSections";
import {Link} from "react-router-dom";


const PageSectionTableActions = ({row, editRecord}) =>{

  const [toastId, setToastId] = useState(null);

  const queryClient = useQueryClient()

  const {mutate, isPending} = useMutation({
    mutationFn: deletePageSection,
    onMutate: () => {
      setToastId(toast.loading("Please wait..."))
    },
    onSuccess: (response, payload) => {
      toast.update(toastId, { render: "Section Deleted!", type: "success", isLoading: false, autoClose: true  });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'sections',
      });
    },
    onError: () => {
      toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
    }
  })

  const editHandler = ()=>{
    editRecord(row)
  }

  const deleteHandler = () => {
    confirmAlert({
      title: 'Delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            mutate({id: row.id})
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        }
      ]
    });
  }
  return (
    <>
      <Link to={`/page-sections/${row.id}`}>
        <Button
          text="Edit"
          classes={"mr_5"}
          variant={"has_border_radius btn_transparent"}
        />
      </Link>

      <Button
        text="Delete"
        variant={"btn_danger"}
        classes={'has_border_radius'}
        isPending={isPending}
        onClickHandler={deleteHandler}
      />
    </>
  );
}

export default PageSectionTableActions;
