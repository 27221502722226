import TextArea from "../../../../components/UI/form/TextArea";
import React from "react";

const OrderNotes = ({order, errors,register, getValues, setValue}) => {
  return (
    <>
      <TextArea label="Buyer Note"
                name="buyer_note"
                errors={errors}
                disable={true}
                placeholder={order.buyer_note}
                register={register}/>
    </>
  );
}

export default OrderNotes