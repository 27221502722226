import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {deleteHomePageSections, getHomePageSections, sortHomeSection} from "../../api/homePageSections";
import {useEffect, useState} from "react";
import Card from "../../components/UI/Card";
import HomePageSectionCardActions from "../../views/home-page-section/HomePageSectionCardActions";
import {ReactSortable} from "react-sortablejs";
import Button from "../../components/UI/Button";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {Link} from "react-router-dom";
import HomeSectionCardActions from "../../views/home-page-section/HomeSectionCardActions";

const HomePageSections = () => {

    const [modalStatus, setModalStatus] = useState(false)
    const [sections, setSections] = useState([])
    const [editSection, setEditSection] = useState(null)
    const queryClient = useQueryClient();

    const {data: allSections, isPending} = useQuery({
        queryKey:[`home-page-section`],
        queryFn: () => getHomePageSections()//Home page Top Banner Type 2 fixed
    })

    const [toastId, setToastId] = useState(null);
    const { mutate, isIdle,  reset} = useMutation({
        mutationFn: async  (ids) => sortHomeSection( {ids:[...ids]}),
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (res) => {
            toast.update(toastId, {
                render: `Category Sorted!`,
                type: "success",
                isLoading: false,
                autoClose: true
            });
            setTimeout(()=>{
                reset()
            }, 1000)
            //
        },
        onError: (error) => {
            let errorMsg = "Something happened!";
            toast.update(toastId, {
                render: errorMsg,
                type: "error",
                isLoading: false,
                autoClose: true
            });
        }
    })

    useEffect(()=>{
        if(allSections && allSections.length){
            setSections(allSections)
        }
    }, [allSections])

    const categorySort = (list)=>{
        const checkEquality = JSON.stringify(list) === JSON.stringify(sections);
        if (!checkEquality) {
            let ids = list.map(obj => obj.id);
            mutate(ids)
            setSections(list)
        }

    }

    function handleEditSection(row){
        setEditSection(row)
        setModalStatus(true)
    }


    const {mutate:deleteMutate} = useMutation({
        mutationFn: deleteHomePageSections,
        onMutate: () => {
            setToastId(toast.loading("Please wait..."))
        },
        onSuccess: (response, payload) => {
            toast.update(toastId, { render: "Section Deleted!", type: "success", isLoading: false, autoClose: true  });
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey[0] === 'home-page-section',
            });
        },
        onError: () => {
            toast.update(toastId, { render: "Something happened!", type: "error", isLoading: false, autoClose: true });
        }
    })

    function handleDelete(row){
        confirmAlert({
            title: "Delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteMutate({id: row.id})
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    },
                }
            ]
        })
    }

    return (
      <>
          <Card title={'Home Sections'} CardActions={<HomeSectionCardActions />}>
              {sections &&
              <div className="accordion_wrap categories_row">
                  <ReactSortable animation={200}
                                 list={sections}
                                 setList={categorySort} >
                      {sections.map((section)=>(
                        <div className="accordion" key={section.id}>
                            <div className="accordion_heading p_relative">
                                <div className={`last_category`} data-toggle="collapse" data-target={`#parent${section.id}`}>{section.title}  <span className="text_muted text-capitalize">{section.type.replaceAll('_', ' ').toString()}</span></div>
                                <div className="category_actions p_absolute" style={{'paddingTop':'3px'}}>

                                    <Link className={''} to={`/home-page-sections/${section.id}`}>
                                        <span className={'cursor_pointer'}><img src="/assets/media/images/edit.png" alt="" /></span>
                                    </Link>
                                    <span className="cursor_pointer pl_15" onClick={()=> handleDelete(section)}><img src="/assets/media/images/trash.png" alt="" /></span>
                                    <span className={'move pl_15'}><i className="lni lni-move"></i></span>
                                </div>
                            </div>

                        </div>
                      ))}
                  </ReactSortable>
              </div>}

          </Card>



      </>
    );
}

export default HomePageSections;