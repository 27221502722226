import React, {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getProducts} from "../../api/products";
import ProductComponent from "../../views/products/product/List/includes/ProductComponent";
import Pagination from "../../components/UI/Pagination";
import ProductModificationActionsComponent
  from "../../views/products/product/List/includes/ProductModificationActionsComponent";

const InActiveProductList = ({activeTab,setTotalInActiveItem,  initialParams, categories}) => {
  const[products, setProducts] = useState([]);
  const[params, setInitialParams] = useState({...initialParams, status:0});
  const[paginations, setPagination] = useState(null);
  const[selectedProducts, setSelectedProducts] = useState([]);
  const queryClient = useQueryClient()

  const {data: allProducts, isPending} = useQuery({
    queryKey:['inactive-products'],
    queryFn: () => getProducts({...params, status: 0})
  })

  useEffect(()=>{
    if(allProducts) {
      setProducts(allProducts.data)
      setPagination(allProducts.meta)
      setTotalInActiveItem(allProducts.meta?.total)
    }
  },[allProducts])


  useEffect(()=>{
    if(params && params.status === 0) {
      mutateGetProduct()
    }
  },[params])


  useEffect(()=>{
    if(initialParams.status === 0) {
      setInitialParams({...initialParams, status: 0})
    }
  },[initialParams])

  function mutateGetProduct(){
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === 'inactive-products',
    });
  }

  const changePage = (page) => {
    setInitialParams(old=>({...old, page:page}))
  }

  const selectUnselect = (actionType = null) => {
    if(actionType === 'select'){
      setSelectedProducts([])
      const idsArray = products.map(obj => obj.id);
      setSelectedProducts(idsArray)
    }

    if(actionType === 'unselect'){
      setSelectedProducts([])
    }
  }
  const selectSingleProduct = (id) => {
    const existingItemIndex = selectedProducts.findIndex(item => item === +id);

    if (existingItemIndex !== -1) {
      setSelectedProducts(selectedProducts.filter(item => item !== +id))
    } else {
      setSelectedProducts(old=>([...old, id]))
    }
  }
  const actionComplete = (action) => {
    if(action === 'complete') {
      mutateGetProduct()
      setSelectedProducts([])
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'active-products',
      });
    }
  }

  return (
    <>
      <div className="fadein">
        <ProductModificationActionsComponent activeTab={false} selectUnselect={selectUnselect} selectedProducts={selectedProducts} actionComplete={actionComplete} categories={categories} />
        {(paginations && paginations.per_page < paginations.total) ?
          <Pagination meta={paginations} changePageFn={changePage} isPlaceholderData={false}/> : ''}
        <div className="item_list_wrap pt_0">
          {products.map((product, index) => (
            <ProductComponent selectedProducts={selectedProducts} selectSingleProduct={selectSingleProduct} product={product} key={index}/>
          ))}
          {products.length === 0 && (
              <div className="mt_15 item_list_inner">No items found!</div>
          )}
        </div>
        {(paginations && paginations.per_page < paginations.total) ?
          <Pagination meta={paginations} changePageFn={changePage} isPlaceholderData={false}/> : ''}
      </div>
    </>
  );
}

export default InActiveProductList