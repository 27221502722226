import {Link} from "react-router-dom";
import Button from "../../components/UI/Button";

const CustomerCardActions = () => {
  return (
    <Link  to={"/customers/add"} >
      <Button text={'Add Customer'} />
    </Link>
  );
}

export default CustomerCardActions