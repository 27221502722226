import Card from "../../../../components/UI/Card";
import React from "react";
import TextArea from "../../../../components/UI/form/TextArea";

const OrderAdminNote = ({order, errors,register, getValues, setValue})=>{
  return (
    <TextArea label="Vendor Note"
              name="vendor_note"
              errors={errors}
              disable={true}
              placeholder={order.vendor_note}
              register={register}/>
  );
}

export default OrderAdminNote