import Button from "../../../components/UI/Button";
import RadioBox from "../../../components/UI/form/RadioBox";
import Input from "../../../components/UI/form/Input";
import Checkbox from "../../../components/UI/form/Checkbox";
import React, {useState} from "react";
import {useFieldArray} from "react-hook-form";

const CouponFormCodesComponent = ({setValue, control,register,setError,getValues, watch, checkedStatus, hangleCheckboxChange, errors})=>{
    const [accordianList, setAccordianList] = useState([])
    const codeObject = {code: '', active: "1", expires_at: '', max_redemptions: '', minimum_amount: '', first_time_transaction: false, auto_apply: false, free_shipping: false}
    const { fields, append: appendVariant, remove:removeVariant} = useFieldArray({
        control,
        name: 'codes',
    });

    const accordianHandle = (item)=>{
        setAccordianList((currentList) => {
            const index = currentList.findIndex((currentItem) => currentItem === item);
            if (index > -1) {
                return [
                    ...currentList.slice(0, index),
                    ...currentList.slice(index + 1),
                ];
            } else {
                return [...currentList, item];
            }
        });
    }

    const removeCodeAccordian = (index)=>{
        setAccordianList((currentList) => currentList.filter(item => item !== `accordian_${index}`));
        removeVariant(index)
    }

    return (
        <div className="col_12 mt_20">
            <div className="accordion_wrap">
                {getValues("codes") && getValues("codes").map((field, index) => (
                    <div className="accordion" key={`row_${index}`}>
                        <div className={`accordion_heading ${accordianList.includes(`accordian_${index}`) ? 'active' : ''}`}>
                            <div className="accordion_heading_inner left">
                                <div className="d_flex_btwn">
                                    <span className={"cursor_pointer"} onClick={()=>accordianHandle(`accordian_${index}`)}>Code will be generated upon creation</span>
                                    <Button text={"Remove"} variant={"btn_danger"} onClickHandler={()=>removeCodeAccordian(index)} />
                                </div>
                            </div>
                        </div>
                        <div className={`accordion_body ${accordianList.includes(`accordian_${index}`) ? 'd_block_important' : ''}`}>
                            <div className="row" >
                                <div className="col_12">
                                    <RadioBox label="Code Status"
                                              inline={true}
                                              name={`codes[${index}].active`}
                                              errors={errors}
                                              optionValue={'id'}
                                              optionLabel={'name'}
                                              options={[{id: "1", name: 'Active'}, {id: '0', name:"Inactive"}]}
                                              validationRules={{required:true, type: 'string'}}
                                              register={register}/>
                                </div>
                                <div className="col_12">
                                    <Input label="Code"
                                           inline={true}
                                           name={`codes[${index}].code`}
                                           classes={'width_500p'}
                                           errors={errors}
                                           validationRules={{required: true, type: 'string'}}
                                           register={register}/>
                                </div>
                                <div className="col_12">
                                    <Checkbox name={`codes[${index}].first_time_transaction`}
                                              label={"Eligible for first-time orders only"}
                                              classes="mb_0 pt_10"
                                              register={register}
                                              checked={getValues(`codes[${index}].first_time_transaction`) ? 1 : 0} />
                                </div>
                                <div className="col_12">
                                    <Checkbox name={`codes[${index}].auto_apply`}
                                              label={"Auto apply due to checkout?"}
                                              classes="mb_0 pt_10"
                                              register={register}
                                              checked={getValues(`codes[${index}].auto_apply`) ? 1 : 0} />
                                </div>
                                <div className="col_12">
                                    <Checkbox name={`codes[${index}].free_shipping`}
                                              label={"Free shipping?"}
                                              classes="mb_0 pt_10"
                                              register={register}
                                              checked={getValues(`codes[${index}].free_shipping`) ? 1 : 0} />
                                </div>
                                <div className="col_12">
                                    {/*<Checkbox name={`codes[${index}].max_redemptions_limit`}*/}
                                    {/*          label={"Limit the number of times this code can be redeemed"}*/}
                                    {/*          classes="mb_0 pt_10"*/}
                                    {/*          register={register}*/}
                                    {/*          onChangeHandler={(e)=>hangleCheckboxChange(e, `max_redemptions_limit_${index}`)}*/}
                                    {/*          checked={getValues(`codes[${index}].max_redemptions_limit`) ? 1 : 0} />*/}

                                    {checkedStatus[`max_redemptions_limit_${index}`] && <div className={"pl_30"}>
                                        <Input label="Redemptions times"
                                               name={`codes[${index}].max_redemptions`}
                                               classes={"mt_5"}
                                               type={'number'}
                                               errors={errors}
                                               validationRules={{required: true, type: "number"}}
                                               register={register}/>
                                    </div>}
                                </div>
                                <div className="col_12">
                                    <Checkbox name={`codes[${index}].expires_at_date`}
                                              label={"Add an expiry date"}
                                              classes="mb_0 pt_10"
                                              register={register}
                                              onChangeHandler={(e)=>hangleCheckboxChange(e, `expires_at_date${index}`)}
                                              checked={getValues(`codes[${index}].expires_at_date`) ? 1 : 0} />

                                    {checkedStatus[`expires_at_date${index}`] && <div className={"pl_30"}>
                                        <Input label="Expires at"
                                               name={`codes[${index}].expires_at`}
                                               classes={"mt_5 width_500p"}
                                               type={'date'}
                                               errors={errors}
                                               validationRules={checkedStatus[`expires_at_date${index}`] && {required: true}}
                                               register={register}/>

                                    </div>}
                                </div>
                                <div className="col_12">
                                    <Checkbox name={`codes[${index}].minimum_amount_limit`}
                                              label={"Require minimum order value"}
                                              classes="mb_0 pt_10"
                                              register={register}
                                              onChangeHandler={(e)=>hangleCheckboxChange(e, `minimum_amount_limit${index}`)}
                                              checked={getValues(`codes[${index}].minimum_amount_limit`) ? 1 : 0} />

                                    {checkedStatus[`minimum_amount_limit${index}`] && <div className={"pl_30"}>
                                        <Input label="Minimum amount"
                                               name={`codes[${index}].minimum_amount`}
                                               classes={"mt_5 "}
                                               type={'number'}
                                               errors={errors}
                                               validationRules={checkedStatus[`minimum_amount_limit${index}`] && {required: true}}
                                               register={register}/>

                                    </div>}
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
                <Button text={"+ Add another code"} variant={"btn_black"} onClickHandler={()=>appendVariant({...codeObject})} />
            </div>
        </div>
    );
}

export default CouponFormCodesComponent